// In styles/colors.js or constants/colors.js

const colors = {
  // **Background Colors**
  background: '#F8FAFD', // Light background from upload pages
  white: '#FFFFFF',      // Pure white

  // **Primary and Secondary Colors (Purples)**
  primary: '#7E3BA1',    // Light Purple used for active buttons and borders
  secondary: '#A16FB0',  // Medium Purple used for buttons like "Start Beauty Journey"

  // **Accent Colors**
  accentLight: '#C89FD9', // Used in gradients (e.g., How It Works section)
  accentLighter: '#E6D4F3', // Light Purple background for upload boxes
  accentLightest: '#F3E6FF', // Lighter shade for gradients

  // **Text Colors**
  text: '#2C3E50',        // Dark text color for readability
  gray: '#769192',        // Gray used for labels and subtitles

  // **Placeholder and Hover Colors**
  placeholder: '#BFA5E6', // Placeholder background color for upload sections
  hoverPrimary: '#8C4D8C', // Hover state for primary buttons
  hoverSecondary: '#8C4D8C', // Hover state for secondary elements like upload boxes

  // **Progress Bar Colors**
  progressPath: '#7E3BA1', // Path color for CircularProgressbar
  progressText: '#7E3BA1', // Text color for CircularProgressbar
  progressTrail: '#D6D6D6', // Trail color for CircularProgressbar
  progressBackground: '#3E98C7', // Background color for CircularProgressbar

  // **Border Colors**
  borderPrimary: '#7E3BA1', // Primary border color for upload sections
  borderSecondary: '#67CAC7', // Secondary border color for hover effects

  // Add more colors as needed
};

export default colors;
