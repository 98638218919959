import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import colors from '../../styles/colors';
import { calculateScorePercentile } from '../../Authorized/components/utils';
import UnauthorizedFrontResultsComponent from '../components/UnauthorizedFrontResultsComponent';
import FailedFrontResultsComponent from '../../General/FailedFrontResultsComponent';
import SignUpModal from '../components/SignUpModal';
import LoginModal from '../components/LoginModal';
import '../../styles/ResultsPage.css';

function UnauthorizedResultsPage() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { data, frontImage } = state || {};
  const [loading, setLoading] = useState(true);
  const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  useEffect(() => {
    if (data && frontImage) {
      setLoading(false);
    } else {
      console.error('Essential data missing. Redirecting to upload.');
      navigate('/upload', { replace: true });
    }
  }, [data, frontImage, navigate]);

  const calculateOverallScore = useCallback(() => {
    if (!data?.frontResult?.score) {
      return 0;
    }
    const frontScore = data.frontResult.score;
    return frontScore.toFixed(2);
  }, [data?.frontResult?.score]);

  const overallScore = useMemo(() => calculateOverallScore(), [calculateOverallScore]);
  const overallScoreValue = useMemo(() => Math.ceil(overallScore * 10), [overallScore]);
  const overallScorePercentile = useMemo(
    () => calculateScorePercentile(overallScoreValue / 10),
    [overallScoreValue]
  );

  // Modal functions
  const openModal = () => {
    setIsSignUpModalOpen(true);
    document.body.classList.add('modal-open');
  };

  const closeSignUpModal = () => {
    setIsSignUpModalOpen(false);
    document.body.classList.remove('modal-open');
  };

  const closeLoginModal = () => {
    setIsLoginModalOpen(false);
    document.body.classList.remove('modal-open');
  };

  const handleLoginClick = () => {
    closeSignUpModal();
    setIsLoginModalOpen(true);
    document.body.classList.add('modal-open');
  };

  const handleSignUpClick = () => {
    closeLoginModal();
    setIsSignUpModalOpen(true);
    document.body.classList.add('modal-open');
  };

  // Navigation to routines page
  const handleExploreRoutines = () => {
    if (data && data.routines) {
      navigate('/routines', {
        state: {
          data,
          routines: data.routines,
          fullData: data,
          imagePath: `data:image/jpeg;base64,${frontImage}`,
          frontImage,
        },
      });
    } else {
      console.error('Routines data is missing.');
    }
  };

  return (
    <div className="profileContainer">
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <CircularProgress sx={{ color: colors.primary }} size={150} />
        </div>
      ) : (
        <>
          {/* Header Section */}
          <header style={{ textAlign: 'center', marginBottom: '20px' }}>
            <h1 className="mainPageTitle">Your AI Beauty Insights</h1>
            <p className="pageDescription">
              Discover personalized recommendations and insights tailored to your unique skin analysis.
            </p>
            <div className="header-content">
              <div className="score-container">
                {data?.frontResult && (
                  <div style={{ fontSize: '26px', fontWeight: 'bold', color: '#2c3e50', textAlign: 'center' }}>
                    Overall Score: {(overallScore * 10).toFixed(0)} / 100
                    <br />
                    <span style={{ fontSize: '20px', fontWeight: 'normal' }}>
                      Better than {overallScorePercentile.toFixed(1)}% of People
                    </span>
                  </div>
                )}
              </div>
              <div className="header-buttons">
                <button className="explore-button" onClick={handleExploreRoutines}>
                  Explore Routines
                </button>
                <button className="explore-button" onClick={openModal}>
                  Unlock Full Results
                </button>
              </div>
            </div>
          </header>

          {/* Main Results Section */}
          <div>
            {data?.frontResult &&
              (data.frontResult.score === 0 ? (
                <FailedFrontResultsComponent imagePath={`data:image/jpeg;base64,${frontImage}`} />
              ) : (
                <UnauthorizedFrontResultsComponent
                  data={data.frontResult}
                  routines={data.routines}
                  fullData={data}
                  imagePath={`data:image/jpeg;base64,${frontImage}`}
                  frontImage={frontImage}
                />
              ))}
          </div>

          {(isSignUpModalOpen || isLoginModalOpen) && <div style={backdropStyles}></div>}

          <SignUpModal isOpen={isSignUpModalOpen} onClose={closeSignUpModal} onLoginClick={handleLoginClick} />
          <LoginModal isOpen={isLoginModalOpen} onClose={closeLoginModal} onSignUpClick={handleSignUpClick} />
        </>
      )}
    </div>
  );
}

const backdropStyles = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: 998,
};

export default UnauthorizedResultsPage;
