import React from 'react';

const Footer = () => {
  return (
    <footer style={footerStyles}>
      {/* Only Contact Us section is kept */}
      <div style={contactSectionStyles}>
        <h3 style={{ color: '#7E3BA1', marginBottom: '10px' }}>Contact Us</h3>
        <p style={{ color: '#4A4A4A' }}>
          Have questions or need assistance? Reach out to our team for support.
        </p>
        <a style={contactButtonStyles} href="mailto:goldenratioonline@gmail.com">
          goldenratioonline@gmail.com
        </a>
      </div>
      <div style={footerBottomStyles}>
        <p style={{ margin: 0, color: '#4A4A4A' }}>
          &copy; 2025 Beauty e-commerce - All Rights Reserved.
        </p>
      </div>
    </footer>
  );
};

const footerStyles = {
  backgroundColor: '#F4ECFD',
  padding: '60px 20px 20px',
  position: 'relative',
};

const contactSectionStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const contactButtonStyles = {
  display: 'inline-block',
  backgroundColor: '#7E3BA1',
  color: '#fff',
  padding: '10px 15px',
  borderRadius: '5px',
  textDecoration: 'none',
  marginTop: '10px',
};

const footerBottomStyles = {
  borderTop: '1px solid #ccc',
  paddingTop: '10px',
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
};

export default Footer;
