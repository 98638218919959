import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SideScanLockedComponent from '../components/ScanResultPages/SideScan/SideScanLockedComponent';
import SideScanResultsComponent from '../components/ScanResultPages/SideScan/SideScanResultsComponent';
import FailedSideResultsComponent from '../../General/FailedSideResultsComponent';
import UnlockingModal from '../components/UnlockingModal';
import PurchaseModal from '../components/PurchaseModal';
import CircularProgress from '@mui/material/CircularProgress';
import colors from '../../styles/colors';
import { doc, getDoc, collection, query, getDocs, orderBy } from 'firebase/firestore';
import { firestore, auth, storage } from '../../../firebaseConfig';
import { ref, getDownloadURL } from 'firebase/storage';
import { useUser } from '../components/UserContext';
import { getFunctions, httpsCallable } from 'firebase/functions';

function SideProfileResultsPage() {
  const [isLocked, setIsLocked] = useState(true);
  const [fullScanData, setFullScanData] = useState(null);
  const [isUnlockingModalOpen, setIsUnlockingModalOpen] = useState(false);
  const [isPurchaseModalOpen, setIsPurchaseModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isUnlocking, setIsUnlocking] = useState(false);
  const location = useLocation();
  const initialScanData = location.state?.data;
  const [scanDataHelper, setScanDataHelper] = useState(initialScanData);
  const [scanData, setScanData] = useState(initialScanData);
  const { credits, setCredits } = useUser();
  const [scans, setScans] = useState([]);
  const scrollRef = useRef(null);
  const navigate = useNavigate();
  const functions = getFunctions();

  const preloadImage = (url) =>
    new Promise((resolve, reject) => {
      const img = new Image();
      img.src = url;
      img.onload = resolve;
      img.onerror = reject;
    });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = auth.currentUser;
        if (!user) {
          console.error('User not authenticated');
          return;
        }
        if (!location.state?.scans) {
          const userDoc = collection(firestore, 'users', user.uid, 'scans');
          const scansQuery = query(userDoc, orderBy('created_at', 'desc'));
          const scanSnapshots = await getDocs(scansQuery);
          const scansList = await Promise.all(
            scanSnapshots.docs.map(async (doc) => {
              const scanData = doc.data();
              const repImageUrl = await getDownloadURL(ref(storage, scanData.repImageUrl));
              return { id: doc.id, ...scanData, repImageUrl };
            })
          );
          setScans(scansList);
          if (scanDataHelper.sideResult.image_path.includes('unauthorized')) {
            navigate('/scans');
          }
        } else {
          setScans(location.state.scans);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching scans:', error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchScanData = async () => {
      try {
        const user = auth.currentUser;
        if (!user) {
          console.error('User not authenticated');
          return;
        }
        let updatedScanData = { ...scanDataHelper };
        if (updatedScanData.unlocked && updatedScanData.full_scan_id) {
          const fullScanDocRef = doc(
            firestore,
            'users',
            user.uid,
            'scans',
            updatedScanData.scan_id,
            'fullScan',
            updatedScanData.full_scan_id
          );
          const fullScanDocSnapshot = await getDoc(fullScanDocRef);
          if (fullScanDocSnapshot.exists()) {
            const fullScanData = fullScanDocSnapshot.data();
            const sideImageUrl = await getDownloadURL(ref(storage, fullScanData.sideResult.image_path));
            await preloadImage(sideImageUrl);
            fullScanData.sideResult.image_path = sideImageUrl;
            updatedScanData = { ...updatedScanData, ...fullScanData };
            setFullScanData(fullScanData);
            setIsLocked(false);
          } else {
            console.error('Full scan data not found');
          }
        } else {
          const sideImageUrl = await getDownloadURL(ref(storage, updatedScanData.sideResult.image_path));
          await preloadImage(sideImageUrl);
          updatedScanData.sideResult.image_path = sideImageUrl;
          setIsLocked(true);
        }
        setScanData(updatedScanData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching scan data:', error);
      }
    };
    fetchScanData();
  }, [scanDataHelper]);

  const handleToggle = () => {
    setIsUnlockingModalOpen(true);
  };

  const handleUnlockConfirm = async () => {
    if (credits <= 0) {
      setIsPurchaseModalOpen(true);
      return;
    }
    setIsUnlocking(true);
    try {
      const user = auth.currentUser;
      if (user && scanData.full_scan_id) {
        const unlockFullScan = httpsCallable(functions, 'unlockFullScanFunction');
        const result = await unlockFullScan({ scanData });
        const { fullScanData, updatedCredits } = result.data;
        const sideImageUrl = await getDownloadURL(ref(storage, fullScanData.sideResult.image_path));
        fullScanData.sideResult.image_path = sideImageUrl;
        setFullScanData(fullScanData);
        setScanData({ ...scanData, unlocked: true });
        setScanDataHelper({ ...scanData, unlocked: true });
        setCredits(updatedCredits);
        setIsLocked(false);
        setScans((prevScans) =>
          prevScans.map((scan) =>
            scan.id === scanData.scan_id ? { ...scan, unlocked: true } : scan
          )
        );
      } else {
        console.error('User not authenticated or full_scan_id not found');
      }
    } catch (error) {
      console.error('Error unlocking full scan:', error);
    }
    setTimeout(() => {
      setIsUnlocking(false);
    }, 500);
    setIsUnlockingModalOpen(false);
  };

  const handleUnlockingModalClose = () => {
    setIsUnlockingModalOpen(false);
  };

  const handleScanClick = (scan) => {
    setLoading(true);
    setTimeout(() => {
      setScanDataHelper(scan);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 1000);
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp.seconds * 1000);
    const today = new Date();
    return date.toDateString() === today.toDateString()
      ? date.toLocaleTimeString()
      : date.toLocaleDateString();
  };

  const scrollLeft = () => {
    scrollRef.current.scrollBy({ left: -200, behavior: 'smooth' });
  };

  const scrollRight = () => {
    scrollRef.current.scrollBy({ left: 200, behavior: 'smooth' });
  };

  // Compute side profile score
  const overallScore =
    scanData && scanData.sideResult ? Math.ceil(scanData.sideResult.score * 10) : null;

  const handleExploreRoutines = () => {
    if (isLocked) {
      setIsUnlockingModalOpen(true);
    } else {
      const routinesData =
        fullScanData && fullScanData.routines ? fullScanData.routines : scanData?.routines;
      if (routinesData) {
        navigate('/routines', { state: { routines: routinesData, scanId: scanData.scan_id } });
      }
    }
  };

  return (
    <div className="profileContainer" style={{ minWidth: '1100px', overflowX: 'auto' }}>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress sx={{ color: colors.primary }} size={150} />
        </div>
      ) : (
        <>
          {/* Header Section */}
          <header style={{ textAlign: 'center', marginBottom: '20px' }}>
            <h1 className="mainPageTitle">Your AI Beauty Insights</h1>
            <p className="pageDescription">
              Discover personalized recommendations and insights tailored to your unique profile.
            </p>
            <div className="header-content">
              {overallScore !== null && (
                <div style={{ fontSize: '36px', fontWeight: 'bold', color: '#2c3e50' }}>
                  Side Profile Score: {overallScore} /100
                </div>
              )}
              <div className="header-buttons">
                <button
                  className="explore-button"
                  onClick={handleExploreRoutines}
                  style={{ padding: '10px 20px', cursor: 'pointer', fontSize: '16px' }}
                >
                  Explore Routines
                </button>
                {isLocked && (
                  <button
                    onClick={handleToggle}
                    style={{
                      background: colors.primary,
                      border: 'none',
                      borderRadius: '12px',
                      padding: '10px 20px',
                      color: '#fff',
                      cursor: 'pointer',
                      fontSize: '16px',
                    }}
                  >
                    Unlock Full Results
                  </button>
                )}
              </div>
            </div>
          </header>

          {isUnlocking ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
              <CircularProgress sx={{ color: colors.primary }} size={150} />
            </div>
          ) : (
            <>
              {/* Render scan results based on lock state */}
              <div style={{ marginTop: '20px' }}>
                {scanData?.sideResult?.score === 0 ? (
                  <FailedSideResultsComponent imagePath={scanData.sideResult.image_path} />
                ) : isLocked ? (
                  <SideScanLockedComponent
                    data={scanData}
                    routines={scanData.routines}
                    imagePath={scanData.sideResult.image_path}
                    onUnlockConfirm={handleUnlockConfirm}
                    scanId={scanData.scan_id}
                  />
                ) : (
                  <SideScanResultsComponent
                    data={fullScanData.sideResult}
                    routines={fullScanData.routines}
                    imagePath={scanData.sideResult.image_path}
                    scanId={scanData.scan_id}
                  />
                )}
              </div>

              {/* Horizontal Scans Carousel */}
              <div
                style={{
                  position: 'relative',
                  marginTop: '20px',
                  paddingBottom: '20px',
                  border: `2px solid ${colors.primary}`,
                  borderRadius: '12px',
                  overflow: 'hidden',
                }}
              >
                <button
                  onClick={scrollLeft}
                  style={{
                    position: 'absolute',
                    left: 0,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    zIndex: 1,
                    background: colors.primary,
                    border: 'none',
                    borderRadius: '50%',
                    width: '40px',
                    height: '40px',
                    cursor: 'pointer',
                  }}
                >
                  &lt;
                </button>
                <div
                  ref={scrollRef}
                  style={{ display: 'flex', overflowX: 'scroll', padding: '10px', scrollBehavior: 'smooth' }}
                >
                  {scans.map((scan) => (
                    <div
                      key={scan.id}
                      onClick={() => handleScanClick(scan)}
                      style={{ cursor: 'pointer', flex: '0 0 auto', marginRight: '10px' }}
                    >
                      <div
                        style={{
                          width: '100px',
                          height: '100px',
                          overflow: 'hidden',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginBottom: '10px',
                        }}
                      >
                        <img
                          src={scan.repImageUrl}
                          alt={`Scan ${scan.scan_number}`}
                          style={{ width: 'auto', height: '100%' }}
                        />
                      </div>
                      <div style={{ textAlign: 'center' }}>
                        <p>Scan {scan.scan_number}</p>
                        <p>{formatDate(scan.created_at)}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <button
                  onClick={scrollRight}
                  style={{
                    position: 'absolute',
                    right: 0,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    zIndex: 1,
                    background: colors.primary,
                    border: 'none',
                    borderRadius: '50%',
                    width: '40px',
                    height: '40px',
                    cursor: 'pointer',
                  }}
                >
                  &gt;
                </button>
              </div>
            </>
          )}

          {isUnlockingModalOpen && <div style={backdropStyles}></div>}
          <UnlockingModal
            isOpen={isUnlockingModalOpen}
            onClose={handleUnlockingModalClose}
            onConfirm={handleUnlockConfirm}
          />
          <PurchaseModal isOpen={isPurchaseModalOpen} onClose={() => setIsPurchaseModalOpen(false)} />
        </>
      )}
    </div>
  );
}

const backdropStyles = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: 998,
};

export default SideProfileResultsPage;
