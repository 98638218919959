import React, { useEffect, useState } from 'react';
import { signOut, onAuthStateChanged, deleteUser } from 'firebase/auth';
import { doc, getDoc, updateDoc, arrayRemove, onSnapshot } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { auth, firestore } from '../../../firebaseConfig';
import { useUser } from '../components/UserContext';
import SearchModal from '../components/SearchModal';
import { getFunctions, httpsCallable } from 'firebase/functions';
import styles from '../../styles/ProfilePage.module.css';

// Import the ingredient data and default avatar
import cleanedIngredients from '../../../assets/data/cleaned_ingredients.json';
import defaultAvatar from '../../../assets/images/default_avatar.webp';

// ------------------------------
// Utility Functions
// ------------------------------
const formatPropertyName = (str) => {
  return str
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const getClassForValue = (value) => {
  return `color${value}`;
};

const getSafetyClass = (decision) => {
  if (decision.includes('Safe') && !decision.includes('hazard')) {
    return 'safetySafe';
  } else if (decision.includes('Safe - Low hazard')) {
    return 'safetyLightgreen';
  } else if (
    decision.includes('Safe - Moderate hazard') ||
    decision.includes('Fair') ||
    decision.includes('Limited')
  ) {
    return 'safetyModerate';
  } else if (decision.includes('Moderate hazard')) {
    return 'safetyWarning';
  } else if (decision.includes('Unsafe') || decision.includes('High hazard')) {
    return 'safetyUnsafe';
  } else {
    return 'safetyUnknown';
  }
};

// Create a mapping from ingredient titles to ingredient data
const titleToIngredientMap = new Map();
cleanedIngredients.forEach((ingredient) => {
  titleToIngredientMap.set(ingredient.title.toLowerCase(), ingredient);
});

// ------------------------------
// Ingredient Modal Component
// ------------------------------
function IngredientModal({ ingredient, onClose }) {
  if (!ingredient) return null;
  return (
    <div className={styles.ingredientModal} onClick={onClose} role="dialog" aria-modal="true">
      <div className={styles.ingredientContent} onClick={(e) => e.stopPropagation()}>
        <h2>{ingredient.title}</h2>
        {ingredient.ewg && (
          <>
            <h3>EWG Decision:</h3>
            <p>{ingredient.ewg.decision}</p>
          </>
        )}
        {ingredient.categories && ingredient.categories.trim() !== '' && (
          <>
            <h3>Categories:</h3>
            <p>{ingredient.categories}</p>
          </>
        )}
        {ingredient && (
          <>
            <h3>Properties:</h3>
            <div className={styles.propertiesContainer}>
              {ingredient.boolean_properties &&
                Object.entries(ingredient.boolean_properties)
                  .filter(([_, value]) => value)
                  .map(([key]) => (
                    <span key={key} className={`${styles.propertyBubble} ${styles.booleanBubble}`}>
                      {formatPropertyName(key)}
                    </span>
                  ))}
              {ingredient.integer_properties &&
                Object.entries(ingredient.integer_properties).map(([key, value]) => (
                  <span
                    key={key}
                    className={`${styles.propertyBubble} ${styles.integerBubble} ${styles[getClassForValue(value)]}`}
                    title={`Rating: ${value}`}
                  >
                    {formatPropertyName(key)}
                  </span>
                ))}
            </div>
          </>
        )}
        <h3>Key:</h3>
        <div className={styles.keyContainer}>
          <div className={styles.keyItem}>
            <span className={`${styles.safetyColorBox} ${styles.safetySafe}`}></span> Safe
          </div>
          <div className={styles.keyItem}>
            <span className={`${styles.safetyColorBox} ${styles.safetyLightgreen}`}></span> Low Hazard
          </div>
          <div className={styles.keyItem}>
            <span className={`${styles.safetyColorBox} ${styles.safetyModerate}`}></span> Moderate Hazard
          </div>
          <div className={styles.keyItem}>
            <span className={`${styles.safetyColorBox} ${styles.safetyWarning}`}></span> Warning
          </div>
          <div className={styles.keyItem}>
            <span className={`${styles.safetyColorBox} ${styles.safetyUnsafe}`}></span> Risky
          </div>
          <div className={styles.keyItem}>
            <span className={`${styles.safetyColorBox} ${styles.safetyUnknown}`}></span> Unknown
          </div>
        </div>
        <button onClick={onClose} className={styles.closeModalButton}>
          Close
        </button>
      </div>
    </div>
  );
}

// ------------------------------
// Confirmation Modal Component
// ------------------------------
function ConfirmRemoveModal({ product, onConfirm, onCancel }) {
  if (!product) return null;
  return (
    <div className={styles.confirmModal} onClick={onCancel} role="dialog" aria-modal="true">
      <div className={styles.confirmContent} onClick={(e) => e.stopPropagation()}>
        <h2>Confirm Removal</h2>
        <p>
          Are you sure you want to remove <strong>{product.title}</strong>?
        </p>
        <div className={styles.confirmButtons}>
          <button onClick={onConfirm} className={styles.confirmButton}>
            Yes, Remove
          </button>
          <button onClick={onCancel} className={styles.cancelButton}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

// ------------------------------
// Edit Profile Modal Component
// ------------------------------
function EditProfileModal({ currentName, currentPhoto, onClose, onSave }) {
  const [newName, setNewName] = useState(currentName || '');
  const [newImageFile, setNewImageFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(currentPhoto || defaultAvatar);

  const handleNameChange = (e) => setNewName(e.target.value);
  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setNewImageFile(e.target.files[0]);
      setPreviewImage(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleSave = () => {
    onSave(newName, newImageFile);
  };

  return (
    <div className={styles.editProfileModal} onClick={onClose} role="dialog" aria-modal="true">
      <div className={styles.editProfileContent} onClick={(e) => e.stopPropagation()}>
        <h2>Edit Profile</h2>
        <div className={styles.profileForm}>
          <label>
            Username:
            <input
              type="text"
              value={newName}
              onChange={handleNameChange}
              className={styles.profileInput}
            />
          </label>
          <label>
            Profile Image:
            <input type="file" accept="image/*" onChange={handleImageChange} className={styles.profileInput} />
          </label>
          {previewImage && (
            <img
              src={previewImage}
              alt="Profile Preview"
              className={styles.userProfileImage}
              style={{ width: '150px', height: '150px', borderRadius: '50%' }}
              onError={(e) => {
                e.target.src = defaultAvatar;
              }}
            />
          )}
          <div className={styles.modalButtons}>
            <button onClick={handleSave} className={styles.saveButton}>
              Save Changes
            </button>
            <button onClick={onClose} className={styles.cancelButton}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

// ------------------------------
// Full Profile Page Component with Smart Updating
// ------------------------------
function ProfilePage() {
  const navigate = useNavigate();
  const { credits, setCredits } = useUser();
  const [user, setUser] = useState(null);

  // User details
  const [userPhoto, setUserPhoto] = useState('');
  const [userName, setUserName] = useState('');

  // Options menu and edit profile modal state
  const [optionsMenuOpen, setOptionsMenuOpen] = useState(false);
  const [editProfileModalOpen, setEditProfileModalOpen] = useState(false);

  // Current Beauty Routine and Wishlist
  const [savedProducts, setSavedProducts] = useState([]);
  const [wishlistProducts, setWishlistProducts] = useState([]);

  // Additional states for search modals and card flips
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const [isWishlistSearchModalOpen, setIsWishlistSearchModalOpen] = useState(false);
  const [flippedProducts, setFlippedProducts] = useState({});
  const [flippedWishlist, setFlippedWishlist] = useState({});
  const [selectedIngredient, setSelectedIngredient] = useState(null);
  const [productToRemove, setProductToRemove] = useState(null);
  const [wishlistItemToRemove, setWishlistItemToRemove] = useState(null);

  // Prepare callable function for updating profile image
  const functions = getFunctions();
  const updateProfileImageCallable = httpsCallable(functions, 'updateProfileImageCallable');

  // Helper: Convert file to base64 string
  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result.split(',')[1]);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  // ------------------------------
  // Caching: Check for profile data in sessionStorage
  // ------------------------------
  useEffect(() => {
    const cachedProfile = sessionStorage.getItem('profileData');
    if (cachedProfile) {
      const profileData = JSON.parse(cachedProfile);
      setCredits(profileData.credits || 0);
      setUserName(profileData.name || '');
      setUserPhoto(profileData.photoURL || '');
      setSavedProducts(profileData.savedProducts || []);
      setWishlistProducts(profileData.wishlistProducts || []);
    }

    // Listen for auth state changes.
    const unsubscribeAuth = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);

        // Subscribe to real-time updates for the main user document.
        const userDocRef = doc(firestore, 'users', currentUser.uid);
        const unsubscribeProfile = onSnapshot(userDocRef, (docSnap) => {
          if (docSnap.exists()) {
            const userData = docSnap.data();
            setCredits(userData.credits || 0);
            setUserName(userData.name || currentUser.displayName || 'N/A');
            setUserPhoto(userData.photoURL || currentUser.photoURL || '');
            // Update cache.
            const profileCache = {
              ...(JSON.parse(sessionStorage.getItem('profileData') || '{}')),
              credits: userData.credits || 0,
              name: userData.name || currentUser.displayName || 'N/A',
              photoURL: userData.photoURL || currentUser.photoURL || '',
            };
            sessionStorage.setItem('profileData', JSON.stringify(profileCache));
          }
        });

        // Subscribe to real-time updates for the current beauty routine.
        const routineDocRef = doc(
          firestore,
          'users',
          currentUser.uid,
          'UserData',
          'currentBeautyRoutine'
        );
        const unsubscribeRoutine = onSnapshot(routineDocRef, (docSnap) => {
          if (docSnap.exists()) {
            const routineData = docSnap.data();
            setSavedProducts(routineData.products || []);
            const cachedData = JSON.parse(sessionStorage.getItem('profileData') || '{}');
            cachedData.savedProducts = routineData.products || [];
            sessionStorage.setItem('profileData', JSON.stringify(cachedData));
          } else {
            setSavedProducts([]);
          }
        });

        // Subscribe to real-time updates for the wishlist.
        const wishlistDocRef = doc(
          firestore,
          'users',
          currentUser.uid,
          'UserData',
          'wishlist'
        );
        const unsubscribeWishlist = onSnapshot(wishlistDocRef, (docSnap) => {
          if (docSnap.exists()) {
            const wishlistData = docSnap.data();
            setWishlistProducts(wishlistData.products || []);
            const cachedData = JSON.parse(sessionStorage.getItem('profileData') || '{}');
            cachedData.wishlistProducts = wishlistData.products || [];
            sessionStorage.setItem('profileData', JSON.stringify(cachedData));
          } else {
            setWishlistProducts([]);
          }
        });

        // Cleanup all subscriptions when the user changes.
        return () => {
          unsubscribeProfile();
          unsubscribeRoutine();
          unsubscribeWishlist();
        };
      } else {
        setUser(null);
        setCredits(0);
        setSavedProducts([]);
        setWishlistProducts([]);
      }
    });

    return () => unsubscribeAuth();
  }, [setCredits]);

  // ------------------------------
  // Toggle Card Flip (Routine)
  // ------------------------------
  const toggleCardFlip = (productId) => {
    setFlippedProducts((prev) => ({ ...prev, [productId]: !prev[productId] }));
  };

  // ------------------------------
  // Toggle Card Flip (Wishlist)
  // ------------------------------
  const toggleCardFlipWishlist = (productId) => {
    setFlippedWishlist((prev) => ({ ...prev, [productId]: !prev[productId] }));
  };

  // ------------------------------
  // Handle Ingredient Click
  // ------------------------------
  const handleIngredientClick = (ingredientName) => {
    const ingredientData = titleToIngredientMap.get(ingredientName.toLowerCase());
    if (ingredientData) {
      setSelectedIngredient(ingredientData);
    } else {
      alert('Ingredient details not available.');
    }
  };

  // ------------------------------
  // Remove Product from Routine
  // ------------------------------
  const removeProduct = async (productId) => {
    if (!user) return;
    const product = savedProducts.find((p) => p.id === productId);
    if (!product) return;
    try {
      const routineDocRef = doc(
        firestore,
        'users',
        user.uid,
        'UserData',
        'currentBeautyRoutine'
      );
      await updateDoc(routineDocRef, {
        products: arrayRemove(product),
      });
      setSavedProducts((prev) => prev.filter((p) => p.id !== productId));
    } catch (error) {
      console.error('Error removing product:', error);
      alert('Failed to remove product. Please try again.');
    }
  };

  // ------------------------------
  // Remove Product from Wishlist
  // ------------------------------
  const removeWishlistProduct = async (productId) => {
    if (!user) return;
    const product = wishlistProducts.find((p) => p.id === productId);
    if (!product) return;
    try {
      const wishlistDocRef = doc(
        firestore,
        'users',
        user.uid,
        'UserData',
        'wishlist'
      );
      await updateDoc(wishlistDocRef, {
        products: arrayRemove(product),
      });
      setWishlistProducts((prev) => prev.filter((p) => p.id !== productId));
    } catch (error) {
      console.error('Error removing product from wishlist:', error);
      alert('Failed to remove product. Please try again.');
    }
  };

  // Handler for saving profile updates (username and photo)
  const handleProfileSave = async (newName, newImageFile) => {
    if (!user) return;
    try {
      // Update username in Firestore.
      const userDocRef = doc(firestore, 'users', user.uid);
      await updateDoc(userDocRef, { name: newName });
      setUserName(newName);
      // If an image file was provided, update the profile image.
      if (newImageFile) {
        const imageBase64 = await fileToBase64(newImageFile);
        const result = await updateProfileImageCallable({ uid: user.uid, imageBase64 });
        const { imageUrl } = result.data;
        setUserPhoto(imageUrl);
      }
      setEditProfileModalOpen(false);
    } catch (error) {
      console.error('Error updating profile:', error);
      alert('Failed to update profile. Please try again.');
    }
  };

  // ------------------------------
  // Open/Close Search Modals
  // ------------------------------
  const openSearchModal = () => {
    setIsSearchModalOpen(true);
  };

  const closeSearchModal = async () => {
    setIsSearchModalOpen(false);
    // Optionally, refresh user data after modal closes
  };

  const openWishlistSearchModal = () => {
    setIsWishlistSearchModalOpen(true);
  };

  const closeWishlistSearchModal = async () => {
    setIsWishlistSearchModalOpen(false);
    // Optionally, refresh user data after modal closes
  };

  if (!user) {
    return (
      <div className={styles.profileContainer}>
        <p className={styles.noSavedProducts}>No user is authenticated. Please sign in.</p>
      </div>
    );
  }

  return (
    <div className={styles.profileContainer}>
      {/* Profile Page Title and Description */}
      <h1 className={styles.mainPageTitle}>
        <span className="title-black">Your  </span>
        <span className="title-purple">Beauty </span> Profile
      </h1>
      <p className={styles.pageDescription}>
        Manage your preferences, track your progress, and personalize your beauty journey.
      </p>

      {/* User Details Header */}
      <div className={styles.userDetailsHeader}>
        <h2 className={styles.sectionHeader}>User Details</h2>
        <div className={styles.optionsContainer}>
          <span
            className={styles.moreOptions}
            role="button"
            tabIndex={0}
            onClick={() => setOptionsMenuOpen((prev) => !prev)}
            onKeyPress={(e) => {
              if (e.key === 'Enter' || e.key === ' ') setOptionsMenuOpen((prev) => !prev);
            }}
            aria-label="More options"
          >
            &#8942;
          </span>
          {optionsMenuOpen && (
            <div className={styles.optionsMenu}>
              <button onClick={() => {
                setEditProfileModalOpen(true);
                setOptionsMenuOpen(false);
              }}>
                Edit Profile
              </button>
              <button onClick={async () => {
                try {
                  await signOut(auth);
                  navigate('/upload');
                } catch (error) {
                  console.error('Error signing out:', error);
                }
              }}>
                Sign Out
              </button>
              <button onClick={async () => {
                if (!user) return;
                const confirmDelete = window.confirm(
                  'Are you sure you want to delete your account? This action is irreversible.'
                );
                if (!confirmDelete) return;
                try {
                  await deleteUser(user);
                  navigate('/upload');
                } catch (error) {
                  console.error('Error deleting user account:', error);
                  alert('Failed to delete account. Please sign in again or try later.');
                }
              }} style={{ color: 'red' }}>
                Delete Account
              </button>
            </div>
          )}
        </div>
      </div>

      {/* User Details */}
      <div className={styles.userDetailsContainer}>
        {userPhoto ? (
          <img
            src={userPhoto}
            alt="Profile"
            className={styles.userProfileImage}
            style={{ width: '150px', height: '150px', borderRadius: '50%' }}
            onError={(e) => { e.target.src = defaultAvatar; }}
          />
        ) : (
          <div className={styles.placeholderImage}>No Image</div>
        )}
        <div className={styles.userInfo}>
          <p>
            <strong>Name:</strong> {userName}
          </p>
          <p>
            <strong>Email:</strong> {user.email}
          </p>
          <p>
            <strong>Credits:</strong> {credits}
          </p>
        </div>
      </div>

      {/* Current Beauty Routine Section */}
      <div className={styles.section}>
        <div className={styles.sectionHeaderContainer}>
          <h2 className={styles.sectionHeader}>Current Beauty Routine</h2>
          <button className={styles.addProductButton} onClick={openSearchModal}>
            Add Products to Routine
          </button>
        </div>
        {savedProducts.length > 0 ? (
          <div className={styles.productScrollContainer}>
            <div className={styles.productScroll}>
              {savedProducts.map((product) => (
                <div
                  key={product.id}
                  className={`${styles.productCard} ${
                    flippedProducts[product.id] ? styles.flipped : ''
                  }`}
                  onClick={(e) => {
                    if (!e.target.closest(`.${styles.removeButton}`)) {
                      toggleCardFlip(product.id);
                    }
                  }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') toggleCardFlip(product.id);
                  }}
                  tabIndex="0"
                  role="button"
                  aria-expanded={!!flippedProducts[product.id]}
                  aria-label={`Toggle ingredients for ${product.title}`}
                >
                  <div className={styles.cardInner}>
                    {/* Front Side */}
                    <div className={styles.cardFront}>
                      {product.primary_image && (
                        <img
                          src={product.primary_image}
                          alt={product.title}
                          className={styles.productImage}
                          onError={(e) => {
                            e.target.style.display = 'none';
                          }}
                        />
                      )}
                      <div className={styles.productInfo}>
                        <h3 className={`${styles.productTitle} ${styles.title}`}>
                          {product.title}
                        </h3>
                        <p className={styles.productBrand}>
                          <strong>Brand:</strong> {product.brand}
                        </p>
                      </div>
                    </div>
                    {/* Back Side */}
                    <div className={styles.cardBack}>
                      <div className={styles.backContent}>
                        <h3 className={styles.fullProductTitle}>{product.title}</h3>
                        <p className={styles.productBrand}>
                          <strong>Brand:</strong> {product.brand}
                        </p>
                        <div className={styles.ingredientsSection}>
                          <strong>Ingredients:</strong>
                          {product.matched_ingredients ? (
                            <div className={styles.ingredientsList}>
                              {product.matched_ingredients
                                .split(',')
                                .map((ingredient) => ingredient.trim())
                                .map((ingredientName, index) => {
                                  const ingredientData = titleToIngredientMap.get(
                                    ingredientName.toLowerCase()
                                  );
                                  return (
                                    <button
                                      key={index}
                                      className={`${styles.ingredientButton} ${
                                        styles[
                                          ingredientData
                                            ? getSafetyClass(ingredientData.ewg?.decision || '')
                                            : 'safetyUnknown'
                                        ]
                                      }`}
                                      onClick={() => handleIngredientClick(ingredientName)}
                                      aria-label={`View details for ${ingredientName}`}
                                    >
                                      {ingredientName}
                                    </button>
                                  );
                                })}
                            </div>
                          ) : (
                            <p>Not available.</p>
                          )}
                        </div>
                        {product.asin && (
                          <a
                            href={`http://www.amazon.com/dp/${product.asin}/ref=nosim?tag=goldenratio08-20`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={styles.buyMoreButton}
                            aria-label={`Buy more of ${product.title} on Amazon`}
                          >
                            Buy More
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                  <button
                    className={styles.removeButton}
                    onClick={(e) => {
                      e.stopPropagation();
                      setProductToRemove(product);
                    }}
                    aria-label={`Remove ${product.title} from your routine`}
                  >
                    &times;
                  </button>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <p className={styles.noSavedProducts}>
            Your beauty routine is empty. Click above to add products.
          </p>
        )}
      </div>

      {/* Shopping Cart Section */}
      <div className={styles.section}>
        <div className={styles.sectionHeaderContainer}>
          <h2 className={styles.sectionHeader}>Shopping Cart</h2>
          <button className={styles.addProductButton} onClick={openWishlistSearchModal}>
            Add Products to Cart
          </button>
        </div>
        {wishlistProducts.length > 0 ? (
          <div className={styles.productScrollContainer}>
            <div className={styles.productScroll}>
              {wishlistProducts.map((product) => (
                <div
                  key={product.id}
                  className={`${styles.productCard} ${
                    flippedWishlist[product.id] ? styles.flipped : ''
                  }`}
                  onClick={(e) => {
                    if (!e.target.closest(`.${styles.removeButton}`)) {
                      toggleCardFlipWishlist(product.id);
                    }
                  }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') toggleCardFlipWishlist(product.id);
                  }}
                  tabIndex="0"
                  role="button"
                  aria-expanded={!!flippedWishlist[product.id]}
                  aria-label={`Toggle ingredients for ${product.title}`}
                >
                  <div className={styles.cardInner}>
                    {/* Front Side */}
                    <div className={styles.cardFront}>
                      {product.primary_image && (
                        <img
                          src={product.primary_image}
                          alt={product.title}
                          className={styles.productImage}
                          onError={(e) => {
                            e.target.style.display = 'none';
                          }}
                        />
                      )}
                      <div className={styles.productInfo}>
                        <h3 className={`${styles.productTitle} ${styles.title}`}>
                          {product.title}
                        </h3>
                        <p className={styles.productBrand}>
                          <strong>Brand:</strong> {product.brand}
                        </p>
                      </div>
                    </div>
                    {/* Back Side */}
                    <div className={styles.cardBack}>
                      <div className={styles.backContent}>
                        <h3 className={styles.fullProductTitle}>{product.title}</h3>
                        <p className={styles.productBrand}>
                          <strong>Brand:</strong> {product.brand}
                        </p>
                        <div className={styles.ingredientsSection}>
                          <strong>Ingredients:</strong>
                          {product.matched_ingredients ? (
                            <div className={styles.ingredientsList}>
                              {product.matched_ingredients
                                .split(',')
                                .map((ingredient) => ingredient.trim())
                                .map((ingredientName, index) => {
                                  const ingredientData = titleToIngredientMap.get(
                                    ingredientName.toLowerCase()
                                  );
                                  return (
                                    <button
                                      key={index}
                                      className={`${styles.ingredientButton} ${
                                        styles[
                                          ingredientData
                                            ? getSafetyClass(ingredientData.ewg?.decision || '')
                                            : 'safetyUnknown'
                                        ]
                                      }`}
                                      onClick={() => handleIngredientClick(ingredientName)}
                                      aria-label={`View details for ${ingredientName}`}
                                    >
                                      {ingredientName}
                                    </button>
                                  );
                                })}
                            </div>
                          ) : (
                            <p>Not available.</p>
                          )}
                        </div>
                        {product.asin && (
                          <a
                            href={`http://www.amazon.com/dp/${product.asin}/ref=nosim?tag=goldenratio08-20`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={styles.buyMoreButton}
                            aria-label={`Buy more of ${product.title} on Amazon`}
                          >
                            Buy More
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                  <button
                    className={styles.removeButton}
                    onClick={(e) => {
                      e.stopPropagation();
                      setWishlistItemToRemove(product);
                    }}
                    aria-label={`Remove ${product.title} from your cart`}
                  >
                    &times;
                  </button>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <p className={styles.noSavedProducts}>
            Your cart is empty. Click above to add products.
          </p>
        )}
      </div>

      {isSearchModalOpen && (
        <SearchModal isOpen={isSearchModalOpen} onClose={closeSearchModal} />
      )}
      {isWishlistSearchModalOpen && (
        <SearchModal isOpen={isWishlistSearchModalOpen} onClose={closeWishlistSearchModal} />
      )}
      {selectedIngredient && (
        <IngredientModal
          ingredient={selectedIngredient}
          onClose={() => setSelectedIngredient(null)}
        />
      )}
      {productToRemove && (
        <ConfirmRemoveModal
          product={productToRemove}
          onConfirm={() => {
            removeProduct(productToRemove.id);
            setProductToRemove(null);
          }}
          onCancel={() => setProductToRemove(null)}
        />
      )}
      {wishlistItemToRemove && (
        <ConfirmRemoveModal
          product={wishlistItemToRemove}
          onConfirm={() => {
            removeWishlistProduct(wishlistItemToRemove.id);
            setWishlistItemToRemove(null);
          }}
          onCancel={() => setWishlistItemToRemove(null)}
        />
      )}
      {editProfileModalOpen && (
        <EditProfileModal
          currentName={userName}
          currentPhoto={userPhoto}
          onClose={() => setEditProfileModalOpen(false)}
          onSave={handleProfileSave}
        />
      )}
    </div>
  );
}

export default ProfilePage;
