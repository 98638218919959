import React from 'react';
import '../styles/HeroSection.css'; // Import the CSS file

const HeroSection = () => {
  return (
    <section className="hero-section-container">
      {/* Decorative Ellipses can be enabled if needed */}
      {/* <div className="hero-ellipse hero-ellipse-left"></div> */}
      {/* <div className="hero-ellipse hero-ellipse-right"></div> */}

      <div className="hero-content">
        <h1 className="hero-title">Transform Your Beauty Routine Today</h1>
        <p className="hero-subtitle">
          Scan your face, chat with AI, and unlock personalized product suggestions.
        </p>
        <button
          className="hero-button"
          onClick={() => (window.location.href = '/upload')}
        >
          Start Your Scan
        </button>
      </div>
    </section>
  );
};

export default HeroSection;
