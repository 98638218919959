import React, { useEffect, useState } from 'react';

function ProgressBar({ percent }) {
  const [animatedPercent, setAnimatedPercent] = useState(0);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setAnimatedPercent(Math.ceil(percent));
    }, 100);
    return () => clearTimeout(timeout);
  }, [percent]);

  const getBackgroundColor = () => {
    return `linear-gradient(to right, #FBE9FD, #7E3BA1)`;
  };

  return (
    <div style={{ width: '100%', backgroundColor: '#e0e0e0', borderRadius: '15px', margin: '10px 0', position: 'relative' }}>
      <div
        style={{
          width: `${animatedPercent}%`,
          background: getBackgroundColor(),
          height: '30px',
          borderRadius: '15px',
          display: 'flex',
          alignItems: 'center',
          padding: '0 8px',
          boxSizing: 'border-box',
          color: '#000',
          fontWeight: '400',
          fontSize: '16px',
          position: 'relative',
          transition: 'width 1s ease-in-out'
        }}
      >
      </div>
      <span style={{ position: 'absolute', left: '8px', top: '50%', transform: 'translateY(-50%)', color: '#000', fontWeight: '400', fontSize: '16px' }}>
        Better Than {`${animatedPercent}%`} Percent Of People
      </span>
    </div>
  );
}

export default ProgressBar;
