// src/components/Authorized/screens/BeautyConsultantPage.js

import React, { useState, useEffect, useRef } from 'react';
import '../../styles/BeautyConsultant.css';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

// Import Algolia search client
import algoliasearch from 'algoliasearch/lite';

// Import the ingredient data
import cleanedIngredients from '../../../assets/data/cleaned_ingredients.json';

// Import the CSS for the slider
import 'rc-slider/assets/index.css';

// Import the slider component
import Slider from 'rc-slider';

// Utility functions
const formatPropertyName = (str) => {
  return str
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const getClassForValue = (value) => {
  return `color${value}`;
};

const getSafetyClass = (decision) => {
  if (decision.includes('Safe') && !decision.includes('hazard')) {
    return 'safetySafe';
  } else if (decision.includes('Safe - Low hazard')) {
    return 'safetyLightgreen';
  } else if (
    decision.includes('Safe - Moderate hazard') ||
    decision.includes('Fair') ||
    decision.includes('Limited')
  ) {
    return 'safetyModerate';
  } else if (decision.includes('Moderate hazard')) {
    return 'safetyWarning';
  } else if (decision.includes('Unsafe') || decision.includes('High hazard')) {
    return 'safetyUnsafe';
  } else {
    return 'safetyUnknown';
  }
};

/**
 * Strips surrounding single or double quotes from a string.
 * @param {string} str - The string to sanitize.
 * @returns {string} - The sanitized string without surrounding quotes.
 */
const stripQuotes = (str) => {
  if (typeof str !== 'string') return '';
  return str.replace(/^['"]+|['"]+$/g, '');
};

// Create a mapping from ingredient titles to ingredient data
const titleToIngredientMap = new Map();
cleanedIngredients.forEach((ingredient) => {
  titleToIngredientMap.set(ingredient.title.toLowerCase(), ingredient);
});

// Utility function to shuffle an array (Fisher-Yates Shuffle)
const shuffleArray = (array) => {
  const shuffled = [...array];
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  return shuffled;
};

// Ingredient Modal Component
function IngredientModal({ ingredient, onClose }) {
  if (!ingredient) return null;

  return (
    <div className="ingredientModal" onClick={onClose} role="dialog" aria-modal="true">
      <div className="ingredientContent" onClick={(e) => e.stopPropagation()}>
        <h2>{ingredient.title}</h2>
        {ingredient.ewg && (
          <>
            <h3>EWG Decision:</h3>
            <p>{ingredient.ewg.decision}</p>
          </>
        )}
        {ingredient.categories && ingredient.categories.trim() !== '' && (
          <>
            <h3>Categories:</h3>
            <p>{ingredient.categories}</p>
          </>
        )}

        {ingredient && (
          <>
            <h3>Properties:</h3>
            <div className="propertiesContainer">
              {ingredient.boolean_properties &&
                Object.entries(ingredient.boolean_properties)
                  .filter(([key, value]) => value)
                  .map(([key]) => (
                    <span key={key} className={`propertyBubble booleanBubble`}>
                      {formatPropertyName(key)}
                    </span>
                  ))}

              {ingredient.integer_properties &&
                Object.entries(ingredient.integer_properties).map(([key, value]) => (
                  <span
                    key={key}
                    className={`propertyBubble integerBubble ${getClassForValue(value)}`}
                    title={`Rating: ${value}`}
                  >
                    {formatPropertyName(key)}
                  </span>
                ))}
            </div>
          </>
        )}

        <h3>Key:</h3>
        <div className="keyContainer">
          <div className="keyItem">
            <span className={`safetyColorBox safetySafe`}></span> Safe
          </div>
          <div className="keyItem">
            <span className={`safetyColorBox safetyLightgreen`}></span> Low Hazard
          </div>
          <div className="keyItem">
            <span className={`safetyColorBox safetyModerate`}></span> Moderate Hazard
          </div>
          <div className="keyItem">
            <span className={`safetyColorBox safetyWarning`}></span> Warning
          </div>
          <div className="keyItem">
            <span className={`safetyColorBox safetyUnsafe`}></span> Risky
          </div>
          <div className="keyItem">
            <span className={`safetyColorBox safetyUnknown`}></span> Unknown
          </div>
        </div>

        <button onClick={onClose} className="ingredientCloseModalButton" aria-label="Close Ingredient Details">
          Close
        </button>
      </div>
    </div>
  );
}

// Confirmation Modal Component
function ConfirmRemoveModal({ product, onConfirm, onCancel }) {
  if (!product) return null;

  return (
    <div className="confirmModal" onClick={onCancel} role="dialog" aria-modal="true">
      <div className="confirmContent" onClick={(e) => e.stopPropagation()}>
        <h2>Confirm Removal</h2>
        <p>
          Are you sure you want to remove <strong>{product.title}</strong> from your beauty routine?
        </p>
        <div className="confirmButtons">
          <button onClick={onConfirm} className="confirmButton">
            Yes, Remove
          </button>
          <button onClick={onCancel} className="cancelButton">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

// Product Modal Component
function ProductModal({
  product,
  onClose,
  addProduct,
  removeProduct,
  currentBeautyRoutine,
  handleIngredientClick,
  associateId,
}) {
  if (!product) return null;

  const isAdded = currentBeautyRoutine.some((p) => p.id === product.objectID || p.id === product.id);
  const amazonLink = product.asin
    ? `http://www.amazon.com/dp/${product.asin}/ref=nosim?tag=${associateId}`
    : null;

  return (
    <div className="productModal" onClick={onClose} role="dialog" aria-modal="true">
      <div className="productContent" onClick={(e) => e.stopPropagation()}>
        <button onClick={onClose} className="closeModalButton" aria-label="Close Product Details">
          &times;
        </button>
        {product.primary_image && (
          <img
            src={product.primary_image}
            alt={product.title}
            className="modal-product-image"
            onError={(e) => {
              e.target.style.display = 'none';
            }}
          />
        )}
        <h2>{product.title}</h2>
        <p className="product-brand">
          <strong>Brand:</strong> {product.brand}
        </p>
        <div className="ingredients-section">
          <strong>Ingredients:</strong>
          {product.matched_ingredients ? (
            <div className="ingredients-list">
              {product.matched_ingredients
                .split(',')
                .map((ingredient) => ingredient.trim())
                .map((ingredientName, index) => {
                  const ingredientData = titleToIngredientMap.get(ingredientName.toLowerCase());
                  return (
                    <button
                      key={index}
                      className={`ingredient-button ${
                        ingredientData
                          ? getSafetyClass(ingredientData.ewg?.decision || '')
                          : 'safetyUnknown'
                      }`}
                      onClick={() => handleIngredientClick(ingredientName)}
                      aria-label={`View details for ${ingredientName}`}
                    >
                      {ingredientName}
                    </button>
                  );
                })}
            </div>
          ) : (
            <p>Not available.</p>
          )}
        </div>
        {amazonLink && (
          <a
            href={amazonLink}
            target="_blank"
            rel="noopener noreferrer"
            className="buy-now-button"
            aria-label={`Buy ${product.title} on Amazon`}
          >
            Buy Now
          </a>
        )}
        {isAdded ? (
          <button
            className="remove-button-modal"
            onClick={() => {
              removeProduct(product.id);
              onClose();
            }}
            aria-label={`Remove ${product.title} from your routine`}
          >
            Remove from Routine
          </button>
        ) : (
          <button
            className="add-button-modal"
            onClick={() => {
              addProduct(product);
              onClose();
            }}
            aria-label={`Add ${product.title} to your beauty routine`}
          >
            Add to Routine
          </button>
        )}
      </div>
    </div>
  );
}

// Initialize Algolia client
const algoliaClient = algoliasearch('M3H72YNQOO', 'd960357729bf8a27fe5ab22d0034dfa5');
const index = algoliaClient.initIndex('finished_products_dataset');

const TRAIT_RANGES = {
  acne: { min: 0, max: 100 },
  oiliness: { min: 0, max: 100 },
  pigmentation: { min: 0, max: 100 },
  wrinkles: { min: 0, max: 100 },
  dark_circles: { min: 0, max: 100 },
  eye_bags: { min: 0, max: 100 },
};

const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

const BeautyConsultantPage = () => {
  const [messages, setMessages] = useState([]);
  const [conversationStep, setConversationStep] = useState('loading');
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const typingIntervalRef = useRef(null);

  const [productRecommendations, setProductRecommendations] = useState([]);
  const [currentBeautyRoutine, setCurrentBeautyRoutine] = useState([]);

  const auth = getAuth();
  const db = getFirestore();
  const [user, loadingUser] = useAuthState(auth);
  const location = useLocation();
  const navigate = useNavigate();
  const scanId = location.state?.scanId;
  const userId = user ? user.uid : null;

  const CHAT_SESSION_DOC_ID = 'main';

  const lastSavedMessagesRef = useRef({
    messages: [],
    productRecommendations: [],
    currentBeautyRoutine: [],
  });

  const [selectedTraits, setSelectedTraits] = useState({
    acne: 0,
    oiliness: 0,
    pigmentation: 0,
    wrinkles: 0,
    dark_circles: 0,
    eye_bags: 0,
  });

  const [previousSummaries, setPreviousSummaries] = useState([]);
  const [selectedIngredient, setSelectedIngredient] = useState(null);
  const [productToRemove, setProductToRemove] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const messagesEndRef = useRef(null);
  const chatContainerRef = useRef(null);

  useEffect(() => {
    if (loadingUser || !userId || !scanId) {
      console.error('Missing scanId or user not loaded');
      navigate('/scans');
      return;
    }

    const chatSessionRef = doc(db, 'users', userId, 'scans', scanId, 'chatSession', CHAT_SESSION_DOC_ID);

    const loadMessages = async () => {
      try {
        const docSnap = await getDoc(chatSessionRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          if (data.messages && data.messages.length > 0) {
            setMessages(data.messages);
            setConversationStep('chat');
          } else {
            setMessages([
              {
                sender: 'assistant',
                text: "Hello! I am your AI Beauty Assistant. Let's start by understanding your skin. Please confirm or adjust your skin traits below.",
              },
            ]);
            setConversationStep('traits-confirmation');
          }
          if (data.productRecommendations) {
            setProductRecommendations(data.productRecommendations);
          }
          if (data.currentBeautyRoutine) {
            setCurrentBeautyRoutine(data.currentBeautyRoutine);
          }
          lastSavedMessagesRef.current = {
            messages: data.messages || [],
            productRecommendations: data.productRecommendations || [],
            currentBeautyRoutine: data.currentBeautyRoutine || [],
          };
        } else {
          setMessages([
            {
              sender: 'assistant',
              text: "Hello! I am your AI Beauty Assistant. Let's start by understanding your skin. Please confirm or adjust your skin traits below.",
            },
          ]);
          setConversationStep('traits-confirmation');
        }
      } catch (error) {
        console.error('Error loading chat session:', error);
      }
    };

    const loadCurrentBeautyRoutine = async () => {
      try {
        const routineRef = doc(db, 'users', userId, 'UserData', 'currentBeautyRoutine');
        const routineSnap = await getDoc(routineRef);
        if (routineSnap.exists()) {
          const data = routineSnap.data();
          if (data.products) {
            setCurrentBeautyRoutine(data.products);
          }
        }
      } catch (error) {
        console.error('Error loading current beauty routine:', error);
      }
    };

    const loadPreviousSummaries = async () => {
      try {
        const summariesRef = doc(db, 'users', userId, 'UserData', 'chatSummaries');
        const docSnap = await getDoc(summariesRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          if (data.summaries) {
            setPreviousSummaries(data.summaries);
          }
        }
      } catch (error) {
        console.error('Error loading previous summaries:', error);
      }
    };

    const loadFacialFeatures = async () => {
      try {
        const scanDocRef = doc(db, 'users', userId, 'scans', scanId);
        const docSnap = await getDoc(scanDocRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          if (data.confirmedTraits) {
            setSelectedTraits(data.confirmedTraits);
          } else {
            const fullScanId = data.full_scan_id;
            const fullScanDocRef = doc(db, 'users', userId, 'scans', scanId, 'fullScan', fullScanId);
            const fullDocSnap = await getDoc(fullScanDocRef);
            const facialFeatures = fullDocSnap.data().frontResult.facial_features;
            let adjustedAcne = (facialFeatures.acne ?? 0) - 30;
            adjustedAcne = Math.max(adjustedAcne, 0);
            // Limit to two decimal places
            adjustedAcne = Math.round(adjustedAcne * 100) / 100;
            if (facialFeatures) {
              setSelectedTraits({
                acne: adjustedAcne ?? 0,
                oiliness: facialFeatures.oiliness ?? 0,
                pigmentation: facialFeatures.pigmentation ?? 0,
                wrinkles: facialFeatures.wrinkles ?? 0,
                dark_circles: facialFeatures.dark_circles ?? 0,
                eye_bags: facialFeatures.eye_bags ?? 0,
              });
            }
          }
        }
      } catch (error) {
        console.error('Error loading facial features:', error);
      }
    };

    loadMessages();
    loadCurrentBeautyRoutine();
    loadPreviousSummaries();
    loadFacialFeatures();
  }, [userId, scanId, loadingUser, navigate, db]);

  const saveChatSession = async (currentMessages, currentProductRecommendations, currentBeautyRoutine) => {
    if (!userId || !scanId) {
      console.error('User ID or Scan ID is missing');
      return;
    }

    const chatSessionRef = doc(db, 'users', userId, 'scans', scanId, 'chatSession', CHAT_SESSION_DOC_ID);

    try {
      await setDoc(
        chatSessionRef,
        { messages: currentMessages, productRecommendations: currentProductRecommendations },
        { merge: true }
      );

      const routineRef = doc(db, 'users', userId, 'UserData', 'currentBeautyRoutine');
      await setDoc(routineRef, { products: currentBeautyRoutine }, { merge: true });

      lastSavedMessagesRef.current = {
        messages: currentMessages,
        productRecommendations: currentProductRecommendations,
        currentBeautyRoutine,
      };
    } catch (error) {
      console.error('Error saving chat session and beauty routine:', error);
    }
  };

  const handleSend = async (e) => {
    e.preventDefault();
    if (input.trim() === '') return;

    const userMessage = { sender: 'user', text: input.trim() };

    const newMessages = [
      ...messages,
      userMessage,
      { sender: 'assistant', text: 'AI is thinking...' },
    ];

    setMessages(newMessages);
    setInput('');
    setLoading(true);
    setError(null);

    saveChatSession(newMessages, productRecommendations, currentBeautyRoutine);

    try {
      const functionUrl = 'https://us-central1-golden-ratio-23459.cloudfunctions.net/api/processChatGPT';

      const previousSummariesText = previousSummaries
        .map((summary) => `Date: ${summary.date}\n${summary.summary}`)
        .join('\n\n');

      const skinTraitsText = `The user's skin traits are as follows:
  - Acne: ${selectedTraits.acne}
  - Oiliness: ${selectedTraits.oiliness}
  - Pigmentation: ${selectedTraits.pigmentation}
  - Wrinkles: ${selectedTraits.wrinkles}
  - Dark Circles: ${selectedTraits.dark_circles}
  - Eye Bags: ${selectedTraits.eye_bags}`;

      const currentBeautyRoutineText =
        currentBeautyRoutine.length > 0
          ? `The user's current beauty routine includes the following products:
  ${currentBeautyRoutine.map((p, index) => `${index + 1}. ${p.title} by ${p.brand}`).join('\n')}`
          : 'The user has not added any products to their beauty routine yet.';

      const assistantMessages = [
        {
          role: 'system',
          content: `You are an AI Beauty Consultant. Your goal is to create a beauty routine for the user. Whenever you recommend products, you should:
      
      1. Provide your message to the user.
      
      2. After the message, list the product queries in the following exact format:
      
      Product Queries:
      - Step: <Step Name>
        Query: <Algolia search query for the product. Use the info from the chat and the syntax of '-ingredient' to exclude certain ingredients>
        Reason: <Reason for recommending this product>
      
      Ensure that the product queries are formatted exactly as above. If you are not providing product recommendations, respond normally.
      
      Here are summaries of your previous sessions:
      ${previousSummariesText}
      
      ${skinTraitsText}
      
      ${currentBeautyRoutineText}
      `,
        },
        ...newMessages.map((msg) => ({
          role: msg.sender === 'user' ? 'user' : 'assistant',
          content: msg.text,
        })),
      ];

      const response = await fetch(functionUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ messages: assistantMessages }),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch assistant response.');
      }

      const data = await response.json();
      const assistantResponse = data.response;

      setMessages((prevMessages) =>
        prevMessages.filter(
          (msg) => !(msg.sender === 'assistant' && msg.text === 'AI is thinking...')
        )
      );

      await handleAssistantResponse(assistantResponse);
    } catch (err) {
      console.error(err);
      setError('Sorry, something went wrong. Please try again.');
      setMessages((prevMessages) =>
        prevMessages.filter(
          (msg) => !(msg.sender === 'assistant' && msg.text === 'AI is thinking...')
        )
      );
    } finally {
      setLoading(false);
    }
  };

  const handleAssistantResponse = async (assistantResponse) => {
    const [messagePart, ...queryParts] = assistantResponse.split('Product Queries:');
    const message = messagePart.trim();

    // Start typing animation for the assistant's message.
    await startTypingAnimation(message);

    let newProductRecommendations = [];

    if (queryParts.length > 0) {
      const productQueriesText = queryParts.join('Product Queries:').trim();
      const productQueries = parseProductQueries(productQueriesText);

      for (let productQuery of productQueries) {
        if (!productQuery.query) {
          console.error('Product query is missing the query field:', productQuery);
          continue;
        }
        const sanitizedQuery = stripQuotes(productQuery.query);
        const { hits, nbPages } = await searchProducts(sanitizedQuery);
        const shuffledHits = shuffleArray(hits);
        newProductRecommendations.push({
          step: productQuery.step,
          reason: productQuery.reason,
          products: shuffledHits,
          displayCount: 3,
          currentPage: 0,
          totalPages: nbPages,
          query: sanitizedQuery,
        });
      }
    }

    const updatedMessages = [...messages];

    if (newProductRecommendations.length > 0) {
      setProductRecommendations(newProductRecommendations);
      saveChatSession(updatedMessages, newProductRecommendations, currentBeautyRoutine);
    } else {
      saveChatSession(updatedMessages, productRecommendations, currentBeautyRoutine);
    }
  };

  const parseProductQueries = (text) => {
    const lines = text.split('\n');
    const productQueries = [];
    let currentQuery = null;

    for (let line of lines) {
      line = line.trim();
      if (line.startsWith('- Step:')) {
        if (currentQuery) {
          productQueries.push(currentQuery);
        }
        currentQuery = { step: stripQuotes(line.replace('- Step:', '').trim()) };
      } else if (line.startsWith('Query:')) {
        currentQuery.query = stripQuotes(line.replace('Query:', '').trim());
      } else if (line.startsWith('Reason:')) {
        currentQuery.reason = stripQuotes(line.replace('Reason:', '').trim());
      } else if (line === '' && currentQuery) {
        productQueries.push(currentQuery);
        currentQuery = null;
      }
    }
    if (currentQuery) {
      productQueries.push(currentQuery);
    }
    return productQueries;
  };

  const searchProducts = async (query, page = 0) => {
    try {
      const searchOptions = {
        query: query,
        hitsPerPage: 20,
        page: page,
        advancedSyntax: true,
      };
      const { hits, nbPages } = await index.search(query, searchOptions);
      return { hits, nbPages };
    } catch (error) {
      console.error('Error searching for products:', error);
      return { hits: [], nbPages: 0 };
    }
  };

  const handleEndSession = async () => {
    setLoading(true);
    await saveChatSession(messages, productRecommendations, currentBeautyRoutine);

    try {
      const functionUrl = 'https://us-central1-golden-ratio-23459.cloudfunctions.net/api/processChatGPT';

      const summaryPrompt = `
As an AI Beauty Consultant, please provide a concise summary of the following chat session. Focus on key details like skin traits, preferences, ingredients to avoid, and recommendations.

Chat session:
${messages
  .map((msg) => (msg.sender === 'user' ? `User: ${msg.text}` : `Assistant: ${msg.text}`))
  .join('\n')}

Please provide the summary below.
      `;

      const assistantMessages = [
        { role: 'system', content: 'You are an AI Beauty Consultant.' },
        { role: 'user', content: summaryPrompt },
      ];

      const response = await fetch(functionUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ messages: assistantMessages }),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch assistant response.');
      }

      const data = await response.json();
      const assistantResponse = data.response.trim();

      const summaryRef = doc(db, 'users', userId, 'UserData', 'chatSummaries');
      const date = new Date().toISOString();

      const docSnap = await getDoc(summaryRef);
      let summaries = [];
      if (docSnap.exists()) {
        const data = docSnap.data();
        if (data.summaries) {
          summaries = data.summaries;
        }
      }

      const existingIndex = summaries.findIndex((s) => s.scanId === scanId);
      if (existingIndex !== -1) {
        summaries[existingIndex] = { date: date, summary: assistantResponse, scanId };
      } else {
        summaries.push({ date: date, summary: assistantResponse, scanId });
      }

      await setDoc(summaryRef, { summaries: summaries }, { merge: true });
      navigate('/session-ended');
    } catch (error) {
      console.error('Error generating chat summary:', error);
      setError('Sorry, something went wrong while generating the session summary. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Modified typing animation: scroll to bottom when finished typing.
  const startTypingAnimation = (message) => {
    return new Promise((resolve) => {
      setMessages((prevMessages) => [...prevMessages, { sender: 'assistant', text: '' }]);
      let index = 0;
      const typingSpeed = 15;
      typingIntervalRef.current = setInterval(() => {
        index += 1;
        const currentText = message.substring(0, index);
        setMessages((prevMessages) => {
          const newMessages = [...prevMessages];
          newMessages[newMessages.length - 1].text = currentText;
          return newMessages;
        });
        if (index >= message.length) {
          clearInterval(typingIntervalRef.current);
          typingIntervalRef.current = null;
          // Scroll to bottom of chat box when typing is finished
          messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
          resolve();
        }
      }, typingSpeed);
    });
  };

  useEffect(() => {
    return () => {
      if (typingIntervalRef.current) {
        clearInterval(typingIntervalRef.current);
      }
    };
  }, []);

  const handleTraitChange = (trait, value) => {
    setSelectedTraits((prevTraits) => ({ ...prevTraits, [trait]: value }));
  };

  const handleConfirmTraits = async () => {
    try {
      if (!userId || !scanId) {
        console.error('User ID or Scan ID is missing');
        return;
      }
      const scanDocRef = doc(db, 'users', userId, 'scans', scanId);
      await setDoc(scanDocRef, { confirmedTraits: selectedTraits }, { merge: true });
    } catch (error) {
      console.error('Error saving confirmed traits:', error);
    }

    const userMessage = { sender: 'user', text: 'I have confirmed my skin traits.' };

    const newMessages = [
      ...messages,
      userMessage,
      { sender: 'assistant', text: 'AI is thinking...' },
    ];

    setMessages(newMessages);
    saveChatSession(newMessages, productRecommendations, currentBeautyRoutine);
    setConversationStep('chat');
    setLoading(true);

    try {
      const functionUrl = 'https://us-central1-golden-ratio-23459.cloudfunctions.net/api/processChatGPT';

      const previousSummariesText = previousSummaries
        .map((summary) => `Date: ${summary.date}\n${summary.summary}`)
        .join('\n\n');

      const skinTraitsText = `The user's current skin traits are:
  - Acne: ${selectedTraits.acne}
  - Oiliness: ${selectedTraits.oiliness}
  - Pigmentation: ${selectedTraits.pigmentation}
  - Wrinkles: ${selectedTraits.wrinkles}
  - Dark Circles: ${selectedTraits.dark_circles}
  - Eye Bags: ${selectedTraits.eye_bags}`;

      const assistantMessages = [
        {
          role: 'system',
          content: `You are an AI Beauty Consultant. Evaluate the user's skin traits, compare them to previous sessions, and provide feedback. Then, ask if the user has any more comments.`,
        },
        ...newMessages.map((msg) => ({
          role: msg.sender === 'user' ? 'user' : 'assistant',
          content: msg.text,
        })),
        {
          role: 'user',
          content: `${skinTraitsText}\n\nHere are previous summaries:\n${previousSummariesText}`,
        },
      ];

      const response = await fetch(functionUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ messages: assistantMessages }),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch assistant response.');
      }

      const data = await response.json();
      const assistantResponse = data.response.trim();

      setMessages((prevMessages) =>
        prevMessages.filter(
          (msg) => !(msg.sender === 'assistant' && msg.text === 'AI is thinking...')
        )
      );

      await startTypingAnimation(assistantResponse);
      const updatedMessages = [...messages];
      saveChatSession(updatedMessages, productRecommendations, currentBeautyRoutine);
    } catch (error) {
      console.error('Error generating assistant response:', error);
      setError('Sorry, something went wrong. Please try again.');
      setMessages((prevMessages) =>
        prevMessages.filter(
          (msg) => !(msg.sender === 'assistant' && msg.text === 'AI is thinking...')
        )
      );
    } finally {
      setLoading(false);
    }

    setConversationStep('chat');
  };

  const handleIngredientClick = (ingredientName) => {
    const ingredientData = titleToIngredientMap.get(ingredientName.toLowerCase());
    if (ingredientData) {
      setSelectedIngredient(ingredientData);
    } else {
      alert('Ingredient details not available.');
    }
  };

  const removeProduct = async (productId) => {
    if (!userId) return;
    const product = currentBeautyRoutine.find((p) => p.id === productId);
    if (!product) return;
    try {
      const userDataDocRef = doc(db, 'users', userId, 'UserData', 'currentBeautyRoutine');
      await setDoc(
        userDataDocRef,
        { products: currentBeautyRoutine.filter((p) => p.id !== productId) },
        { merge: true }
      );
      setCurrentBeautyRoutine((prevProducts) => prevProducts.filter((p) => p.id !== productId));
    } catch (error) {
      console.error('Error removing product:', error);
      alert('Failed to remove product. Please try again.');
    }
  };

  const addProduct = async (product) => {
    if (!userId) {
      alert('You must be logged in to add products to your routine.');
      return;
    }
    const isAlreadyAdded = currentBeautyRoutine.some((p) => p.id === product.objectID || p.id === product.id);
    if (isAlreadyAdded) {
      alert('This product is already in your beauty routine.');
      return;
    }
    const newProduct = {
      id: product.objectID || product.id,
      title: product.title,
      brand: product.brand,
      primary_image: product.primary_image,
      matched_ingredients: product.matched_ingredients,
      asin: product.asin,
    };
    const updatedBeautyRoutine = [...currentBeautyRoutine, newProduct];
    setCurrentBeautyRoutine(updatedBeautyRoutine);
    try {
      const routineRef = doc(db, 'users', userId, 'UserData', 'currentBeautyRoutine');
      await setDoc(routineRef, { products: updatedBeautyRoutine }, { merge: true });
    } catch (error) {
      console.error('Error adding product to beauty routine:', error);
      alert('Failed to add product to your beauty routine. Please try again.');
    }
  };

  const handleLoadMore = async (recIndex) => {
    const rec = productRecommendations[recIndex];
    const nextDisplayCount = rec.displayCount + 3;

    if (nextDisplayCount <= rec.products.length) {
      setProductRecommendations((prevRecommendations) => {
        const updatedRecommendations = [...prevRecommendations];
        updatedRecommendations[recIndex].displayCount = nextDisplayCount;
        return updatedRecommendations;
      });
      saveChatSession(messages, productRecommendations, currentBeautyRoutine);
    } else if (rec.currentPage + 1 < rec.totalPages) {
      const nextPage = rec.currentPage + 1;
      const { hits, nbPages } = await searchProducts(rec.query, nextPage);
      const shuffledHits = shuffleArray(hits);

      setProductRecommendations((prevRecommendations) => {
        const updatedRecommendations = [...prevRecommendations];
        updatedRecommendations[recIndex].products = [
          ...updatedRecommendations[recIndex].products,
          ...shuffledHits,
        ];
        updatedRecommendations[recIndex].currentPage = nextPage;
        updatedRecommendations[recIndex].totalPages = nbPages;
        updatedRecommendations[recIndex].displayCount = nextDisplayCount;
        return updatedRecommendations;
      });
      saveChatSession(messages, productRecommendations, currentBeautyRoutine);
    } else {
      console.log('No more products to load for this recommendation.');
    }
  };

  // Removed the auto-scroll useEffect.
  
  // Define Amazon Associate ID
  const associateId = 'goldenratio08-20';

  return (
    <div className="chat-page">
      {/* Updated Header using upload page title/subtitle classes */}
      <div className="chat-header authorized-upload-page__gradient-section">
        <h1 className="chat-title authorized-upload-page__gradient-title">Chat with your beauty ai</h1>
        <p className="chat-subtitle authorized-upload-page__gradient-subtitle">
          Ask questions, get advice and discover products customized to your unique beauty profile.
        </p>
      </div>

      <div className="chat-container">
        <div className="left-column">
          <div className="product-recommendations-container">
            <h3>Product Recommendations</h3>
            {productRecommendations.length > 0 ? (
              productRecommendations.map((rec, index) => (
                <div key={index} className="product-recommendation">
                  <h4>{rec.step}</h4>
                  <p>{rec.reason}</p>
                  <div className="product-scroll-container">
                    <div className="product-scroll">
                      {rec.products.slice(0, rec.displayCount).map((product) => (
                        <div
                          key={product.objectID || product.id}
                          className="card product-card"
                          onClick={() => setSelectedProduct(product)}
                          tabIndex="0"
                          role="button"
                          aria-label={`View details for ${product.title}`}
                        >
                          {product.primary_image && (
                            <img
                              src={product.primary_image}
                              alt={product.title}
                              className="product-image"
                              onError={(e) => {
                                e.target.style.display = 'none';
                              }}
                            />
                          )}
                          <div className="product-info">
                            <h5 className="product-title">{product.title}</h5>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  {rec.displayCount < rec.products.length || rec.currentPage + 1 < rec.totalPages ? (
                    <button
                      className="load-more-button"
                      onClick={() => handleLoadMore(index)}
                      aria-label={`Load more products for ${rec.step}`}
                    >
                      Load More
                    </button>
                  ) : null}
                </div>
              ))
            ) : (
              <p>No recommendations yet.</p>
            )}
          </div>
        </div>

        <div className="right-column">
          <div className="chat-messages-container">
            {conversationStep === 'traits-confirmation' ? (
              <div className="traits-section">
                <h2>
                  Here is what I detected. Please confirm or adjust your skin traits below:
                </h2>
                <div className="traits-sliders">
                  {Object.keys(TRAIT_RANGES).map((trait) => (
                    <div key={trait} className="trait-group">
                      <h3>{capitalize(trait)}</h3>
                      <Slider
                        min={TRAIT_RANGES[trait].min}
                        max={TRAIT_RANGES[trait].max}
                        value={selectedTraits[trait]}
                        onChange={(value) => handleTraitChange(trait, value)}
                      />
                      <span>{selectedTraits[trait]}</span>
                    </div>
                  ))}
                </div>
                <button className="confirm-button" onClick={handleConfirmTraits} disabled={loading}>
                  Confirm Traits
                </button>
              </div>
            ) : (
              <>
                <div className="chat-messages" ref={chatContainerRef}>
                  {messages.map((msg, index) => (
                    <div
                      key={index}
                      className={`message ${msg.sender === 'user' ? 'user-message' : 'assistant-message'}`}
                    >
                      {msg.sender === 'assistant' ? (
                        <ReactMarkdown remarkPlugins={[remarkGfm]}>{msg.text}</ReactMarkdown>
                      ) : (
                        msg.text
                      )}
                    </div>
                  ))}
                  {error && (
                    <div className="message error-message">
                      {error}
                    </div>
                  )}
                  <div ref={messagesEndRef} />
                </div>

                {/* Updated Chat Form: Buttons side-by-side */}
                <form className="chat-form" onSubmit={handleSend}>
                  <input
                    type="text"
                    className="chat-input"
                    placeholder="Type your message..."
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    disabled={loading}
                    aria-label="Type your message"
                  />
                  <div className="chat-form__buttons" style={{ display: 'flex', gap: '10px' }}>
                    <button type="submit" className="upload-button" disabled={loading} aria-label="Send message">
                      Send
                    </button>
                    <button
                      type="button"
                      onClick={handleEndSession}
                      className="end-session-button"
                      disabled={loading}
                    >
                      {loading ? 'Ending Session...' : 'End Session'}
                    </button>
                  </div>
                </form>
              </>
            )}
          </div>

          <div className="current-beauty-routine-container">
            <h3>Current Beauty Routine</h3>
            {currentBeautyRoutine.length > 0 ? (
              <div className="product-scroll-container">
                <div className="product-scroll">
                  {currentBeautyRoutine.map((product) => {
                    const amazonLink = product.asin
                      ? `http://www.amazon.com/dp/${product.asin}/ref=nosim?tag=${associateId}`
                      : null;
                    return (
                      <div
                        key={product.id}
                        className="card routine-card"
                        onClick={() => setSelectedProduct(product)}
                        tabIndex="0"
                        role="button"
                        aria-label={`View details for ${product.title}`}
                      >
                        {product.primary_image && (
                          <img
                            src={product.primary_image}
                            alt={product.title}
                            className="routine-image"
                            onError={(e) => {
                              e.target.style.display = 'none';
                            }}
                          />
                        )}
                        <div className="routine-info">
                          <h5 className="routine-title">{product.title}</h5>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <p>No current beauty routine found.</p>
            )}
          </div>
        </div>
      </div>

      {selectedIngredient && (
        <IngredientModal
          ingredient={selectedIngredient}
          onClose={() => setSelectedIngredient(null)}
        />
      )}

      {productToRemove && (
        <ConfirmRemoveModal
          product={productToRemove}
          onConfirm={() => {
            removeProduct(productToRemove.id);
            setProductToRemove(null);
          }}
          onCancel={() => setProductToRemove(null)}
        />
      )}

      {selectedProduct && (
        <ProductModal
          product={selectedProduct}
          onClose={() => setSelectedProduct(null)}
          addProduct={addProduct}
          removeProduct={removeProduct}
          currentBeautyRoutine={currentBeautyRoutine}
          handleIngredientClick={handleIngredientClick}
          associateId={associateId}
        />
      )}
    </div>
  );
};

export default BeautyConsultantPage;
