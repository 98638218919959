import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebaseConfig';
import AuthListener from './components/General/AuthListener';
import AuthRoute from './navigation/AuthRoute';
import UnauthRoute from './navigation/UnauthRoute';
import UploadPage from './components/Authorized/screens/UploadPage';
import ScansPage from './components/Authorized/screens/ScansPage';
import ResultsPage from './components/Authorized/screens/ResultsPage';
import AuthorizedHeader from './components/Authorized/components/AuthorizedHeader';
import UnauthorizedHeader from './components/Unauthorized/components/UnauthorizedHeader';
import UnauthorizedUploadPage from './components/Unauthorized/screens/UnauthorizedUploadPage';
import UnauthorizedResultsPage from './components/Unauthorized/screens/UnauthorizedResultsPage';
import AuthorizedContactUs from './components/Authorized/screens/AuthorizedContactUs';
import UnauthorizedContactUs from './components/Unauthorized/screens/UnauthorizedContactUs';
import ResultsPageSwitch from './components/Authorized/screens/ResultsPageSwitch';
import BeautyRoutinesPage from './components/Authorized/screens/BeautyRoutinesPage';
import UnauthorizedBeautyRoutinesPage from './components/Unauthorized/screens/UnauthorizedRoutinesPage';
// import AttractivenessEvaluator from './components/Authorized/screens/EvaluationScreen';
import BeautyConsultantPage from './components/Authorized/screens/BeautyConsultantPage';
import ProfilePage from './components/Authorized/screens/ProfilePage';
import { UserProvider } from './components/Authorized/components/UserContext';
import colors from './components/styles/colors';
import { isTestingEmail } from './utils/authUtils';
import HeroSection from './components/General/HeroSection';
import Footer from './components/General/Footer';

function App() {
  const [user, setUser] = useState(null);
  const [isVerified, setIsVerified] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setIsVerified(
        currentUser 
          ? (currentUser.emailVerified || isTestingEmail(currentUser)) 
          : false
      );
    });
    return () => unsubscribe();
  }, []);

  return (
    <UserProvider value={{ user, credits: 0, setCredits: () => {} }}>
      {/* Removed extra paddingBottom to eliminate space under the footer */}
      <div style={{ minHeight: '100vh', position: 'relative' }}>
        <Router>
          <AuthListener setUser={setUser} />
          {user && isVerified ? <AuthorizedHeader user={user} /> : <UnauthorizedHeader />}

          <Routes>
            <Route
              path="/upload"
              element={
                user && isVerified ? (
                  <AuthRoute component={UploadPage} user={user} />
                ) : (
                  <UnauthRoute component={UnauthorizedUploadPage} />
                )
              }
            />
            <Route
              path="/results"
              element={
                user && isVerified ? (
                  <AuthRoute component={ResultsPageSwitch} user={user} />
                ) : (
                  <UnauthRoute component={UnauthorizedResultsPage} />
                )
              }
            />
            <Route
              path="/scans"
              element={
                user && isVerified 
                  ? <AuthRoute component={ScansPage} user={user} />
                  : <Navigate to="/unauthorized" />
              }
            />
            <Route
              path="/contact"
              element={
                user && isVerified ? (
                  <AuthRoute component={AuthorizedContactUs} user={user} />
                ) : (
                  <UnauthRoute component={UnauthorizedContactUs} />
                )
              }
            />
            <Route
              path="/routines"
              element={
                user && isVerified ? (
                  <AuthRoute component={BeautyConsultantPage} user={user} />
                ) : (
                  <UnauthRoute component={UnauthorizedBeautyRoutinesPage} />
                )
              }
            />
            {/* <Route path="/gemini" element={<AttractivenessEvaluator />} /> */}
            <Route
              path="/profile"
              element={
                user && isVerified ? (
                  <AuthRoute component={ProfilePage} user={user} />
                ) : (
                  <UnauthRoute component={UnauthorizedUploadPage} />
                )
              }
            />
            <Route path="*" element={<Navigate to="/upload" />} />
          </Routes>

          {/* HeroSection placed after Routes */}
          <HeroSection />

          {/* Footer always at the bottom */}
          <Footer />
        </Router>
      </div>
    </UserProvider>
  );
}

export default App;
