import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import FrontResultsComponent from '../components/ScanResultPages/FullScan/FrontResultsComponent';
import SideResultsComponent from '../components/ScanResultPages/FullScan/SideResultsComponent';
import FailedSideResultsComponent from '../../General/FailedSideResultsComponent';
import FailedFrontResultsComponent from '../../General/FailedFrontResultsComponent';
import ProgressBar from '../../General/ProgressBar';
import BellCurve from '../../General/BellCurve';
import colors from '../../styles/colors';
import { calculateScorePercentile } from '../components/utils';

function UnlockedResultsPage({ data, scans, scanId }) {
  const [currentView, setCurrentView] = useState('front');
  const navigate = useNavigate();
  const scrollRef = useRef(null);

  // Use only the frontResult score to compute the overall score
  const overallScore =
    data && data.frontResult ? Math.ceil(data.frontResult.score * 10) : null;

  // Handler to navigate to routines (matching the front profile page style)
  const handleExploreRoutines = () => {
    if (data && data.routines) {
      navigate('/routines', { state: { routines: data.routines, scanId } });
    }
  };

  // Existing handlers for toggling front and side views
  const handleFrontClick = () => {
    setCurrentView('front');
  };

  const handleSideClick = () => {
    setCurrentView('side');
    console.log(data.sideResult.score);
  };

  const handleScanClick = (scan) => {
    navigate('/results', { state: { data: scan } });
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp.seconds * 1000);
    const today = new Date();
    return date.toDateString() === today.toDateString()
      ? date.toLocaleTimeString()
      : date.toLocaleDateString();
  };

  const scrollLeft = () => {
    scrollRef.current.scrollBy({ left: -200, behavior: 'smooth' });
  };

  const scrollRight = () => {
    scrollRef.current.scrollBy({ left: 200, behavior: 'smooth' });
  };

  return (
    <div className="profileContainer" style={{ minWidth: '1100px', overflowX: 'auto' }}>
      {/* Header Section */}
      <header style={{ textAlign: 'center', marginBottom: '20px' }}>
        <h1 className="mainPageTitle">Your AI Beauty Insights</h1>
        <p className="pageDescription">
          Discover personalized recommendations and insights tailored to your unique skin analysis.
        </p>
        <div className="header-content">
          {overallScore !== null && (
            <div style={{ fontSize: '36px', fontWeight: 'bold', color: '#2c3e50' }}>
              Overall Score: {overallScore} /100
            </div>
          )}
          <div className="header-buttons">
            <button
              className="explore-button"
              onClick={handleExploreRoutines}
              style={{ padding: '10px 20px', cursor: 'pointer', fontSize: '16px' }}
            >
              Explore Routines
            </button>
          </div>
        </div>
      </header>

      {/* Results Section */}
      <div>
        {currentView === 'front' && data?.frontResult && (
          data.frontResult.score === 0 ? (
            <FailedFrontResultsComponent imagePath={data.frontResult.image_path} />
          ) : (
            <FrontResultsComponent 
              data={data.frontResult} 
              routines={data.routines} 
              imagePath={data.frontResult.image_path} 
              scanId={scanId}
              onSwitchProfile={(profile) => setCurrentView(profile)}
            />
          )
        )}
        {currentView === 'side' && data?.sideResult && (
          (data.sideResult.score === 0 || data.sideResult.score === null) ? (
            <FailedSideResultsComponent imagePath={data.sideResult.image_path} />
          ) : (
            <SideResultsComponent 
              data={data.sideResult}
              routines={data.routines} 
              imagePath={data.sideResult.image_path} 
              scanId={scanId}
              onSwitchProfile={(profile) => setCurrentView(profile)}
            />
          )
        )}
      </div>
    </div>
  );
}

export default UnlockedResultsPage;
