import React, { useState, useMemo, useEffect } from 'react';
import SideFeaturesToggle from '../../SideFeaturesToggle';
import ProgressBar from '../../../../General/ProgressBar';
import ScoreRadarChart from '../../../../General/ScoreRadarChart';
import colors from '../../../../styles/colors';
import UnlockingModal from '../../UnlockingModal';
import LoginModal from '../../../../Unauthorized/components/LoginModal';
import { useNavigate } from 'react-router-dom';
import '../../../../styles/GlowUpContainerComponent.css';

function LockedSideResultsComponent({
  data,
  routines,
  imagePath,
  onUnlockConfirm,
  scanId,
  onSwitchProfile, // new prop to switch between profiles
}) {
  // State for visible side features
  const [visibleFeatures, setVisibleFeatures] = useState({
    philtrumFlatness: false,
    leftGonialAngle: false,
    nasofrontalAngle: false,
    nasalAngle: false,
    nasolabialAngle: false,
    nasalProjection: false,
    totalFacialConvexity: false,
    facialConvexity: false,
    mandibularPlaneAngle: false,
  });
  const [toggleView, setToggleView] = useState('traits');
  const [sortOrder, setSortOrder] = useState('default');
  const [isUnlockingModalOpen, setIsUnlockingModalOpen] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  const navigate = useNavigate();

  // Responsive hook: track window width
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const isMobile = windowWidth < 768;

  // Default side feature values
  const defaultSideFeatureData = {
    "Philtrum Flatness": 0.5,
    "Left Gonial Angle": 120,
    "Nasofrontal Angle": 140,
    "Nasal Angle": 95,
    "Nasolabial Angle": 105,
    "Nasal Projection": 15,
    "Total Facial Convexity": 1.2,
    "Facial Convexity": 0.8,
    "Mandibular Plane Angle": 90,
  };
  const defaultSidePercentiles = {
    "Philtrum Flatness": 60,
    "Left Gonial Angle": 70,
    "Nasofrontal Angle": 65,
    "Nasal Angle": 75,
    "Nasolabial Angle": 80,
    "Nasal Projection": 55,
    "Total Facial Convexity": 50,
    "Facial Convexity": 68,
    "Mandibular Plane Angle": 72,
  };

  const safeSideData = {
    data:
      data && data.sideResult && data.sideResult.data
        ? data.sideResult.data
        : defaultSideFeatureData,
    percentiles:
      data && data.sideResult && data.sideResult.percentiles
        ? data.sideResult.percentiles
        : defaultSidePercentiles,
  };

  // Sort side features based on selected ordering
  const sortedFeatures = useMemo(() => {
    const features = Object.keys(safeSideData.percentiles);
    if (sortOrder === 'best-to-worst') {
      return [...features].sort((a, b) => safeSideData.percentiles[b] - safeSideData.percentiles[a]);
    } else if (sortOrder === 'worst-to-best') {
      return [...features].sort((a, b) => safeSideData.percentiles[a] - safeSideData.percentiles[b]);
    }
    return features;
  }, [safeSideData.percentiles, sortOrder]);

  // Preset values for the radar chart (update if needed)
  const presetDynamicScores = {
    Jaw: 40,
    Mouth: 30,
    Nose: 45,
    Eyes: 35,
    Midface: 45,
  };
  const presetUserScore =
    data && data.sideResult && data.sideResult.score
      ? Math.ceil(data.sideResult.score * 10)
      : 80;
  const presetUserScorePercentile = presetUserScore
    ? presetUserScore > 0 && presetUserScore <= 100
      ? presetUserScore
      : 50
    : 50;

  // Toggle feature visibility
  const toggleFeature = (feature) => {
    setVisibleFeatures((prev) => ({
      ...prev,
      [feature]: !prev[feature],
    }));
  };

  // Button style definitions
  const activeButtonStyle = {
    border: '1px solid purple',
    color: 'purple',
    backgroundColor: 'transparent',
    padding: '10px 20px',
    cursor: 'pointer',
  };
  const inactiveButtonStyle = {
    border: '1px solid gray',
    color: 'gray',
    backgroundColor: 'transparent',
    padding: '10px 20px',
    cursor: 'pointer',
  };

  // Modal handlers
  const handleBlurClick = () => {
    setIsUnlockingModalOpen(true);
    document.body.classList.add('modal-open');
  };

  const closeUnlockingModal = () => {
    setIsUnlockingModalOpen(false);
    document.body.classList.remove('modal-open');
  };

  const closeLoginModal = () => {
    setIsLoginModalOpen(false);
    document.body.classList.remove('modal-open');
  };

  return (
    <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: 'flex-start', margin: '30px' }}>
      {/* Left Column: Ordering selector and side features list (blurred) */}
      <div
        style={{
          flex: isMobile ? '0 0 auto' : '0 0 30%',
          width: isMobile ? '100%' : undefined,
          marginRight: isMobile ? 0 : '20px',
          display: 'flex',
          flexDirection: 'column',
          height: isMobile ? 'auto' : '1200px',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '10px',
            filter: 'blur(3px)',
            cursor: 'pointer',
          }}
          onClick={handleBlurClick}
        >
          <label htmlFor="sortOrder" style={{ fontWeight: 'bold' }}>
            Sort Features:
          </label>
          <select
            id="sortOrder"
            value={sortOrder}
            onChange={(e) => setSortOrder(e.target.value)}
            style={{ padding: '5px', borderRadius: '5px' }}
          >
            <option value="default">Default</option>
            <option value="best-to-worst">Best to Worst</option>
            <option value="worst-to-best">Worst to Best</option>
          </select>
        </div>
        <div
          style={{
            border: '2px solid gray',
            borderRadius: '10px',
            padding: '10px',
            flex: 1,
            overflowY: 'auto',
            filter: 'blur(3px)',
            cursor: 'pointer',
          }}
          onClick={handleBlurClick}
        >
          <h3>All Features</h3>
          {sortedFeatures.map((key) => (
            <div key={key} style={{ marginBottom: '10px', minHeight: '60px' }}>
              <strong>{key}:</strong>{' '}
              {safeSideData.data[key] !== undefined ? safeSideData.data[key].toFixed(2) : '0.00'}
              <ProgressBar percent={safeSideData.percentiles[key]} style={{ width: '100%' }} />
            </div>
          ))}
        </div>
      </div>

      {/* Right Column: Image, Score Radar Chart, and toggle panel */}
      <div
        className="responsive-right-column"
        style={{
          flex: isMobile ? '0 0 auto' : '0 0 70%',
          width: isMobile ? '100%' : undefined,
          display: 'flex',
          flexDirection: 'column',
          height: isMobile ? 'auto' : '500px',
        }}
      >
        {/* Top Row: Processed image and radar chart */}
        <div style={{ display: 'flex', flex: '1 1 auto', flexDirection: isMobile ? 'column' : 'row' }}>
          <div
            style={{
              flex: isMobile ? '0 0 auto' : '1 1 50%',
              textAlign: 'left',
            }}
          >
            {/* Scan Type Heading */}
            <h3 style={{ fontSize: '24px', marginBottom: '5px' }}>Scan Type</h3>
            {/* Toggle Buttons Below Heading */}
            <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginBottom: '10px' }}>
              <button style={inactiveButtonStyle} onClick={() => onSwitchProfile('front')}>
                Front Profile
              </button>
              <button style={activeButtonStyle} onClick={() => onSwitchProfile('side')}>
                Side Profile
              </button>
            </div>
            {imagePath && (
              <div
                className="responsive-image-container"
                style={{ marginTop: '20px', width: '420px', height: 'auto', display: 'block', margin: '0 auto' }}
              >
                <img
                  src={imagePath}
                  alt="Processed"
                  style={{
                    width: '100%',
                    border: `4px solid ${colors.primary}`,
                    borderRadius: '25px',
                  }}
                />
              </div>
            )}
          </div>
          <div
            style={{
              flex: isMobile ? '0 0 auto' : '1 1 50%',
              textAlign: 'left',
              marginLeft: isMobile ? 0 : '20px',
              marginTop: isMobile ? '20px' : 0,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              filter: 'blur(3px)',
              cursor: 'pointer',
            }}
            onClick={handleBlurClick}
          >
            <div className="responsive-radar-container" style={{ width: '100%', margin: '0 auto' }}>
              <ScoreRadarChart
                dynamicScores={presetDynamicScores}
                userScore={presetUserScore}
                userScorePercentile={presetUserScorePercentile}
                style={{ width: '100%' }}
              />
            </div>
          </div>
        </div>

        {/* Bottom Section: Toggle buttons and features panel */}
        <div style={{ marginTop: '20px', flex: '0 0 auto' }}>
          <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', marginBottom: '20px' }}>
            <button
              onClick={() => setToggleView('traits')}
              style={toggleView === 'traits' ? activeButtonStyle : inactiveButtonStyle}
            >
              Traits
            </button>
            <button
              onClick={() => setToggleView('ratios')}
              style={toggleView === 'ratios' ? activeButtonStyle : inactiveButtonStyle}
            >
              Ratios
            </button>
          </div>
          <div
            style={{
              border: '2px solid gray',
              borderRadius: '10px',
              padding: '10px',
              height: isMobile ? 'auto' : '500px',
              overflowY: 'auto',
              filter: 'blur(3px)',
              cursor: 'pointer',
            }}
            onClick={handleBlurClick}
          >
            <SideFeaturesToggle visibleFeatures={visibleFeatures} toggleFeature={toggleFeature} />
          </div>
        </div>
      </div>
      {(isLoginModalOpen || isUnlockingModalOpen) && <div style={backdropStyles}></div>}
      <UnlockingModal
        isOpen={isUnlockingModalOpen}
        onClose={closeUnlockingModal}
        onConfirm={() => {
          closeUnlockingModal();
          onUnlockConfirm();
        }}
      />
      <LoginModal
        isOpen={isLoginModalOpen}
        onClose={closeLoginModal}
        onSignUpClick={() => {
          closeLoginModal();
          setIsUnlockingModalOpen(true);
          document.body.classList.add('modal-open');
        }}
      />
    </div>
  );
}

const backdropStyles = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: 998,
};

export default LockedSideResultsComponent;
