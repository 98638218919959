import React from 'react';
import colors from '../../styles/colors';

function UnlockingModal({ isOpen, onClose, onConfirm }) {
  if (!isOpen) return null;

  return (
    <div style={modalStyles}>
      <div style={modalContentStyles}>
        <h2>Unlock Content</h2>
        <p>Do you want to unlock this post for 1 credit?</p>
        <div style={buttonContainerStyles}>
          <button style={buttonStyles} onClick={onConfirm}>Yes</button>
          <button style={buttonStyles} onClick={onClose}>No</button>
        </div>
      </div>
    </div>
  );
}

const modalStyles = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'white',
  padding: '20px',
  borderRadius: '10px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  zIndex: 999,
};

const modalContentStyles = {
  textAlign: 'center',
};

const buttonContainerStyles = {
  marginTop: '20px',
  display: 'flex',
  justifyContent: 'space-around',
};

const buttonStyles = {
  padding: '10px 20px',
  backgroundColor: colors.primary,
  color: 'white',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
};

export default UnlockingModal;
