// ScoreRadarChart.jsx
import React from 'react';
import { Radar } from 'react-chartjs-2';
import 'chart.js/auto';

function ScoreRadarChart({ dynamicScores, userScore, userScorePercentile }) {
  const labels = Object.keys(dynamicScores);
  const dataValues = Object.values(dynamicScores);

  const chartData = {
    labels,
    datasets: [
      {
        label: 'Score',
        data: dataValues,
        backgroundColor: 'rgba(128, 0, 128, 0.2)',
        borderColor: 'purple',
        borderWidth: 2,
        pointBackgroundColor: 'purple',
      },
    ],
  };

  const options = {
    scales: {
      r: {
        // Force the domain for the radial axis from 0 to 100
        min: 0,
        max: 100,
        beginAtZero: true, // Ensures the scale starts at 0
        angleLines: { display: true },
        grid: { color: 'rgba(200, 200, 200, 0.5)' },
        ticks: {
          // Hides numeric labels around the chart (optional)
          display: false,
          // If you ever show ticks, keep one decimal place
          callback: (value) => parseFloat(value).toFixed(1),
        },
      },
    },
    plugins: {
      legend: { display: false },
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>
        Front Profile Score:{' '}
        <span style={styles.score}>{userScore.toFixed(0)}/100</span>
      </h1>
      <p style={styles.subtext}>
        Better Than {userScorePercentile.toFixed(1)}% Of People
      </p>
      <div style={styles.chartContainer}>
        <Radar data={chartData} options={options} />
      </div>
    </div>
  );
}

const styles = {
  container: {
    width: '400px',
    margin: '20px auto',
    padding: '20px',
    borderRadius: '10px',
    backgroundColor: '#fdf8ff',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
    textAlign: 'left',
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#000',
    marginBottom: '10px',
  },
  score: {
    color: 'purple',
  },
  subtext: {
    fontSize: '16px',
    color: '#666',
    marginBottom: '20px',
  },
  chartContainer: {
    width: '100%',
    height: 'auto',
  },
};

export default ScoreRadarChart;
