// SideResultsComponent.jsx
import React, { useEffect, useRef, useState, useMemo } from 'react';
import ProcessedImage from '../../../../General/ProcessedImage';
import SideFeaturesToggle from '../../SideFeaturesToggle';
import ProgressBar from '../../../../General/ProgressBar';
import ScoreRadarChart from '../../../../General/ScoreRadarChart';
import colors from '../../../../styles/colors';
import { calculateScorePercentile } from '../../utils';
import { useNavigate } from 'react-router-dom';
import '../../../../styles/GlowUpContainerComponent.css';

function SideResultsComponent({ data, routines, imagePath, scanId, onSwitchProfile }) {
  const canvasRef = useRef(null);
  const [visibleFeatures, setVisibleFeatures] = useState({
    philtrumFlatness: false,
    leftGonialAngle: false,
    nasofrontalAngle: false,
    nasalAngle: false,
    nasolabialAngle: false,
    nasalProjection: false,
    totalFacialConvexity: false,
    facialConvexity: false,
    mandibularPlaneAngle: false,
  });
  const [sortOrder, setSortOrder] = useState('default');
  const [imageHeight, setImageHeight] = useState(0);
  const navigate = useNavigate();

  // Responsive handling for small screens
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const isMobile = windowWidth < 768;

  const toggleFeature = (feature) => {
    setVisibleFeatures(prev => ({
      ...prev,
      [feature]: !prev[feature],
    }));
  };

  useEffect(() => {
    if (data && canvasRef.current) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      const image = new Image();
      image.src = imagePath;
      image.onload = () => {
        // Use 420px width on desktop and 90% of window width on mobile.
        const availableWidth = isMobile ? window.innerWidth * 0.9 : 420;
        const scale = availableWidth / image.width;
        const calculatedHeight = image.height * scale;
        setImageHeight(calculatedHeight);

        canvas.width = availableWidth;
        canvas.height = calculatedHeight;

        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
        ctx.save();
        ctx.scale(scale, scale);

        const drawLine = (point1, point2) => {
          ctx.beginPath();
          ctx.moveTo(point1[0], point1[1]);
          ctx.lineTo(point2[0], point2[1]);
          ctx.stroke();
        };

        // Consistent styling
        ctx.strokeStyle = 'green';
        ctx.lineWidth = 3 / scale;

        if (visibleFeatures.philtrumFlatness) {
          drawLine(data.measurements_coords["Chin"], data.measurements_coords["Nose Interior"]);
          drawLine(data.measurements_coords["Nose Interior"], data.measurements_coords["Upper Lip"]);
        }
        if (visibleFeatures.leftGonialAngle) {
          drawLine(data.measurements_coords["Left Jaw Angle"], data.measurements_coords["Left Ramus"]);
          drawLine(data.measurements_coords["Left Jaw Angle"], data.measurements_coords["Chin"]);
        }
        if (visibleFeatures.nasofrontalAngle) {
          drawLine(data.measurements_coords["Forehead"], data.measurements_coords["Nose Bridge"]);
          drawLine(data.measurements_coords["Nose Bridge"], data.measurements_coords["Nose Tip"]);
        }
        if (visibleFeatures.nasalAngle) {
          drawLine(data.measurements_coords["Nose Base Left"], data.measurements_coords["Nose Tip"]);
          drawLine(data.measurements_coords["Nose Tip"], data.measurements_coords["Nose Base Right"]);
        }
        if (visibleFeatures.nasolabialAngle) {
          drawLine(data.measurements_coords["Subnasale"], data.measurements_coords["Nose Interior"]);
          drawLine(data.measurements_coords["Nose Interior"], data.measurements_coords["Upper Lip"]);
        }
        if (visibleFeatures.nasalProjection) {
          drawLine(data.measurements_coords["Nose Tip"], data.measurements_coords["Nose Bridge"]);
          drawLine(data.measurements_coords["Nose Tip"], data.measurements_coords["Nasal Projection Point"]);
        }
        if (visibleFeatures.totalFacialConvexity) {
          drawLine(data.measurements_coords["Glabella"], data.measurements_coords["Subnasale"]);
          drawLine(data.measurements_coords["Subnasale"], data.measurements_coords["Pogonion"]);
        }
        if (visibleFeatures.facialConvexity) {
          drawLine(data.measurements_coords["Glabella"], data.measurements_coords["Nose Interior"]);
          drawLine(data.measurements_coords["Nose Interior"], data.measurements_coords["Pogonion"]);
        }
        if (visibleFeatures.mandibularPlaneAngle) {
          drawLine(data.measurements_coords["Nasion"], data.measurements_coords["Gonion Left"]);
          drawLine(data.measurements_coords["Menton"], data.measurements_coords["Gonion Right"]);
        }
      };
    }
  }, [data, imagePath, visibleFeatures, isMobile]);

  const sortedFeatures = useMemo(() => {
    const features = Object.keys(data.percentiles);
    if (sortOrder === 'best-to-worst') {
      return features.sort((a, b) => data.percentiles[b] - data.percentiles[a]);
    } else if (sortOrder === 'worst-to-best') {
      return features.sort((a, b) => data.percentiles[a] - data.percentiles[b]);
    }
    return features;
  }, [data.percentiles, sortOrder]);

  const dynamicScores = useMemo(() => {
    const calculateSectionScore = (keys) => {
      const validValues = keys.map(key => data.percentiles[key]).filter(v => v !== undefined);
      if (validValues.length === 0) return 0;
      const sumOfSquares = validValues.reduce((sum, v) => sum + v * v, 0);
      const meanOfSquares = sumOfSquares / validValues.length;
      const rms = Math.sqrt(meanOfSquares);
      return parseFloat(rms.toFixed(0));
    };

    return {
      Jaw: calculateSectionScore(['Left Gonial Angle', 'Mandibular Plane Angle']),
      Nose: calculateSectionScore(['Nasofrontal Angle', 'Nasal Angle', 'Nasolabial Angle', 'Nasal Projection']),
      Face: calculateSectionScore(['Total Facial Convexity', 'Facial Convexity', 'Philtrum Flatness']),
    };
  }, [data.percentiles]);

  const userScore = useMemo(() => Math.ceil(data.score * 10), [data.score]);
  const userScorePercentile = useMemo(() => calculateScorePercentile(userScore / 10), [userScore]);

  const handleSortChange = (e) => {
    setSortOrder(e.target.value);
  };

  const activeButtonStyle = {
    border: '1px solid purple',
    color: 'purple',
    backgroundColor: 'transparent',
    padding: '10px 20px',
    cursor: 'pointer',
  };

  const inactiveButtonStyle = {
    border: '1px solid gray',
    color: 'gray',
    backgroundColor: 'transparent',
    padding: '10px 20px',
    cursor: 'pointer',
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        alignItems: 'flex-start',
        margin: '30px',
      }}
    >
      {/* Left Column: All Features */}
      <div
        style={{
          // Updated responsive style for mobile: full width on small screens.
          flex: isMobile ? '0 0 auto' : '0 0 30%',
          width: isMobile ? '100%' : undefined,
          marginRight: isMobile ? 0 : '20px',
          display: 'flex',
          flexDirection: 'column',
          height: isMobile ? 'auto' : '1200px',
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
          <label htmlFor="sortOrder" style={{ fontWeight: 'bold' }}>
            Sort Features:
          </label>
          <select
            id="sortOrder"
            value={sortOrder}
            onChange={handleSortChange}
            style={{ padding: '5px', borderRadius: '5px' }}
          >
            <option value="default">Default</option>
            <option value="best-to-worst">Best to Worst</option>
            <option value="worst-to-best">Worst to Best</option>
          </select>
        </div>
        <div
          style={{
            border: '2px solid gray',
            borderRadius: '10px',
            padding: '10px',
            flex: 1,
            overflowY: 'auto',
          }}
        >
          <h3>All Features</h3>
          {sortedFeatures.map((key) => (
            <div key={key} style={{ marginBottom: '10px', minHeight: '60px' }}>
              <strong>{key}:</strong> {data.data[key].toFixed(2)}
              <ProgressBar percent={data.percentiles[key]} style={{ width: '100%' }} />
            </div>
          ))}
        </div>
      </div>

      {/* Right Column: Image, Score Radar Chart & Toggle Panel */}
      <div
        className="responsive-right-column"
        style={{
          flex: '0 0 70%',
          display: 'flex',
          flexDirection: 'column',
          height: isMobile ? 'auto' : '500px',
        }}
      >
        {/* Top Row: Processed Image and Score Radar Chart */}
        <div style={{ display: 'flex', flex: '1 1 auto', flexDirection: isMobile ? 'column' : 'row' }}>
          <div style={{ flex: '1 1 50%', textAlign: 'left' }}>
            <h3 style={{ fontSize: '24px', marginBottom: '5px' }}>Select Scan Type</h3>
            <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginBottom: '10px' }}>
              <button onClick={() => onSwitchProfile('front')} style={inactiveButtonStyle}>
                Front Profile
              </button>
              <button onClick={() => onSwitchProfile('side')} style={activeButtonStyle}>
                Side Profile
              </button>
            </div>
            {imagePath && (
              <div
                className="responsive-image-container"
                style={{
                  marginTop: '10px',
                  width: isMobile ? window.innerWidth * 0.9 : 420,
                  height: 'auto',
                  display: 'block',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              >
                <ProcessedImage
                  canvasRef={canvasRef}
                  style={{
                    width: '100%',
                    border: `4px solid ${colors.primary}`,
                    borderRadius: '25px',
                  }}
                />
              </div>
            )}
          </div>
          <div
            style={{
              flex: '1 1 50%',
              textAlign: 'left',
              marginLeft: isMobile ? 0 : '20px',
              marginTop: isMobile ? '20px' : 0,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            {data && (
              <ScoreRadarChart
                dynamicScores={dynamicScores}
                userScore={userScore}
                userScorePercentile={userScorePercentile}
              />
            )}
          </div>
        </div>

        {/* Bottom Row: Toggle Panel for Side Features */}
        <div style={{ marginTop: '20px', flex: '0 0 auto' }}>
          <div
            style={{
              border: '2px solid gray',
              borderRadius: '10px',
              padding: '10px',
              height: isMobile ? 'auto' : '500px',
              overflowY: 'auto',
            }}
          >
            <SideFeaturesToggle visibleFeatures={visibleFeatures} toggleFeature={toggleFeature} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SideResultsComponent;
