import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import colors from '../../styles/colors';
import Popup from '../../General/Popup';

function AuthorizedContactUs({ user }) {
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    const form = {
      user_name: user.name,
      user_email: user.email,
      message: message,
    };

    try {
      await emailjs.send(
        'service_kielm2g',
        'template_rtry15o',
        form,
        'bz88vqD3wMQzO8MAN'
      );
      setPopupMessage('Message sent successfully');
      setMessage('');
    } catch (error) {
      setPopupMessage('Error sending message');
    }

    setIsSubmitting(false);
  };

  // Matches the .profileContainer background
  const pageBackgroundStyle = {
    padding: '20px',
    paddingLeft: '100px',
    paddingRight: '100px',
    minHeight: '100vh',
    color: '#2c3e50',
    background:
      'radial-gradient(ellipse at 45% 0%, #f3e6ff 0%, #ffffff 50%), ' +
      'radial-gradient(ellipse at 135% 0%, #f3e6ff 0%, #ffffff 50%)',
    backgroundPosition: 'left top, right top',
    backgroundSize: '50% 100%, 50% 100%',
    backgroundRepeat: 'no-repeat',
    backgroundBlendMode: 'lighten',
  };

  // Larger card section
  const cardStyle = {
    backgroundColor: '#fff',
    padding: '50px 40px',
    borderRadius: '10px',
    width: '650px',       // Increased width
    maxWidth: '90%',      // For responsiveness
    margin: '40px auto 0 auto',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    minHeight: '600px',   // Ensures taller layout
  };

  const formStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  };

  const textareaStyle = {
    height: '300px',        // Larger text area
    marginBottom: '20px',
    padding: '15px',
    fontSize: '18px',
    width: '100%',
    borderRadius: '6px',
    border: '1px solid #ddd',
    resize: 'vertical',
  };

  const buttonStyle = {
    backgroundColor: colors.primary,
    color: '#fff',
    border: 'none',
    padding: '10px 20px',
    cursor: 'pointer',
    borderRadius: '5px',
    transition: 'background 0.3s',
  };

  return (
    <div style={pageBackgroundStyle}>
      <div style={cardStyle}>
        {popupMessage && (
          <Popup message={popupMessage} onClose={() => setPopupMessage('')} />
        )}
        <h1 style={{ marginBottom: '20px' }}>Contact Us</h1>
        <p style={{ color: '#444', fontSize: '1.1em', marginBottom: '30px' }}>
          Let us know any issues or suggestions for new features
        </p>
        <form onSubmit={handleSubmit} style={formStyle}>
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Your message"
            required
            style={textareaStyle}
            maxLength="2000"
          />
          <button type="submit" disabled={isSubmitting} style={buttonStyle}>
            {isSubmitting ? 'Sending...' : 'Send Message'}
          </button>
        </form>
      </div>
    </div>
  );
}

export default AuthorizedContactUs;
