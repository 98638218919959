import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getFunctions } from 'firebase/functions';
import { getAnalytics } from 'firebase/analytics';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBLCn6NP8hPfHCurwbGmR_2I7iddJxbU8I",
  authDomain: "golden-ratio-23459.firebaseapp.com",
  databaseURL: "https://golden-ratio-23459-default-rtdb.firebaseio.com",
  projectId: "golden-ratio-23459",
  storageBucket: "golden-ratio-23459.appspot.com",
  messagingSenderId: "650116043505",
  appId: "1:650116043505:web:25575815a20b3139df0f50",
  measurementId: "G-4L0LXJTF38"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app);
const analytics = getAnalytics(app);

export { app, auth, firestore, storage, functions, analytics };
