import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { FaCopy, FaEllipsisV, FaSpinner } from 'react-icons/fa';
import { doc, updateDoc, getDoc, increment } from 'firebase/firestore';
import { auth, firestore } from '../../../firebaseConfig';
import colors from '../../styles/colors';
import { useUser } from './UserContext';
import { fetchStripeProducts, createStripeCheckoutSession, openCustomerPortal } from './stripeService';

const ONE_WEEK_MS = 7 * 24 * 60 * 60 * 1000; // One week in milliseconds
const ONE_DAY_MS = 24 * 60 * 60 * 1000;       // One day in milliseconds

const PurchaseModal = ({ isOpen, onClose }) => {
  const [referralCode, setReferralCode] = useState('');
  const [referralCount, setReferralCount] = useState(0);
  const [showCopiedPopup, setShowCopiedPopup] = useState(false);
  const { setCredits } = useUser();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedProPlan, setSelectedProPlan] = useState('monthly');
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

  // State variables for free credit feature
  const [lastFreeCreditClaimed, setLastFreeCreditClaimed] = useState(null);
  const [canClaim, setCanClaim] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState('');
  const [claimableCredits, setClaimableCredits] = useState(0);

  // State for pro subscription status
  const [isPro, setIsPro] = useState(false);
  const [proExpiration, setProExpiration] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);

  const productMapping = {
    "GR Credits (1)": "1 Credit",
    "GR Credits (10)": "10 Credits",
    "GR Credits (50)": "50 Credits",
    "GR Pro Plan (Monthly)": "GR Pro Plan (Monthly)",
    "GR Pro Plan (Yearly)": "GR Pro Plan (Yearly)",
    "Daily Subscription": "Daily Subscription",
  };

  const valueMultiplier = {
    "GR Credits (10)": "2x Value",
    "GR Credits (50)": "4x Value",
  };

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 600);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (isOpen) {
      const fetchUserDetails = async () => {
        const user = auth.currentUser;
        if (user) {
          const userDocRef = doc(firestore, 'users', user.uid);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            const userData = userDoc.data();
            console.log(userData);
            setReferralCode(userData.referral_code);
            setReferralCount(userData.num_referrals);
            setLastFreeCreditClaimed(
              userData.lastFreeCreditClaimed ? userData.lastFreeCreditClaimed.toMillis() : null
            );
            // Check pro plan status: ensure plan is active (pro_plan true and expiration in the future)
            if (userData.pro_plan && userData.pro_expiration && userData.pro_expiration.toMillis() > Date.now()) {
              setIsPro(true);
              setProExpiration(userData.pro_expiration.toMillis());
            } else {
              setIsPro(false);
              setProExpiration(null);
            }
          }
        }
      };

      const fetchProducts = async () => {
        const allProducts = await fetchStripeProducts();
        const filteredProducts = allProducts.filter(product => !product.name.includes('Testing'));
        setProducts(filteredProducts);
      };

      fetchUserDetails();
      fetchProducts();
    }
  }, [isOpen]);

  // Update free credit claim status based on whether the user is pro or not
  useEffect(() => {
    if (!isOpen) return;

    const updateClaimStatus = () => {
      const now = Date.now();
      // If no claim has ever been made, allow a claim of 1 credit
      if (!lastFreeCreditClaimed) {
        setCanClaim(true);
        setClaimableCredits(1);
        setTimeRemaining('');
        return;
      }

      if (isPro) {
        // Calculate how many full days have passed since the last claim (max 10)
        const elapsed = now - lastFreeCreditClaimed;
        const daysPassed = Math.floor(elapsed / ONE_DAY_MS);
        const credits = Math.min(daysPassed, 10);
        if (credits >= 1) {
          setCanClaim(true);
          setClaimableCredits(credits);
          // Calculate time remaining until the next free credit is available
          const nextEligibleTime = lastFreeCreditClaimed + ONE_DAY_MS;
          const remaining = nextEligibleTime - now;
          setTimeRemaining(formatTimeRemaining(remaining));
        } else {
          setCanClaim(false);
          setClaimableCredits(0);
          const nextEligibleTime = lastFreeCreditClaimed + ONE_DAY_MS;
          const remaining = nextEligibleTime - now;
          setTimeRemaining(formatTimeRemaining(remaining));
        }
      } else {
        // Non-pro users: weekly free credit
        if (now - lastFreeCreditClaimed >= ONE_WEEK_MS) {
          setCanClaim(true);
          setClaimableCredits(1);
          setTimeRemaining('');
        } else {
          setCanClaim(false);
          setClaimableCredits(0);
          const nextEligibleTime = lastFreeCreditClaimed + ONE_WEEK_MS;
          const remaining = nextEligibleTime - now;
          setTimeRemaining(formatTimeRemaining(remaining));
        }
      }
    };

    updateClaimStatus();
    const timer = setInterval(updateClaimStatus, 1000);
    return () => clearInterval(timer);
  }, [lastFreeCreditClaimed, isOpen, isPro]);

  const formatTimeRemaining = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const days = Math.floor(totalSeconds / (24 * 3600));
    const hours = Math.floor((totalSeconds % (24 * 3600)) / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    let parts = [];
    if (days > 0) parts.push(`${days}d`);
    if (hours > 0 || days > 0) parts.push(`${hours}h`);
    if (minutes > 0 || hours > 0 || days > 0) parts.push(`${minutes}m`);
    parts.push(`${seconds}s`);

    return parts.join(' ');
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(referralCode);
    setShowCopiedPopup(true);
    setTimeout(() => setShowCopiedPopup(false), 500);
  };

  const handlePurchase = async (product) => {
    setLoading(true);
    const oneTimePurchase = !(
      product.name === "GR Pro Plan (Monthly)" ||
      product.name === "GR Pro Plan (Yearly)" ||
      product.name === "Daily Subscription"
    );
    try {
      await createStripeCheckoutSession(product.priceId, oneTimePurchase);
    } catch (error) {
      console.error('Error during purchase: ', error);
    } finally {
      setTimeout(() => setLoading(false), 5000);
    }
  };

  const handleProPlanPurchase = () => {
    const proPlanProducts = products.filter(product =>
      product.name === "GR Pro Plan (Monthly)" || product.name === "GR Pro Plan (Yearly)"
    );
    const selectedProduct = proPlanProducts.find(product =>
      selectedProPlan === 'monthly'
        ? product.name.includes('Monthly')
        : product.name.includes('Yearly')
    );
    if (selectedProduct) {
      handlePurchase(selectedProduct);
    }
  };

  const handleClaimFreeCredit = async () => {
    const user = auth.currentUser;
    if (!user) return;
    const userDocRef = doc(firestore, 'users', user.uid);
    // For pro users, claim all accumulated credits; non-pro users always claim 1
    const creditsToClaim = isPro ? claimableCredits : 1;
    if (creditsToClaim < 1) return;
    try {
      await updateDoc(userDocRef, {
        credits: increment(creditsToClaim),
        lastFreeCreditClaimed: new Date(),
      });
      setCredits(prev => prev + creditsToClaim);
      setLastFreeCreditClaimed(Date.now());
      setCanClaim(false);
      // Reset timer based on plan type
      setTimeRemaining(isPro ? formatTimeRemaining(ONE_DAY_MS) : formatTimeRemaining(ONE_WEEK_MS));
    } catch (error) {
      console.error('Error claiming free credit: ', error);
    }
  };

  const handleCancel = async () => {
    setLoading(true);
    try {
      const portalUrl = await openCustomerPortal();
      window.location.assign(portalUrl);
    } catch (error) {
      console.error("Error opening customer portal:", error);
      alert("Failed to open customer portal. Please try again.");
    } finally {
      setLoading(false);
      setShowDropdown(false);
    }
  };

  const creditProducts = products.filter(product =>
    product.name.toLowerCase().includes('credit')
  );

  if (!isOpen) return null;

  return createPortal(
    <>
      <style>
        {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
      </style>
      <div style={overlayStyles}>
        <div style={modalStyles}>
          {loading && (
            <div style={spinnerContainerStyles}>
              <FaSpinner style={spinnerStyles} />
            </div>
          )}
          <button onClick={onClose} style={closeButtonStyles}>×</button>
          <h2 style={titleStyles}>Purchase More Credits</h2>
          <div style={{ ...purchaseItemsContainer, alignItems: 'stretch' }}>
            <div style={{ flex: 1 }}>
              <h3 style={sectionTitleStyles}>Credits</h3>
              <p style={groupDescriptionStyles}>
                Purchase individual credits to use for scans.
              </p>
              {!loading && creditProducts.map(product => (
                <div key={product.priceId} style={tierStyles} onClick={() => handlePurchase(product)}>
                  <strong>{productMapping[product.name] || product.name}</strong>
                  <span>${(product.priceInfo.unit_amount / 100).toFixed(2)}</span>
                  {valueMultiplier[product.name] && (
                    <div style={valueBubbleStyles}>{valueMultiplier[product.name]}</div>
                  )}
                </div>
              ))}
            </div>
            <div style={{ flex: 1, marginTop: isMobile ? '20px' : 0, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <h3 style={sectionTitleStyles}>Pro Plan Subscription</h3>
              {isPro ? (
                <div style={proPlanDetailsStyles}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>
                      <strong style={planTitleStyles}>Your Pro Plan is active</strong>
                      {proExpiration && (
                        <p style={planPriceStyles}>Expires on: {new Date(proExpiration).toLocaleDateString()}</p>
                      )}
                    </div>
                    <div style={{ position: 'relative' }}>
                      <button onClick={() => setShowDropdown(prev => !prev)} style={ellipsisButtonStyles}>
                        <FaEllipsisV />
                      </button>
                      {showDropdown && (
                        <div style={dropdownStyles}>
                          <button onClick={handleCancel} style={dropdownItemStyles} disabled={loading}>
                            Cancel Pro Subscription
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <p style={groupDescriptionStyles}>
                    Subscribe to our Pro Plan for extra benefits.
                  </p>
                  <div style={planSwitchContainerStyles}>
                    <div
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: selectedProPlan === 'monthly' ? '0%' : '50%',
                        width: '50%',
                        height: '100%',
                        backgroundColor: colors.primary,
                        borderRadius: '8px',
                        transition: 'left 0.3s ease',
                      }}
                    />
                    <button
                      onClick={() => setSelectedProPlan('monthly')}
                      style={{
                        ...toggleButtonStyles,
                        color: selectedProPlan === 'monthly' ? colors.white : colors.primary,
                      }}
                    >
                      Monthly
                    </button>
                    <button
                      onClick={() => setSelectedProPlan('yearly')}
                      style={{
                        ...toggleButtonStyles,
                        color: selectedProPlan === 'yearly' ? colors.white : colors.primary,
                      }}
                    >
                      Yearly
                    </button>
                  </div>
                  <div style={proPlanDetailsStyles} onClick={handleProPlanPurchase}>
                    {selectedProPlan === 'monthly' ? (
                      <>
                        <strong style={{ ...planTitleStyles, cursor: 'pointer' }}>GR Pro Plan (Monthly)</strong>
                        <p style={planPriceStyles}>$12.99/month</p>
                        <ul style={benefitsListStyles}>
                          <li>Instantly receive 5 credits</li>
                          <li>Daily free scans</li>
                          <li>Retrieve up to 10 free scans at a time</li>
                        </ul>
                      </>
                    ) : (
                      <>
                        <strong style={{ ...planTitleStyles, cursor: 'pointer' }}>GR Pro Plan (Yearly)</strong>
                        <p style={planPriceStyles}>$7.99/month</p>
                        <ul style={benefitsListStyles}>
                          <li>Instantly receive 30 credits</li>
                          <li>Daily free scans</li>
                          <li>Retrieve up to 10 free scans at a time</li>
                        </ul>
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>

          {/* Free Credit Section */}
          <div style={freeCreditSectionStyles}>
            <h3>{isPro ? 'Daily Free Credit' : 'Weekly Free Credit'}</h3>
            {canClaim ? (
              <button onClick={handleClaimFreeCredit} style={claimButtonStyles}>
                {isPro
                  ? `Claim ${claimableCredits} Free Credit${claimableCredits > 1 ? 's' : ''}`
                  : 'Claim Free Credit'}
              </button>
            ) : (
              <p style={timeRemainingStyles}>
                Next free credit available in: {timeRemaining}
              </p>
            )}
          </div>

          <p style={referralStyles}>
            or refer a friend with your referral code to get 1 free credit
          </p>
          <div style={referralSectionStyles}>
            <span style={referralCodeStyles}>{referralCode}</span>
            <button onClick={handleCopy} style={copyButtonStyles}>
              <FaCopy />
            </button>
          </div>
          <p style={referralCountStyles}>{referralCount} referred so far</p>
          {showCopiedPopup && <div style={copiedPopupStyles}>Copied!</div>}
        </div>
      </div>
    </>,
    document.body
  );
};

// --- Styles ---

const overlayStyles = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000,
};

const modalStyles = {
  backgroundColor: colors.white,
  padding: '40px',
  borderRadius: '12px',
  width: '500px',
  maxWidth: '90%',
  boxShadow: '0 8px 30px rgba(0, 0, 0, 0.12)',
  position: 'relative',
  maxHeight: '90vh',
  overflowY: 'auto',
  transition: 'transform 0.3s ease, opacity 0.3s ease',
};

const closeButtonStyles = {
  position: 'absolute',
  top: '10px',
  right: '10px',
  backgroundColor: 'transparent',
  border: 'none',
  fontSize: '24px',
  color: colors.primary,
  cursor: 'pointer',
};

const titleStyles = {
  margin: '0 0 20px',
  textAlign: 'center',
};

const purchaseItemsContainer = {
  display: 'flex',
  gap: '40px',
  marginBottom: '20px',
};

const sectionTitleStyles = {
  textAlign: 'center',
  marginBottom: '5px',
};

const groupDescriptionStyles = {
  fontSize: '12px',
  color: colors.secondary,
  textAlign: 'center',
  marginBottom: '10px',
};

const tierStyles = {
  position: 'relative',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px 15px',
  border: `1px solid ${colors.primary}`,
  borderRadius: '4px',
  cursor: 'pointer',
  marginBottom: '10px',
};

const valueBubbleStyles = {
  position: 'absolute',
  top: '0px',
  right: '0px',
  transform: 'translate(50%, -50%)',
  backgroundColor: colors.secondary,
  color: colors.white,
  padding: '5px 10px',
  borderRadius: '50%',
  fontSize: '12px',
  fontWeight: 'bold',
};

const planSwitchContainerStyles = {
  position: 'relative',
  display: 'flex',
  width: '200px',
  height: '40px',
  margin: '0 auto 10px auto',
  border: `1px solid ${colors.primary}`,
  borderRadius: '8px',
  overflow: 'hidden',
};

const toggleButtonStyles = {
  flex: 1,
  border: 'none',
  background: 'transparent',
  zIndex: 1,
  cursor: 'pointer',
  fontSize: '16px',
};

const proPlanDetailsStyles = {
  textAlign: 'center',
  marginBottom: '10px',
  padding: '10px',
  border: `1px solid ${colors.primary}`,
  borderRadius: '4px',
};

const planTitleStyles = {
  display: 'block',
  marginBottom: '5px',
  fontSize: '16px',
};

const planPriceStyles = {
  fontSize: '14px',
  fontWeight: 'bold',
  marginBottom: '5px',
};

const benefitsListStyles = {
  listStyleType: 'disc',
  paddingLeft: '20px',
  textAlign: 'left',
  display: 'inline-block',
  fontSize: '12px',
};

const freeCreditSectionStyles = {
  marginTop: '20px',
  textAlign: 'center',
};

const claimButtonStyles = {
  backgroundColor: colors.primary,
  color: colors.white,
  border: 'none',
  borderRadius: '4px',
  padding: '10px 20px',
  cursor: 'pointer',
  fontSize: '16px',
};

const timeRemainingStyles = {
  color: colors.secondary,
  fontSize: '14px',
};

const referralStyles = {
  textAlign: 'center',
  marginTop: '20px',
};

const referralSectionStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  marginTop: '10px',
};

const referralCodeStyles = {
  padding: '5px 10px',
  border: `1px solid ${colors.primary}`,
  borderRadius: '4px',
  backgroundColor: colors.lightGrey,
  fontFamily: 'monospace',
  fontSize: '20px',
};

const copyButtonStyles = {
  backgroundColor: colors.primary,
  color: colors.white,
  border: 'none',
  borderRadius: '4px',
  padding: '5px 10px',
  cursor: 'pointer',
};

const referralCountStyles = {
  textAlign: 'center',
  marginTop: '10px',
};

const copiedPopupStyles = {
  position: 'absolute',
  bottom: '20px',
  left: '50%',
  transform: 'translateX(-50%)',
  backgroundColor: colors.primary,
  color: colors.white,
  padding: '10px 20px',
  borderRadius: '4px',
};

const spinnerContainerStyles = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(255,255,255,0.8)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 2000,
};

const spinnerStyles = {
  fontSize: '48px',
  color: colors.primary,
  animation: 'spin 1s linear infinite',
};

const ellipsisButtonStyles = {
  background: 'transparent',
  border: 'none',
  fontSize: '16px',
  cursor: 'pointer',
  padding: '5px',
};

const dropdownStyles = {
  position: 'absolute',
  top: '100%',
  right: 0,
  backgroundColor: colors.white,
  boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
  borderRadius: '4px',
  overflow: 'hidden',
  zIndex: 100,
};

const dropdownItemStyles = {
  padding: '10px 20px',
  background: colors.white,
  border: 'none',
  width: '100%',
  textAlign: 'left',
  cursor: 'pointer',
};

export default PurchaseModal;
