import React, { useState, useMemo } from 'react';
import FrontRatiosToggle from '../../Authorized/components/FrontRatiosToggle';
import FrontTraitsToggle from '../../Authorized/components/FrontTraitsToggle';
import ProgressBar from '../../General/ProgressBar';
import ScoreRadarChart from '../../General/ScoreRadarChart';
import colors from '../../styles/colors';
import SignUpModal from './SignUpModal';
import LoginModal from './LoginModal';
import { useNavigate } from 'react-router-dom';
import '../../styles/GlowUpContainerComponent.css';
import '../../styles/ResultsPage.css';

function UnauthorizedFrontResultsComponent({ data, imagePath }) {
  const [visibleFeatures, setVisibleFeatures] = useState({
    pupilDistance: false,
    leftEyeLength: false,
    rightEyeLength: false,
    faceLength: false,
    faceWidth: false,
    noseWidth: false,
    noseHeight: false,
    mouthWidth: false,
    eyeSpacing: false,
    eyeOuterDistance: false,
    leftEyeHeight: false,
    rightEyeHeight: false,
    jawWidth: false,
    chinHeight: false,
    philtrumLength: false,
    midfaceLength: false,
    upperLipHeight: false,
    lowerLipHeight: false,
    eyeSeparationRatio: false,
    eyeAspectRatioLeft: false,
    eyeAspectRatioRight: false,
    jawWidthRatio: false,
    chinPhiltrumRatio: false,
    midfaceRatio: false,
    midfaceCompactness: false,
    mouthNoseHarmony: false,
    eyeSpacingRatio: false,
    noseHeightToWidthRatio: false,
    lipRatio: false,
    facialThirds: false,
  });
  const [toggleView, setToggleView] = useState('traits');
  const [sortOrder, setSortOrder] = useState('default');
  const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  const navigate = useNavigate();

  const toggleFeature = (feature) => {
    setVisibleFeatures((prev) => ({
      ...prev,
      [feature]: !prev[feature],
    }));
  };

  const defaultFeatureData = {
    "Jaw Width Ratio": 1.2,
    "Jaw Frontal Angle": 95,
    "Chin Philtrum Ratio": 0.8,
    "Lip Ratio": 1.1,
    "Mouth Nose Harmony": 0.95,
    "Nose Height To Width Ratio": 1.3,
    "Eye Aspect Ratio Left": 0.6,
    "Eye Aspect Ratio Right": 0.65,
    "Midface Ratio": 1.05,
    "Midface Compactness": 0.9,
  };

  const defaultPercentiles = {
    "Jaw Width Ratio": 70,
    "Jaw Frontal Angle": 80,
    "Chin Philtrum Ratio": 60,
    "Lip Ratio": 50,
    "Mouth Nose Harmony": 55,
    "Nose Height To Width Ratio": 65,
    "Eye Aspect Ratio Left": 75,
    "Eye Aspect Ratio Right": 77,
    "Midface Ratio": 68,
    "Midface Compactness": 72,
  };

  const safeData = {
    data: data && data.data ? data.data : defaultFeatureData,
    percentiles: data && data.percentiles ? data.percentiles : defaultPercentiles,
  };

  const sortedFeatures = useMemo(() => {
    const features = Object.keys(safeData.percentiles);
    if (sortOrder === 'best-to-worst') {
      return [...features].sort((a, b) => safeData.percentiles[b] - safeData.percentiles[a]);
    } else if (sortOrder === 'worst-to-best') {
      return [...features].sort((a, b) => safeData.percentiles[a] - safeData.percentiles[b]);
    }
    return features;
  }, [safeData.percentiles, sortOrder]);

  const presetDynamicScores = {
    Jaw: 40,
    Mouth: 30,
    Nose: 45,
    Eyes: 35,
    Midface: 45,
  };
  const presetUserScore = 50;
  const presetUserScorePercentile = 50;

  const handleBlurClick = () => {
    setIsSignUpModalOpen(true);
    document.body.classList.add('modal-open');
  };

  const closeSignUpModal = () => {
    setIsSignUpModalOpen(false);
    document.body.classList.remove('modal-open');
  };

  const closeLoginModal = () => {
    setIsLoginModalOpen(false);
    document.body.classList.remove('modal-open');
  };

  const activeButtonStyle = {
    border: '1px solid purple',
    color: 'purple',
    backgroundColor: 'transparent',
    padding: '10px 20px',
    cursor: 'pointer',
  };

  const inactiveButtonStyle = {
    border: '1px solid gray',
    color: 'gray',
    backgroundColor: 'transparent',
    padding: '10px 20px',
    cursor: 'pointer',
  };

  return (
    <div>
      <div className="resultsWrapper">
        {/* Left Column: Sort selector and All Features (blurred) */}
        <div className="resultsLeft">
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '10px',
              filter: 'blur(3px)',
              cursor: 'pointer',
            }}
            onClick={handleBlurClick}
          >
            <label htmlFor="sortOrder" style={{ fontWeight: 'bold' }}>
              Sort Features:
            </label>
            <select
              id="sortOrder"
              value={sortOrder}
              onChange={(e) => setSortOrder(e.target.value)}
              style={{ padding: '5px', borderRadius: '5px' }}
            >
              <option value="default">Default</option>
              <option value="best-to-worst">Best to Worst</option>
              <option value="worst-to-best">Worst to Best</option>
            </select>
          </div>
          <div
            style={{
              border: '2px solid gray',
              borderRadius: '10px',
              padding: '10px',
              flex: 1,
              overflowY: 'auto',
              filter: 'blur(3px)',
              cursor: 'pointer',
            }}
            onClick={handleBlurClick}
          >
            <h3>All Features</h3>
            {sortedFeatures.map((key) => (
              <div key={key} style={{ marginBottom: '10px', minHeight: '60px' }}>
                <strong>{key}:</strong>{' '}
                {safeData.data[key] !== undefined ? safeData.data[key].toFixed(2) : '0.00'}
                <ProgressBar percent={safeData.percentiles[key]} style={{ width: '100%' }} />
              </div>
            ))}
          </div>
        </div>
        {/* Right Column: Processed Image, Score Radar Chart, and Traits/Ratios Toggle */}
        <div className="resultsRight">
          <div className="resultsRightContent">
            <div style={{ flex: '1 1 50%', textAlign: 'left' }}>
              <h3 style={{ fontSize: '24px', marginBottom: '5px' }}>Scan Type</h3>
              <div style={{ textAlign: 'center' }}>
                <button style={{ ...activeButtonStyle, cursor: 'pointer' }} onClick={handleBlurClick}>
                  Front Profile
                </button>
              </div>
              {imagePath && (
                <div
                  className="responsive-image-container"
                  style={{
                    marginTop: '20px',
                    width: '420px',
                    height: 'auto',
                    display: 'block',
                    margin: '0 auto',
                  }}
                >
                  <img
                    src={imagePath}
                    alt="Processed"
                    style={{
                      width: '100%',
                      border: `4px solid ${colors.primary}`,
                      borderRadius: '25px',
                    }}
                  />
                </div>
              )}
            </div>
            <div
              style={{
                flex: '1 1 50%',
                textAlign: 'left',
                marginLeft: '20px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                filter: 'blur(3px)',
                cursor: 'pointer',
              }}
              onClick={handleBlurClick}
            >
              <div
                className="responsive-radar-container"
                style={{ width: '100%', margin: '0 auto' }}
              >
                <ScoreRadarChart
                  dynamicScores={presetDynamicScores}
                  userScore={presetUserScore}
                  userScorePercentile={presetUserScorePercentile}
                  style={{ width: '100%' }}
                />
              </div>
            </div>
          </div>
          <div style={{ marginTop: '20px', flex: '0 0 auto' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                gap: '20px',
                marginBottom: '20px',
              }}
            >
              <button
                onClick={() => setToggleView('traits')}
                style={toggleView === 'traits' ? activeButtonStyle : inactiveButtonStyle}
              >
                Traits
              </button>
              <button
                onClick={() => setToggleView('ratios')}
                style={toggleView === 'ratios' ? activeButtonStyle : inactiveButtonStyle}
              >
                Ratios
              </button>
            </div>
            <div
              style={{
                border: '2px solid gray',
                borderRadius: '10px',
                padding: '10px',
                height: '500px',
                overflowY: 'auto',
                filter: 'blur(3px)',
                cursor: 'pointer',
              }}
              onClick={handleBlurClick}
            >
              {toggleView === 'traits' ? (
                <FrontTraitsToggle visibleFeatures={visibleFeatures} toggleFeature={toggleFeature} />
              ) : (
                <FrontRatiosToggle visibleFeatures={visibleFeatures} toggleFeature={toggleFeature} />
              )}
            </div>
          </div>
        </div>
      </div>
      {(isLoginModalOpen || isSignUpModalOpen) && <div style={backdropStyles}></div>}
      <SignUpModal
        isOpen={isSignUpModalOpen}
        onClose={closeSignUpModal}
        onLoginClick={() => {
          closeSignUpModal();
          setIsLoginModalOpen(true);
          document.body.classList.add('modal-open');
        }}
      />
      <LoginModal
        isOpen={isLoginModalOpen}
        onClose={closeLoginModal}
        onSignUpClick={() => {
          closeLoginModal();
          setIsSignUpModalOpen(true);
          document.body.classList.add('modal-open');
        }}
      />
    </div>
  );
}

const backdropStyles = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: 998,
};

export default UnauthorizedFrontResultsComponent;
