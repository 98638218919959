// src/pages/UnauthorizedRoutinesPage.js
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../../styles/BeautyRoutinePage.css';
import { RoutineList } from '../../General/RoutineList.js';

const UnauthorizedBeautyRoutinesPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [selectedRoutines, setSelectedRoutines] = useState([]);
  const [expandedStepIds, setExpandedStepIds] = useState([]);
  const [expandedProductRecIds, setExpandedProductRecIds] = useState([]);
  const [shortenedStates, setShortenedStates] = useState({});
  
  // This will track whether we actually received valid data.
  // If false, no "Back to Results" button will be shown.
  const [hasData, setHasData] = useState(false);

  useEffect(() => {
    const routineMap = new Map(RoutineList.map(r => [r.id, r]));

    if (location.state && location.state.routines) {
      const { routines } = location.state;

      // Validate routines array
      if (Array.isArray(routines) && routines.length === 6) {
        // Map over the routines array to preserve order
        const selected = routines.map(id => routineMap.get(id)).filter(Boolean);
        
        if (selected.length === routines.length) {
          setSelectedRoutines(selected);
          setHasData(true); // We have valid data
        } else {
          console.error('Some routines were not found in RoutineList:', routines);
          navigate('/results', { replace: true });
        }
      } else {
        console.error('Invalid routines data:', routines);
        navigate('/results', { replace: true });
      }
    } else {
      // No state passed, use default routine IDs
      console.error('No routines data passed. Using default routines...');
      const defaultIds = [1, 2, 3, 4, 5, 6, 7, 8];
      const selected = defaultIds.map(id => routineMap.get(id)).filter(Boolean);
      setSelectedRoutines(selected);
      setHasData(false);
    }
  }, [location.state, navigate]);

  // Function to toggle step expansion
  const toggleStepExpansion = (stepId) => {
    setExpandedStepIds((prevExpandedStepIds) =>
      prevExpandedStepIds.includes(stepId)
        ? prevExpandedStepIds.filter((id) => id !== stepId)
        : [...prevExpandedStepIds, stepId]
    );
  };

  // Function to toggle product recommendations dropdown
  const toggleProductRec = (routineId) => {
    setExpandedProductRecIds((prevExpandedProductRecIds) =>
      prevExpandedProductRecIds.includes(routineId)
        ? prevExpandedProductRecIds.filter((id) => id !== routineId)
        : [...prevExpandedProductRecIds, routineId]
    );
  };

  // Function to toggle shortened routine for a specific routine
  const toggleShortened = (routineId) => {
    setShortenedStates((prevShortenedStates) => ({
      ...prevShortenedStates,
      [routineId]: !prevShortenedStates[routineId],
    }));
  };

  // Helper function to check if there are any products, including 'general'
  const hasProducts = (products) => {
    if (!products) return false;
    return ['beginner', 'advanced', 'luxury', 'general'].some(
      (category) => products[category] && products[category].length > 0
    );
  };

  // Define the product categories in the desired order
  const productCategories = ['beginner', 'advanced', 'luxury', 'general'];

  // Handler for navigating back to results with state
  // Only do this if we actually have data from location.state
  const handleNavigateBack = () => {
    if (location.state && location.state.fullData && location.state.frontImage) {
      navigate('/results', {
        state: {
          data: location.state.fullData,
          frontImage: location.state.frontImage,
        },
      });
    } else {
      console.error('Required state data is missing.');
      navigate('/results', { replace: true });
    }
  };

  return (
    <div className="unauthorized-routine-beauty-routine-page">
      <h1>Your Sample Beauty Routine</h1>

      {/* 
        Centered and spaced paragraph for an appealing message.
        If you’re using a CSS class, make sure to define something like:
        
        .unauthorized-routine-sample-info {
          text-align: center;
          margin-bottom: 20px;
          font-size: 1.1rem;
          line-height: 1.4;
        }
      */}
      <p
        className="unauthorized-routine-sample-info"
        style={{ textAlign: 'center', marginBottom: '20px' }}
      >
        Log in to chat with an AI Assistant to get your Personalized Beauty Routine!
        <br />
      </p>

      <div className="unauthorized-routine-steps-grid">
        {selectedRoutines.map((routine, index) => (
          <div
            key={routine.id}
            className={`unauthorized-routine-step-card ${expandedStepIds.includes(routine.id) ? 'expanded' : ''}`}
            onClick={() => toggleStepExpansion(routine.id)}
            role="button"
            tabIndex={0}
            onKeyPress={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                toggleStepExpansion(routine.id);
              }
            }}
          >
            <div className="unauthorized-routine-step-header">
              <div className="unauthorized-routine-priority-badge">
                {index + 1}
              </div>
              <h2>{routine.name}</h2>
              <span className="unauthorized-routine-expand-icon">
                {expandedStepIds.includes(routine.id) ? '−' : '+'}
              </span>
            </div>
            <p>{routine.description}</p>

            {routine.canBeShortened && expandedStepIds.includes(routine.id) && (
              <div
                className="unauthorized-routine-toggle-container"
                onClick={(e) => e.stopPropagation()}
              >
                <label
                  className="unauthorized-routine-switch"
                  aria-label={`Toggle between full and shortened routine for ${routine.name}`}
                >
                  <input
                    type="checkbox"
                    checked={shortenedStates[routine.id] || false}
                    onChange={() => toggleShortened(routine.id)}
                    aria-checked={shortenedStates[routine.id] || false}
                  />
                  <span className="unauthorized-routine-slider"></span>
                </label>
                <span className="unauthorized-routine-toggle-label">
                  {shortenedStates[routine.id] ? 'Shortened Routine' : 'Full Routine'}
                </span>
              </div>
            )}

            {expandedStepIds.includes(routine.id) && (
              <div className="unauthorized-routine-step-content">
                {routine.routines
                  .filter(
                    (subRoutine) =>
                      !routine.canBeShortened ||
                      !shortenedStates[routine.id] ||
                      subRoutine.includeInShortened
                  )
                  .map((subRoutine, subIndex) => {
                    const routineId = `${routine.id}-${subIndex}`;
                    return (
                      <div key={routineId} className="unauthorized-routine-routine-section">
                        <h3>{subRoutine.name}</h3>
                        <p>{subRoutine.description}</p>
                        <ol className="unauthorized-routine-routine-steps">
                          {subRoutine.steps.map((instruction, idx) => (
                            <li key={idx}>{instruction}</li>
                          ))}
                        </ol>
                        <p className="unauthorized-routine-routine-feedback">
                          <strong>Feedback:</strong> {subRoutine.feedback}
                        </p>

                        {hasProducts(subRoutine.products) && (
                          <div className="unauthorized-routine-product-recommendations">
                            <button
                              className="unauthorized-routine-product-recommendations-toggle"
                              onClick={(e) => {
                                e.stopPropagation();
                                toggleProductRec(routineId);
                              }}
                              aria-expanded={expandedProductRecIds.includes(routineId)}
                              aria-controls={`product-recommendations-${routineId}`}
                            >
                              {expandedProductRecIds.includes(routineId)
                                ? 'Hide Product Recommendations ▲'
                                : 'Show Product Recommendations ▼'}
                            </button>

                            {expandedProductRecIds.includes(routineId) && (
                              <div
                                id={`product-recommendations-${routineId}`}
                                className="unauthorized-routine-products-container"
                              >
                                {productCategories.map((category) => {
                                  if (
                                    subRoutine.products[category] &&
                                    subRoutine.products[category].length > 0
                                  ) {
                                    const product = subRoutine.products[category][0];
                                    return (
                                      <div
                                        key={category}
                                        className="unauthorized-routine-product-category-box"
                                      >
                                        <div
                                          className={`unauthorized-routine-product-category-header unauthorized-routine-${category}-box`}
                                        >
                                          {category.charAt(0).toUpperCase() + category.slice(1)}
                                        </div>
                                        <a
                                          href={product.link}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="unauthorized-routine-product-card"
                                          aria-label={`View details for ${product.name}`}
                                          onClick={(e) => e.stopPropagation()}
                                        >
                                          <img
                                            src={product.image}
                                            alt={product.name}
                                            className="unauthorized-routine-product-image"
                                            loading="lazy"
                                          />
                                          <h5>{product.name}</h5>
                                          <p>{product.description}</p>
                                          <span className="unauthorized-routine-buy-button">
                                            Buy Now
                                          </span>
                                        </a>
                                      </div>
                                    );
                                  }
                                  return null;
                                })}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
        ))}
      </div>

      {/* Only render the "Back to Results" button if we had valid data passed in */}
      {hasData && (
        <div className="unauthorized-routine-back-link">
          <button
            onClick={handleNavigateBack}
            className="unauthorized-routine-back-button"
            aria-label="Navigate back to Results page"
          >
            &larr; Back to Results
          </button>
        </div>
      )}
    </div>
  );
};

export default UnauthorizedBeautyRoutinesPage;
