// Import product images
import UBeauty_LipExfoliator from './images/UBeauty_LipExfoliator.png'
import Elf_LipExfoliator from './images/Elf_LipExfoliator.png'
import Burts_LipBalm from './images/Burts_LipBalm.png'
import EltaMD_LipBalm from './images/EltaMD_LipBalm.png'
import Sturm_LipBalm from './images/Sturm_LipBalm.png'
import Tatcha_LipExfoliator from './images/Tatcha_LipExfoliator.png'

export const LipHealthRoutine = {
    id: 7,
    name: 'Lip Health Routine',
    description: 'Keep your lips hydrated, soft, and healthy with these simple steps.',
    canBeShortened: false,
    routines: [
        {
            name: 'Exfoliate Lips (2-3 times a week)',
            description: 'Remove dead skin from your lips to maintain smoothness.',
            steps: [
                'Apply a gentle lip scrub or make your own with sugar and honey.',
                'Rub it in circular motions for about 30 seconds.',
                'Rinse off with warm water and pat dry.',
            ],
            feedback: 'Be gentle when exfoliating to avoid damaging the delicate skin on your lips.',
            products: {
                beginner: [
                    {
                        id: 901,
                        name: 'e.l.f. Lip Exfoliator',
                        description: 'An affordable lip exfoliator that gently removes dead skin, leaving lips smooth and soft.',
                        link: 'https://amzn.to/417jB5Z',
                        image: Elf_LipExfoliator
                    },
                ],
                advanced: [
                    {
                        id: 902,
                        name: 'Tatcha Kissu Lip Mask',
                        description: 'A mid-range lip mask that hydrates and plumps lips for a fuller appearance.',
                        link: 'https://amzn.to/3CPDKWd',
                        image: Tatcha_LipExfoliator
                    },
                ],
                luxury: [
                    {
                        id: 903,
                        name: 'U Beauty The Plasma Lip Compound',
                        description: 'A luxury lip compound that intensely nourishes and revitalizes the lips.',
                        link: 'https://amzn.to/4beIwsT',
                        image: UBeauty_LipExfoliator
                    },
                ],
            },
        },
        {
            name: 'Hydrate Lips (Daily)',
            description: 'Keep your lips moisturized and prevent them from drying out.',
            steps: [
                'Apply a hydrating lip balm throughout the day, especially in the morning and before bed.',
                'Choose a lip balm with SPF for daytime protection against UV rays.',
                'Reapply as needed, especially in dry or cold conditions.',
            ],
            feedback: 'Using a lip balm with SPF helps protect your lips from sun damage, preventing dark spots and aging.',
            products: {
                beginner: [
                    {
                        id: 904,
                        name: "Burt's Bees Lip Balm",
                        description: 'Affordable and nourishing lip balm with natural ingredients.',
                        link: 'https://amzn.to/4k5wHJH',
                        image: Burts_LipBalm
                    },
                ],
                advanced: [
                    {
                        id: 905,
                        name: 'EltaMD UV Lip Balm',
                        description: 'A mid-range lip balm that provides SPF protection and long-lasting hydration.',
                        link: 'https://amzn.to/3EOTNUG',
                        image: EltaMD_LipBalm
                    },
                ],
                luxury: [
                    {
                        id: 906,
                        name: 'Dr. Barbara Sturm Lip Balm',
                        description: 'A luxury lip balm that offers supreme hydration and smooths fine lines around the lips.',
                        link: 'https://amzn.to/4kbpGXF',
                        image: Sturm_LipBalm
                    },
                ],
            },
        },
    ],
}
