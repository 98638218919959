import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, query, orderBy, onSnapshot, deleteDoc, doc } from 'firebase/firestore';
import { auth, firestore, storage } from '../../../firebaseConfig';
import { ref, getDownloadURL } from 'firebase/storage';
import { calculateScorePercentile } from '../components/utils';
import '../../styles/ScansPage.css';
import { useUser } from '../components/UserContext';
import { getFunctions } from 'firebase/functions';

// ---------------------------------------
// ScanItem Component
// ---------------------------------------
const ScanItem = ({ scan, onClick, onChatClick, onDelete }) => {
  const formattedDate = new Date(scan.created_at.seconds * 1000).toLocaleDateString();
  const formattedTime = new Date(scan.created_at.seconds * 1000).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  });

  // Compute composite score based on available results:
  let computedScore = null;
  if (
    scan.frontResult && scan.frontResult.score != null &&
    scan.sideResult && scan.sideResult.score != null
  ) {
    const frontScore = scan.frontResult.score;
    const sideScore = scan.sideResult.score;
    const higherScore = Math.max(frontScore, sideScore);
    const lowerScore = Math.min(frontScore, sideScore);
    computedScore = (2 / 3) * higherScore + (1 / 3) * lowerScore;
  } else if (scan.frontResult && scan.frontResult.score != null) {
    computedScore = scan.frontResult.score;
  } else if (scan.sideResult && scan.sideResult.score != null) {
    computedScore = scan.sideResult.score;
  }

  const displayedScore = computedScore !== null ? computedScore.toFixed(1) : 'N/A';
  const computedPercentile = computedScore !== null ? Math.ceil(calculateScorePercentile(computedScore)) : 0;

  // State for controlling the ellipsis menu.
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuToggle = (e) => {
    e.stopPropagation();
    setMenuOpen(prev => !prev);
  };

  const handleDeleteClick = (e) => {
    e.stopPropagation();
    setMenuOpen(false);
    onDelete(scan);
  };

  return (
    <li key={scan.id} onClick={() => onClick(scan)} className="scan-item">
      {/* Desktop Layout – exactly as originally designed */}
      <div className="desktop-scan-layout">
        <div className="scan-profile">
          <div className="scan-image-container">
            <img
              src={scan.repImageUrl}
              alt={`Scan ${scan.scan_number}`}
              className="scan-image"
            />
          </div>
          <div className="scan-details">
            <p className="scan-title">Scan {scan.scan_number}</p>
            <p className="scan-date">Date: {formattedDate}</p>
            <p className="scan-time">Time: {formattedTime}</p>
          </div>
        </div>
        <div className="score-container">
          <p>
            Score: {displayedScore * 10} / 100 {!scan.unlocked && <span className="lock-icon">🔒</span>}
          </p>
          <div className="score-bar-container">
            <div className="score-bar">
              <span
                className="score-label-dynamic"
                style={{ left: `${computedPercentile}%` }}
              >
                {computedPercentile}%
              </span>
              <div
                className="score-bar-inner"
                style={{ width: `${computedPercentile}%` }}
              ></div>
            </div>
          </div>
        </div>
        <div className="scan-actions">
          <button
            className="chat-button"
            onClick={(e) => {
              e.stopPropagation();
              onChatClick(scan);
            }}
          >
            Chat
          </button>
          <div className="scan-menu">
            <button
              className="menu-button"
              onClick={handleMenuToggle}
            >
              ⋮
            </button>
            {menuOpen && (
              <div className="dropdown-menu">
                <button
                  className="dropdown-item"
                  onClick={handleDeleteClick}
                >
                  Delete Scan
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Mobile Layout – only visible on small screens */}
      <div className="mobile-scan-layout">
        <div className="mobile-image">
          <img
            src={scan.repImageUrl}
            alt={`Scan ${scan.scan_number}`}
          />
        </div>
        <div className="mobile-info">
          <p className="scan-title">Scan {scan.scan_number}</p>
          <p className="mobile-score">
            Score: {displayedScore * 10} / 100 {!scan.unlocked && <span className="lock-icon">🔒</span>}
          </p>
        </div>
        <div className="mobile-actions">
          <button
            className="chat-button"
            onClick={(e) => {
              e.stopPropagation();
              onChatClick(scan);
            }}
          >
            Chat
          </button>
          <div className="scan-menu">
            <button
              className="menu-button"
              onClick={handleMenuToggle}
            >
              ⋮
            </button>
            {menuOpen && (
              <div className="dropdown-menu">
                <button
                  className="dropdown-item"
                  onClick={handleDeleteClick}
                >
                  Delete Scan
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </li>
  );
};

// ---------------------------------------
// ScansPage Component
// ---------------------------------------
const ScansPage = () => {
  const [scans, setScans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const scansPerPage = 5; // Number of scans per page
  const navigate = useNavigate();
  const { setCredits } = useUser(); // kept for potential future use
  const functions = getFunctions();

  useEffect(() => {
    let isMounted = true;
    let unsubscribeScans = null;

    // Load cached scans from sessionStorage for a fast initial render.
    const cachedScans = sessionStorage.getItem('scansData');
    if (cachedScans) {
      setScans(JSON.parse(cachedScans));
      setLoading(false);
    }

    // Listen for auth state changes.
    const unsubscribeAuth = auth.onAuthStateChanged((user) => {
      if (user) {
        // Create a query to get scans ordered by created_at.
        const userScansRef = collection(firestore, 'users', user.uid, 'scans');
        const scansQuery = query(userScansRef, orderBy('created_at', 'desc'));

        // Subscribe to real-time updates.
        unsubscribeScans = onSnapshot(
          scansQuery,
          async (snapshot) => {
            const scansList = await Promise.all(
              snapshot.docs.map(async (docSnapshot) => {
                const scanData = docSnapshot.data();
                // Fetch the representative image URL.
                const repImageUrl = await getDownloadURL(ref(storage, scanData.repImageUrl));
                return {
                  id: docSnapshot.id,
                  ...scanData,
                  repImageUrl,
                };
              })
            );
            if (isMounted) {
              setScans(scansList);
              sessionStorage.setItem('scansData', JSON.stringify(scansList));
              setLoading(false);
            }
          },
          (err) => {
            console.error('Error fetching scans:', err);
            if (isMounted) {
              setError(err);
              setLoading(false);
            }
          }
        );
      } else {
        if (isMounted) {
          setScans([]);
        }
      }
    });

    // Cleanup on unmount.
    return () => {
      isMounted = false;
      if (unsubscribeScans) unsubscribeScans();
      unsubscribeAuth();
    };
  }, []);

  // Navigates to the results page for the selected scan.
  const handleScanClick = (scan) => {
    navigate('/results', { state: { data: scan } });
  };

  // Chat button handler: navigate based on unlocked status.
  const handleChatClick = (scan) => {
    if (scan.unlocked) {
      navigate('/routines', { state: { routines: scan.routines, scanId: scan.scan_id || scan.id } });
    } else {
      navigate('/results', { state: { data: scan } });
    }
  };

  // Delete scan handler.
  const handleDeleteScan = async (scan) => {
    if (window.confirm("Are you sure you want to delete this scan?")) {
      try {
        const user = auth.currentUser;
        if (user) {
          await deleteDoc(doc(firestore, 'users', user.uid, 'scans', scan.id));
        } else {
          console.error("User not authenticated.");
        }
      } catch (error) {
        console.error("Error deleting scan:", error);
      }
    }
  };

  const totalPages = Math.ceil(scans.length / scansPerPage);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const displayedScans = scans.slice(
    (currentPage - 1) * scansPerPage,
    currentPage * scansPerPage
  );

  if (loading) {
    return <div className="scans-page">Loading...</div>;
  }

  if (error) {
    return (
      <div className="scans-page">
        Error loading scans: {error.message}
      </div>
    );
  }

  return (
    <div className="scans-page">
      <h1 className="scans-title">
        <span className="title-black">Your Personalized</span>{' '}
        <span className="title-purple">Scan History</span>
      </h1>
      <p className="scans-subtitle">
        Review past scans and track your beauty journey for insights tailored to your unique skin needs.
      </p>
      <ul className="scans-list">
        {displayedScans.map((scan) => (
          <ScanItem
            key={scan.id}
            scan={scan}
            onClick={handleScanClick}
            onChatClick={handleChatClick}
            onDelete={handleDeleteScan}
          />
        ))}
      </ul>
      <div className="pagination">
        <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
          &lt;
        </button>
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            className={currentPage === index + 1 ? 'active' : ''}
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </button>
        ))}
        <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
          &gt;
        </button>
      </div>
    </div>
  );
};

export default ScansPage;
