import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import LockedFrontResultsComponent from '../components/ScanResultPages/FullScan/LockedFrontResultsComponent';
import LockedSideResultsComponent from '../components/ScanResultPages/FullScan/LockedSideResultsComponent';
import FailedSideResultsComponent from '../../General/FailedSideResultsComponent';
import FailedFrontResultsComponent from '../../General/FailedFrontResultsComponent';
import ProgressBar from '../../General/ProgressBar';
import BellCurve from '../../General/BellCurve';
import colors from '../../styles/colors';
import { collection, query, getDocs, orderBy } from 'firebase/firestore';
import { auth, firestore } from '../../../firebaseConfig';

function LockedResultsPage({ data, onUnlockConfirm, scanId }) {
  const [currentView, setCurrentView] = useState('front');
  const navigate = useNavigate();
  const scrollRef = useRef(null);

  // Explore Routines handler to match the front profile page
  const handleExploreRoutines = () => {
    if (data && data.routines) {
      navigate('/routines', { state: { routines: data.routines, scanId } });
    }
  };

  // Compute overall score using the frontResult
  const overallScore =
    data && data.frontResult ? Math.ceil(data.frontResult.score * 10) : null;

  // Handlers for switching between front and side views
  const handleFrontClick = () => {
    setCurrentView('front');
  };

  const handleSideClick = () => {
    setCurrentView('side');
    console.log(data.sideResult?.score);
  };

  return (
    <div
      className="profileContainer"
      style={{ minWidth: '1100px', overflowX: 'auto', minHeight: '100vh' }}
    >
      {/* Header Section */}
      <header style={{ textAlign: 'center', marginBottom: '20px' }}>
        <h1 className="mainPageTitle">Your AI Beauty Insights</h1>
        <p className="pageDescription">
          Discover personalized recommendations and insights tailored to your unique skin analysis.
        </p>
        <div className="header-content">
          {overallScore !== null && (
            <div style={{ fontSize: '36px', fontWeight: 'bold', color: '#2c3e50' }}>
              Overall Score: {overallScore} /100
            </div>
          )}
          <div className="header-buttons">
            <button
              className="explore-button"
              onClick={handleExploreRoutines}
              style={{ padding: '10px 20px', cursor: 'pointer', fontSize: '16px' }}
            >
              Explore Routines
            </button>
            <button
              onClick={onUnlockConfirm}
              style={{
                background: colors.primary,
                border: 'none',
                borderRadius: '12px',
                padding: '10px 20px',
                color: '#fff',
                cursor: 'pointer',
                fontSize: '16px',
              }}
            >
              Unlock Full Results
            </button>
          </div>
        </div>
      </header>

      {/* Results Section */}
      <div>
        {currentView === 'front' && data?.frontResult && (
          data.frontResult.score === 0 ? (
            <FailedFrontResultsComponent imagePath={data.frontResult.image_path} />
          ) : (
            <LockedFrontResultsComponent
              data={data.frontResult}
              routines={data.routines}
              imagePath={data.frontResult.image_path}
              onUnlockConfirm={onUnlockConfirm}
              scanId={scanId}
              onSwitchProfile={(profile) => setCurrentView(profile)}
            />
          )
        )}
        {currentView === 'side' && data?.sideResult && (
          data.sideResult.score === 0 ? (
            <FailedSideResultsComponent imagePath={data.sideResult.image_path} />
          ) : (
            <LockedSideResultsComponent
              data={data.sideResult}
              routines={data.routines}
              imagePath={data.sideResult.image_path}
              onUnlockConfirm={onUnlockConfirm}
              scanId={scanId}
              onSwitchProfile={(profile) => setCurrentView(profile)}
            />
          )
        )}
      </div>
    </div>
  );
}

export default LockedResultsPage;
