import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../../firebaseConfig';

// React Circular Progress Bar
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

// React Easy Crop for cropping images
import Cropper from 'react-easy-crop';

// Images (adjust import paths as needed)
import frontProfilePlaceholder from '../../../assets/images/front_profile_example1.png';
import sideProfilePlaceholder from '../../../assets/images/side_profile_example1.png';
import homeAnalyzedWoman from '../../../assets/images/homeAnalyzedWoman.png';

// "How It Works" images
import ScanYourFaceGroup from '../../../assets/images/ScanYourFaceGroup.png';
import ScanResultsGroup from '../../../assets/images/ScanResultsGroup.png';
import AIChatGroup from '../../../assets/images/AIChatGroup.png';

// Styles
import '../../styles/Authorized/AuthorizedUploadPage.css';

function UploadPage() {
  // --- State for scanning & images ---
  const [scanType, setScanType] = useState('fullScan');
  const [frontImage, setFrontImage] = useState(null);
  const [sideImage, setSideImage] = useState(null);
  const [frontDisplayImage, setFrontDisplayImage] = useState(frontProfilePlaceholder);
  const [sideDisplayImage, setSideDisplayImage] = useState(sideProfilePlaceholder);
  const [gender, setGender] = useState('male');

  // --- Loading & progress ---
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  const navigate = useNavigate();

  // --- Crop Modal States ---
  const [isCropModalOpen, setCropModalOpen] = useState(false);
  const [imageToCrop, setImageToCrop] = useState(null);
  const [cropType, setCropType] = useState(''); // 'front' or 'side'
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  // --- Scan Type & Gender handlers ---
  const handleScanTypeButton = (selectedScanType) => {
    setScanType(selectedScanType);
  };

  const handleGenderButton = (selectedGender) => {
    setGender(selectedGender);
  };

  // --- Open Crop Modal ---
  const openCropModal = (file, type) => {
    setCropType(type);
    setImageToCrop(file);
    setCropModalOpen(true);
    // Reset crop and zoom values
    setCrop({ x: 0, y: 0 });
    setZoom(1);
  };

  // --- File Input Change Handlers ---
  const handleFrontImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      openCropModal(e.target.files[0], 'front');
    }
  };

  const handleSideImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      openCropModal(e.target.files[0], 'side');
    }
  };

  // --- Crop Handler ---
  const handleCrop = async () => {
    try {
      const imageUrl = URL.createObjectURL(imageToCrop);
      const { blob, croppedImageUrl } = await getCroppedImg(imageUrl, croppedAreaPixels);
      if (cropType === 'front') {
        setFrontImage(blob);
        setFrontDisplayImage(croppedImageUrl);
      } else if (cropType === 'side') {
        setSideImage(blob);
        setSideDisplayImage(croppedImageUrl);
      }
      setCropModalOpen(false);
    } catch (error) {
      console.error('Error cropping image:', error);
    }
  };

  // --- Helper: create image from URL ---
  function createImage(url) {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener('load', () => resolve(image));
      image.addEventListener('error', (error) => reject(error));
      image.setAttribute('crossOrigin', 'anonymous');
      image.src = url;
    });
  }

  // --- Helper: get cropped image using canvas ---
  async function getCroppedImg(imageSrc, pixelCrop) {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error('Canvas is empty'));
          return;
        }
        blob.name = 'cropped.jpeg';
        const croppedImageUrl = URL.createObjectURL(blob);
        resolve({ blob, croppedImageUrl });
      }, 'image/jpeg');
    });
  }

  // --- Submit Handler ---
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!auth.currentUser) {
      console.error('User not authenticated');
      return;
    }

    setLoading(true);
    setProgress(0);

    const uid = auth.currentUser.uid;
    const frontImageBase64 = frontImage ? await toBase64(frontImage) : null;
    const sideImageBase64 = sideImage ? await toBase64(sideImage) : null;

    // Simulate progress
    const incrementProgress = () => {
      let percent = 0;
      const interval = setInterval(() => {
        percent += 1;
        setProgress(percent);
        if (percent >= 100) clearInterval(interval);
      }, 150);
    };
    incrementProgress();

    try {
      let endpoint = '';
      let bodyData = {};

      switch (scanType) {
        case 'frontProfile':
          endpoint =
            'https://us-central1-golden-ratio-23459.cloudfunctions.net/api/processFrontProfileScan';
          bodyData = { uid, frontImage: frontImageBase64, gender };
          break;
        case 'sideProfile':
          endpoint =
            'https://us-central1-golden-ratio-23459.cloudfunctions.net/api/processSideProfileScan';
          bodyData = { uid, sideImage: sideImageBase64, gender };
          break;
        case 'fullScan':
          endpoint =
            'https://us-central1-golden-ratio-23459.cloudfunctions.net/api/processUserScan';
          bodyData = { uid, frontImage: frontImageBase64, sideImage: sideImageBase64, gender };
          break;
        default:
          throw new Error('Invalid scan type');
      }

      const response = await fetch(endpoint, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(bodyData),
      });

      if (!response.ok) throw new Error('Network response was not ok');

      const data = await response.json();
      const { scanData } = data;
      console.log(scanData);

      setTimeout(() => {
        setLoading(false);
        navigate('/results', { state: { data: scanData } });
      }, 15000);
    } catch (error) {
      console.error('Error processing scan:', error);
      setLoading(false);
    }
  };

  // --- Helper: convert file to base64 ---
  const toBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(',')[1]);
      reader.onerror = (error) => reject(error);
    });
  };

  const isFrontPlaceholderActive = frontImage === null;
  const isSidePlaceholderActive = sideImage === null;

  return (
    <>
      {/* Crop Modal */}
      {isCropModalOpen && (
        <div className="crop-modal-overlay">
          <div className="crop-modal">
            <h2>Crop Your Image</h2>
            <div className="crop-container">
              <Cropper
                image={URL.createObjectURL(imageToCrop)}
                crop={crop}
                zoom={zoom}
                aspect={1} // square crop
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={(croppedArea, croppedAreaPixels) =>
                  setCroppedAreaPixels(croppedAreaPixels)
                }
              />
            </div>
            <div className="crop-modal-buttons">
              <button onClick={handleCrop}>Crop</button>
              <button onClick={() => setCropModalOpen(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}

      {/********************************************************************
        1) Personalized Guide + Upload Section with Gradient Background
      *********************************************************************/}
      <section className="authorized-upload-page__personalized-guide-upload-section">
        <section className="authorized-upload-page__purple-gradient-section">
          <h1 className="authorized-upload-page__gradient-title">
            <span className="title-black">Your Personalized </span>
            <span className="title-purple">Beauty Guide</span> with AI
          </h1>
          <p className="authorized-upload-page__gradient-subtitle">
            Analyze. Chat. Discover your perfect products.
          </p>
        </section>

        <div className="authorized-upload-page__upload-page">
          <h1 className="authorized-upload-page__upload-title">
            {loading ? 'Analyzing Your Face' : 'Upload Photos To See Your Facial Attractiveness'}
          </h1>

          {!loading && (
            <form onSubmit={handleSubmit} className="authorized-upload-page__upload-form">
              <div className="authorized-upload-page__form-content-row">
                {/* Left Panel */}
                <div className="authorized-upload-page__settings-left">
                  <div className="authorized-upload-page__scan-type-selector">
                    <label>Select Scan Type:</label>
                    <div className="authorized-upload-page__button-group">
                      <button
                        type="button"
                        className={scanType === 'frontProfile' ? 'active' : ''}
                        onClick={() => handleScanTypeButton('frontProfile')}
                      >
                        Front Profile
                      </button>
                      <button
                        type="button"
                        className={scanType === 'sideProfile' ? 'active' : ''}
                        onClick={() => handleScanTypeButton('sideProfile')}
                      >
                        Side Profile
                      </button>
                      <button
                        type="button"
                        className={scanType === 'fullScan' ? 'active' : ''}
                        onClick={() => handleScanTypeButton('fullScan')}
                      >
                        Full Scan
                      </button>
                    </div>
                  </div>

                  <div className="authorized-upload-page__gender-section">
                    <label>Gender:</label>
                    <div className="authorized-upload-page__button-group">
                      <button
                        type="button"
                        className={gender === 'male' ? 'active' : ''}
                        onClick={() => handleGenderButton('male')}
                      >
                        Male
                      </button>
                      <button
                        type="button"
                        className={gender === 'female' ? 'active' : ''}
                        onClick={() => handleGenderButton('female')}
                      >
                        Female
                      </button>
                      <button
                        type="button"
                        className={gender === 'other' ? 'active' : ''}
                        onClick={() => handleGenderButton('other')}
                      >
                        Other
                      </button>
                    </div>
                  </div>

                  <button
                    type="submit"
                    className="authorized-upload-page__start-journey-button"
                    disabled={
                      (scanType === 'frontProfile' && !frontImage) ||
                      (scanType === 'sideProfile' && !sideImage) ||
                      (scanType === 'fullScan' && (!frontImage || !sideImage))
                    }
                  >
                    Start Beauty Journey
                  </button>
                </div>

                {/* Right Panel */}
                {scanType === 'frontProfile' && (
                  <div
                    className={`authorized-upload-page__upload-section authorized-upload-page__upload-section--single-upload ${
                      isFrontPlaceholderActive ? 'authorized-upload-page__front-placeholder-active' : ''
                    }`}
                  >
                    <h2 className="authorized-upload-page__section-title">Front Profile</h2>
                    <div
                      className="authorized-upload-page__upload-box authorized-upload-page__upload-box--single-upload"
                      onClick={() => document.getElementById('front-input').click()}
                      role="button"
                      tabIndex={0}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                          document.getElementById('front-input').click();
                        }
                      }}
                    >
                      {frontImage ? (
                        <img
                          src={frontDisplayImage}
                          alt="Front Profile"
                          className="authorized-upload-page__uploaded-image"
                        />
                      ) : (
                        <div className="authorized-upload-page__placeholder">
                          <div className="authorized-upload-page__placeholder-icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="64"
                              height="64"
                              fill="#ffffff"
                              viewBox="0 0 24 24"
                            >
                              <path d="M21 19V5c0-1.1-.9-2-2-2H5C3.9 3 3 3.9 3 5v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zm-3-9l-5 5-5-5V5h10v5z" />
                            </svg>
                          </div>
                        </div>
                      )}
                      <input
                        id="front-input"
                        type="file"
                        accept="image/*"
                        onChange={handleFrontImageChange}
                        className="authorized-upload-page__file-input"
                      />
                    </div>
                  </div>
                )}

                {scanType === 'sideProfile' && (
                  <div
                    className={`authorized-upload-page__upload-section authorized-upload-page__upload-section--single-upload ${
                      isSidePlaceholderActive ? 'authorized-upload-page__side-placeholder-active' : ''
                    }`}
                  >
                    <h2 className="authorized-upload-page__section-title">Side Profile</h2>
                    <div
                      className="authorized-upload-page__upload-box authorized-upload-page__upload-box--single-upload"
                      onClick={() => document.getElementById('side-input').click()}
                      role="button"
                      tabIndex={0}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                          document.getElementById('side-input').click();
                        }
                      }}
                    >
                      {sideImage ? (
                        <img
                          src={sideDisplayImage}
                          alt="Side Profile"
                          className="authorized-upload-page__uploaded-image"
                        />
                      ) : (
                        <div className="authorized-upload-page__placeholder">
                          <div className="authorized-upload-page__placeholder-icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="64"
                              height="64"
                              fill="#ffffff"
                              viewBox="0 0 24 24"
                            >
                              <path d="M21 19V5c0-1.1-.9-2-2-2H5C3.9 3 3 3.9 3 5v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zm-3-9l-5 5-5-5V5h10v5z" />
                            </svg>
                          </div>
                        </div>
                      )}
                      <input
                        id="side-input"
                        type="file"
                        accept="image/*"
                        onChange={handleSideImageChange}
                        className="authorized-upload-page__file-input"
                      />
                    </div>
                  </div>
                )}

                {scanType === 'fullScan' && (
                  <div className="authorized-upload-page__double-upload-sections">
                    <div
                      className={`authorized-upload-page__upload-section ${
                        isFrontPlaceholderActive ? 'authorized-upload-page__front-placeholder-active' : ''
                      }`}
                    >
                      <h2 className="authorized-upload-page__section-title">Front Profile</h2>
                      <div
                        className="authorized-upload-page__upload-box"
                        onClick={() => document.getElementById('front-input').click()}
                        role="button"
                        tabIndex={0}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter' || e.key === ' ') {
                            document.getElementById('front-input').click();
                          }
                        }}
                      >
                        {frontImage ? (
                          <img
                            src={frontDisplayImage}
                            alt="Front Profile"
                            className="authorized-upload-page__uploaded-image"
                          />
                        ) : (
                          <div className="authorized-upload-page__placeholder">
                            <div className="authorized-upload-page__placeholder-icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="64"
                                height="64"
                                fill="#ffffff"
                                viewBox="0 0 24 24"
                              >
                                <path d="M21 19V5c0-1.1-.9-2-2-2H5C3.9 3 3 3.9 3 5v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zm-3-9l-5 5-5-5V5h10v5z" />
                              </svg>
                            </div>
                          </div>
                        )}
                        <input
                          id="front-input"
                          type="file"
                          accept="image/*"
                          onChange={handleFrontImageChange}
                          className="authorized-upload-page__file-input"
                        />
                      </div>
                    </div>

                    <div
                      className={`authorized-upload-page__upload-section ${
                        isSidePlaceholderActive ? 'authorized-upload-page__side-placeholder-active' : ''
                      }`}
                    >
                      <h2 className="authorized-upload-page__section-title">Side Profile</h2>
                      <div
                        className="authorized-upload-page__upload-box"
                        onClick={() => document.getElementById('side-input').click()}
                        role="button"
                        tabIndex={0}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter' || e.key === ' ') {
                            document.getElementById('side-input').click();
                          }
                        }}
                      >
                        {sideImage ? (
                          <img
                            src={sideDisplayImage}
                            alt="Side Profile"
                            className="authorized-upload-page__uploaded-image"
                          />
                        ) : (
                          <div className="authorized-upload-page__placeholder">
                            <div className="authorized-upload-page__placeholder-icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="64"
                                height="64"
                                fill="#ffffff"
                                viewBox="0 0 24 24"
                              >
                                <path d="M21 19V5c0-1.1-.9-2-2-2H5C3.9 3 3 3.9 3 5v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zm-3-9l-5 5-5-5V5h10v5z" />
                              </svg>
                            </div>
                          </div>
                        )}
                        <input
                          id="side-input"
                          type="file"
                          accept="image/*"
                          onChange={handleSideImageChange}
                          className="authorized-upload-page__file-input"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </form>
          )}

          {loading && (
            <div className="authorized-upload-page__loading-spinner">
              <CircularProgressbar
                value={progress}
                text={`${progress}%`}
                styles={buildStyles({
                  pathColor: '#7e3ba1',
                  textColor: '#7e3ba1',
                  trailColor: '#d6d6d6',
                  backgroundColor: '#3e98c7',
                })}
              />
            </div>
          )}
        </div>
      </section>

      {/********************************************************************
        2) White Background “About Us” Section
      *********************************************************************/}
      <section className="authorized-upload-page__white-about-section">
        <h2 className="authorized-upload-page__section-heading">
          About Us <span className="authorized-upload-page__highlight">Redefining Beauty</span> with AI
        </h2>
        <p className="authorized-upload-page__section-subtitle">
          Revolutionizing Your Beauty Routine with AI Precision
        </p>

        <div className="authorized-upload-page__about-content">
          <div className="authorized-upload-page__about-text">
            <p className="authorized-upload-page__about-intro">
              Discover how our innovative AI technology transforms your beauty experience by
              providing personalized insights and recommendations.
            </p>
            <ul className="authorized-upload-page__about-bullets">
              <li>Comprehensive Facial Analysis: Understand your unique facial features and skin needs.</li>
              <li>Tailored Product Recommendations: Receive suggestions that perfectly match your profile.</li>
              <li>Interactive AI Assistance: Engage with our intelligent assistant for expert advice and guidance.</li>
            </ul>
          </div>
          <div className="authorized-upload-page__about-image">
            <img src={homeAnalyzedWoman} alt="About us placeholder" />
          </div>
        </div>
      </section>

      {/********************************************************************
        3) Light Purple “How It Works” Section
      *********************************************************************/}
      <section className="authorized-upload-page__light-purple-howitworks-section">
        <h2 className="authorized-upload-page__section-heading">
          How It <span className="authorized-upload-page__highlight">Works</span>
        </h2>
        <p className="authorized-upload-page__section-subtitle">
          Revolutionizing Your Beauty Routine with AI Precision
        </p>

        <div className="how-it-works-step how-it-works-step--1">
          <div className="step-text">
            <h3>Scan Your Face</h3>
            <p>
              Start your journey with a quick facial scan. Our advanced AI technology
              analyzes your unique features, identifying your skin type, texture, and
              specific concerns like dryness, acne, or signs of aging.
            </p>
          </div>
          <div className="step-image">
            <img src={ScanYourFaceGroup} alt="Scan Your Face" />
          </div>
        </div>

        <div className="how-it-works-step how-it-works-step--2">
          <div className="step-image">
            <img src={ScanResultsGroup} alt="Receive Personalized Recommendations" />
          </div>
          <div className="step-text">
            <h3>Receive Personalized Recommendations</h3>
            <p>
              Our advanced AI technology suggests products tailored to your skin’s needs.
              Whether it’s dryness, acne, or aging concerns, discover the perfect routine
              for a healthier, radiant look.
            </p>
          </div>
        </div>

        <div className="how-it-works-step how-it-works-step--3">
          <div className="step-text">
            <h3>Chat with AI and Consult Your Product</h3>
            <p>
              Engage in an interactive conversation with our intelligent beauty assistant.
              Learn about effective skincare routines, ask questions, and get expert advice
              tailored to your profile.
            </p>
          </div>
          <div className="step-image">
            <img src={AIChatGroup} alt="Chat with AI" />
          </div>
        </div>
      </section>
    </>
  );
}

export default UploadPage;
