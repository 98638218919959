import React from 'react';

function SideFeaturesToggle({ visibleFeatures, toggleFeature }) {
  const features = [
    { key: 'philtrumFlatness', label: 'Philtrum Flatness' },
    { key: 'leftGonialAngle', label: 'Left Gonial Angle' },
    { key: 'nasofrontalAngle', label: 'Nasofrontal Angle' },
    { key: 'nasalAngle', label: 'Nasal Angle' },
    { key: 'nasolabialAngle', label: 'Nasolabial Angle' },
    { key: 'nasalProjection', label: 'Nasal Projection' },
    { key: 'totalFacialConvexity', label: 'Total Facial Convexity' },
    { key: 'facialConvexity', label: 'Facial Convexity' },
    { key: 'mandibularPlaneAngle', label: 'Mandibular Plane Angle' },
  ];

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gap: '10px'
      }}
    >
      {features.map((feature) => (
        <div
          key={feature.key}
          onClick={() => toggleFeature(feature.key)}
          style={{
            cursor: 'pointer',
            padding: '10px',
            textAlign: 'center',
            borderRadius: '5px',
            border: visibleFeatures[feature.key]
              ? '1px solid purple'
              : '1px solid gray',
            color: visibleFeatures[feature.key] ? 'purple' : 'black',
            backgroundColor: 'transparent'
          }}
        >
          {feature.label}
        </div>
      ))}
    </div>
  );
}

export default SideFeaturesToggle;
