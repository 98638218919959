// FrontResultsComponent.jsx
import React, { useEffect, useRef, useState, useMemo } from 'react';
import ProcessedImage from '../../../../General/ProcessedImage';
import FrontTraitsToggle from '../../FrontTraitsToggle';
import FrontRatiosToggle from '../../FrontRatiosToggle';
import ProgressBar from '../../../../General/ProgressBar';
import ScoreRadarChart from '../../../../General/ScoreRadarChart';
import colors from '../../../../styles/colors';
import { calculateScorePercentile } from '../../utils';
import { useNavigate } from 'react-router-dom';
import '../../../../styles/GlowUpContainerComponent.css';

function FrontResultsComponent({ data, routines, imagePath, scanId, onSwitchProfile }) {
  const canvasRef = useRef(null);
  const [visibleFeatures, setVisibleFeatures] = useState({
    pupilDistance: false,
    leftEyeLength: false,
    rightEyeLength: false,
    faceLength: false,
    faceWidth: false,
    noseWidth: false,
    noseHeight: false,
    mouthWidth: false,
    eyeSpacing: false,
    eyeOuterDistance: false,
    leftEyeHeight: false,
    rightEyeHeight: false,
    jawWidth: false,
    chinHeight: false,
    philtrumLength: false,
    midfaceLength: false,
    upperLipHeight: false,
    lowerLipHeight: false,
    eyeSeparationRatio: false,
    eyeAspectRatioLeft: false,
    eyeAspectRatioRight: false,
    jawWidthRatio: false,
    chinPhiltrumRatio: false,
    midfaceRatio: false,
    midfaceCompactness: false,
    mouthNoseHarmony: false,
    eyeSpacingRatio: false,
    noseHeightToWidthRatio: false,
    lipRatio: false,
    facialThirds: false,
  });
  const [sortOrder, setSortOrder] = useState('default');
  const [toggleView, setToggleView] = useState('traits');
  const [imageHeight, setImageHeight] = useState(0);
  const navigate = useNavigate();

  // Responsive handling for small screens
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const isMobile = windowWidth < 768;

  const toggleFeature = (feature) => {
    setVisibleFeatures(prev => ({
      ...prev,
      [feature]: !prev[feature],
    }));
  };

  useEffect(() => {
    if (data && canvasRef.current) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      const image = new Image();
      image.src = imagePath;
      image.onload = () => {
        // Use 420px width on desktop and 90% of window width on mobile.
        const availableWidth = isMobile ? window.innerWidth * 0.9 : 420;
        const scale = availableWidth / image.width;
        const calculatedHeight = image.height * scale;
        setImageHeight(calculatedHeight);

        canvas.width = availableWidth;
        canvas.height = calculatedHeight;

        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
        ctx.save();
        ctx.scale(scale, scale);

        const drawLine = (point1, point2) => {
          ctx.beginPath();
          ctx.moveTo(point1[0], point1[1]);
          ctx.lineTo(point2[0], point2[1]);
          ctx.stroke();
        };

        ctx.strokeStyle = 'green';
        ctx.lineWidth = 3 / scale;

        const drawRatio = (ratio, points) => {
          points.forEach(pair => {
            drawLine(data.measurements_coords[pair[0]], data.measurements_coords[pair[1]]);
          });
        };

        const drawHorizontalLines = (points) => {
          const colorsArr = ['red', 'green', 'blue'];
          const labels = ['Forehead Line', 'Brow Line', 'Nose Line', 'Chin Line'];
          points.forEach((point, index) => {
            ctx.strokeStyle = colorsArr[index % colorsArr.length];
            ctx.lineWidth = 2 / scale;
            drawLine([0, point[1]], [canvas.width / scale, point[1]]);
            ctx.fillStyle = colorsArr[index % colorsArr.length];
            ctx.fillText(labels[index], 10, point[1] - 10);
          });
        };

        // Draw traits
        if (visibleFeatures.pupilDistance) {
          drawLine(data.measurements_coords["Left Pupil"], data.measurements_coords["Right Pupil"]);
        }
        if (visibleFeatures.leftEyeLength) {
          drawLine(data.measurements_coords["Left Eye Start"], data.measurements_coords["Left Eye End"]);
        }
        if (visibleFeatures.rightEyeLength) {
          drawLine(data.measurements_coords["Right Eye Start"], data.measurements_coords["Right Eye End"]);
        }
        if (visibleFeatures.faceLength) {
          drawLine(data.measurements_coords["Chin Point"], data.measurements_coords["Forehead Point"]);
        }
        if (visibleFeatures.faceWidth) {
          drawLine(data.measurements_coords["Left Face Point"], data.measurements_coords["Right Face Point"]);
        }
        if (visibleFeatures.noseWidth) {
          drawLine(data.measurements_coords["Nose Left Point"], data.measurements_coords["Nose Right Point"]);
        }
        if (visibleFeatures.noseHeight) {
          drawLine(data.measurements_coords["Nose Top Point"], data.measurements_coords["Nose Bottom Point"]);
        }
        if (visibleFeatures.mouthWidth) {
          drawLine(data.measurements_coords["Mouth Left Point"], data.measurements_coords["Mouth Right Point"]);
        }
        if (visibleFeatures.eyeSpacing) {
          drawLine(data.measurements_coords["Left Eye Inner Point"], data.measurements_coords["Right Eye Inner Point"]);
        }
        if (visibleFeatures.eyeOuterDistance) {
          drawLine(data.measurements_coords["Left Eye Outer Point"], data.measurements_coords["Right Eye Outer Point"]);
        }
        if (visibleFeatures.leftEyeHeight) {
          drawLine(data.measurements_coords["Left Eye Height Start"], data.measurements_coords["Left Eye Height End"]);
        }
        if (visibleFeatures.rightEyeHeight) {
          drawLine(data.measurements_coords["Right Eye Height Start"], data.measurements_coords["Right Eye Height End"]);
        }
        if (visibleFeatures.jawWidth) {
          drawLine(data.measurements_coords["Jaw Left Point"], data.measurements_coords["Jaw Right Point"]);
        }
        if (visibleFeatures.chinHeight) {
          drawLine(data.measurements_coords["Chin Height Start"], data.measurements_coords["Chin Height End"]);
        }
        if (visibleFeatures.philtrumLength) {
          drawLine(data.measurements_coords["Philtrum Start"], data.measurements_coords["Philtrum End"]);
        }
        if (visibleFeatures.midfaceLength) {
          drawLine(data.measurements_coords["Midface Start"], data.measurements_coords["Midface End"]);
        }
        if (visibleFeatures.upperLipHeight) {
          drawLine(data.measurements_coords["Upper Lip Top Point"], data.measurements_coords["Upper Lip Bottom Point"]);
        }
        if (visibleFeatures.lowerLipHeight) {
          drawLine(data.measurements_coords["Lower Lip Top Point"], data.measurements_coords["Lower Lip Bottom Point"]);
        }
        if (visibleFeatures.bitemporalWidth) {
          drawLine(data.measurements_coords["Left Temple Point"], data.measurements_coords["Right Temple Point"]);
        }
        if (visibleFeatures.facialThirds) {
          const points = [
            data.measurements_coords["Forehead Point"],
            data.measurements_coords["Brow Line Point"],
            data.measurements_coords["Nose Base Point"],
            data.measurements_coords["Chin Point"]
          ];
          drawHorizontalLines(points);
        }

        // Draw ratios
        if (visibleFeatures.eyeSeparationRatio) {
          drawRatio('eyeSeparationRatio', [["Left Pupil", "Right Pupil"], ["Left Face Point", "Right Face Point"]]);
        }
        if (visibleFeatures.eyeAspectRatioLeft) {
          drawRatio('eyeAspectRatioLeft', [["Left Eye Start", "Left Eye End"], ["Left Eye Height Start", "Left Eye Height End"]]);
        }
        if (visibleFeatures.eyeAspectRatioRight) {
          drawRatio('eyeAspectRatioRight', [["Right Eye Start", "Right Eye End"], ["Right Eye Height Start", "Right Eye Height End"]]);
        }
        if (visibleFeatures.jawWidthRatio) {
          drawRatio('jawWidthRatio', [["Jaw Left Point", "Jaw Right Point"], ["Left Face Point", "Right Face Point"]]);
        }
        if (visibleFeatures.chinPhiltrumRatio) {
          drawRatio('chinPhiltrumRatio', [["Chin Height Start", "Chin Height End"], ["Philtrum Start", "Philtrum End"]]);
        }
        if (visibleFeatures.midfaceRatio) {
          drawRatio('midfaceRatio', [["Left Pupil", "Right Pupil"], ["Midface Start", "Midface End"]]);
        }
        if (visibleFeatures.midfaceCompactness) {
          drawRatio('midfaceCompactness', [["Left Face Point", "Right Face Point"], ["Midface Start", "Midface End"]]);
        }
        if (visibleFeatures.mouthNoseHarmony) {
          drawRatio('mouthNoseHarmony', [["Mouth Left Point", "Mouth Right Point"], ["Nose Left Point", "Nose Right Point"]]);
        }
        if (visibleFeatures.eyeSpacingRatio) {
          drawRatio('eyeSpacingRatio', [["Left Eye Inner Point", "Right Eye Inner Point"], ["Left Eye Outer Point", "Right Eye Outer Point"]]);
        }
        if (visibleFeatures.noseHeightToWidthRatio) {
          drawRatio('noseHeightToWidthRatio', [["Nose Top Point", "Nose Bottom Point"], ["Nose Left Point", "Nose Right Point"]]);
        }
        if (visibleFeatures.lipRatio) {
          const adjustUpperLip = ([x, y]) => [x + 3, y];
          const adjustLowerLip = ([x, y]) => [x - 3, y];
          drawLine(
            adjustUpperLip(data.measurements_coords["Upper Lip Top Point"]),
            adjustUpperLip(data.measurements_coords["Upper Lip Bottom Point"])
          );
          drawLine(
            adjustLowerLip(data.measurements_coords["Lower Lip Top Point"]),
            adjustLowerLip(data.measurements_coords["Lower Lip Bottom Point"])
          );
        }
        if (visibleFeatures.bitemporalToFaceWidthRatio) {
          drawRatio('bitemporalToFaceWidthRatio', [["Left Temple Point", "Right Temple Point"], ["Left Face Point", "Right Face Point"]]);
        }
        if (visibleFeatures.jawFrontalAngle) {
          drawLine(data.measurements_coords["Jaw Left Point1"], data.measurements_coords["Jaw Left Point2"]);
          drawLine(data.measurements_coords["Jaw Right Point1"], data.measurements_coords["Jaw Right Point2"]);
        }
        if (visibleFeatures.chinAngle) {
          drawLine(data.measurements_coords["Chin Point"], data.measurements_coords["Chin End Left"]);
          drawLine(data.measurements_coords["Chin Point"], data.measurements_coords["Chin End Right"]);
        }
        if (visibleFeatures.leftCantileTilt) {
          drawLine(data.measurements_coords["Left Eye Outer Point"], data.measurements_coords["Left Eye Inner Point"]);
        }
        if (visibleFeatures.rightCantileTilt) {
          drawLine(data.measurements_coords["Right Eye Outer Point"], data.measurements_coords["Right Eye Inner Point"]);
        }
      };
    }
  }, [data, imagePath, visibleFeatures, isMobile]);

  const sortedFeatures = useMemo(() => {
    const features = Object.keys(data.percentiles);
    if (sortOrder === 'best-to-worst') {
      return features.sort((a, b) => data.percentiles[b] - data.percentiles[a]);
    } else if (sortOrder === 'worst-to-best') {
      return features.sort((a, b) => data.percentiles[a] - data.percentiles[b]);
    }
    return features;
  }, [data.percentiles, sortOrder]);

  const dynamicScores = useMemo(() => {
    const calculateSectionScore = (keys) => {
      const validValues = keys
        .map((key) => data.percentiles[key])
        .filter((v) => v !== undefined);
      if (validValues.length === 0) return 0;
      const sumOfSquares = validValues.reduce((sum, v) => sum + v * v, 0);
      const meanOfSquares = sumOfSquares / validValues.length;
      const rms = Math.sqrt(meanOfSquares);
      return parseFloat(rms.toFixed(0));
    };

    return {
      Jaw: calculateSectionScore(['Jaw Width Ratio', 'Jaw Frontal Angle', 'Chin Philtrum Ratio']),
      Mouth: calculateSectionScore(['Lip Ratio', 'Mouth Nose Harmony']),
      Nose: calculateSectionScore(['Nose Height To Width Ratio', 'Mouth Nose Harmony']),
      Eyes: calculateSectionScore([
        'Eye Aspect Ratio Left',
        'Eye Aspect Ratio Right',
        'Eye Separation Ratio',
        'Eye Spacing Ratio',
        'Left Cantile Tilt',
        'Right Cantile Tilt',
      ]),
      Midface: calculateSectionScore(['Midface Ratio', 'Midface Compactness']),
    };
  }, [data.percentiles]);

  const userScore = useMemo(() => Math.ceil(data.score * 10), [data.score]);
  const userScorePercentile = useMemo(() => calculateScorePercentile(userScore / 10), [userScore]);

  const handleSortChange = (e) => {
    setSortOrder(e.target.value);
  };

  const activeButtonStyle = {
    border: '1px solid purple',
    color: 'purple',
    backgroundColor: 'transparent',
    padding: '10px 20px',
    cursor: 'pointer',
  };

  const inactiveButtonStyle = {
    border: '1px solid gray',
    color: 'gray',
    backgroundColor: 'transparent',
    padding: '10px 20px',
    cursor: 'pointer',
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        alignItems: 'flex-start',
        margin: '30px',
      }}
    >
      {/* Left Column: All Features */}
      <div
        style={{
          // Updated responsive style for mobile: use full width on small screens.
          flex: isMobile ? '0 0 auto' : '0 0 30%',
          width: isMobile ? '100%' : undefined,
          marginRight: isMobile ? 0 : '20px',
          display: 'flex',
          flexDirection: 'column',
          height: isMobile ? 'auto' : '1200px',
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
          <label htmlFor="sortOrder" style={{ fontWeight: 'bold' }}>
            Sort Features:
          </label>
          <select
            id="sortOrder"
            value={sortOrder}
            onChange={handleSortChange}
            style={{ padding: '5px', borderRadius: '5px' }}
          >
            <option value="default">Default</option>
            <option value="best-to-worst">Best to Worst</option>
            <option value="worst-to-best">Worst to Best</option>
          </select>
        </div>
        <div
          style={{
            border: '2px solid gray',
            borderRadius: '10px',
            padding: '10px',
            flex: 1,
            overflowY: 'auto',
          }}
        >
          <h3>All Features</h3>
          {sortedFeatures.map((key) => (
            <div key={key} style={{ marginBottom: '10px', minHeight: '60px' }}>
              <strong>{key}:</strong> {data.data[key].toFixed(2)}
              <ProgressBar percent={data.percentiles[key]} style={{ width: '100%' }} />
            </div>
          ))}
        </div>
      </div>

      {/* Right Column: Image, Score Radar Chart & Toggle Panel */}
      <div
        className="responsive-right-column"
        style={{
          flex: '0 0 70%',
          display: 'flex',
          flexDirection: 'column',
          height: isMobile ? 'auto' : '500px',
        }}
      >
        {/* Top Row: Processed Image and Score Radar Chart */}
        <div style={{ display: 'flex', flex: '1 1 auto', flexDirection: isMobile ? 'column' : 'row' }}>
          <div style={{ flex: '1 1 50%', textAlign: 'left' }}>
            <h3 style={{ fontSize: '24px', marginBottom: '5px' }}>Select Scan Type</h3>
            <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginBottom: '10px' }}>
              <button onClick={() => onSwitchProfile('front')} style={activeButtonStyle}>
                Front Profile
              </button>
              <button onClick={() => onSwitchProfile('side')} style={inactiveButtonStyle}>
                Side Profile
              </button>
            </div>
            {imagePath && (
              <div
                className="responsive-image-container"
                style={{
                  marginTop: '10px',
                  width: isMobile ? window.innerWidth * 0.9 : 420,
                  height: 'auto',
                  display: 'block',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              >
                <ProcessedImage
                  canvasRef={canvasRef}
                  style={{
                    width: '100%',
                    border: `4px solid ${colors.primary}`,
                    borderRadius: '25px',
                  }}
                />
              </div>
            )}
          </div>
          <div
            style={{
              flex: '1 1 50%',
              textAlign: 'left',
              marginLeft: isMobile ? 0 : '20px',
              marginTop: isMobile ? '20px' : 0,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            {data && (
              <ScoreRadarChart
                dynamicScores={dynamicScores}
                userScore={userScore}
                userScorePercentile={userScorePercentile}
              />
            )}
          </div>
        </div>

        {/* Bottom Row: Toggle Panel */}
        <div style={{ marginTop: '20px', flex: '0 0 auto' }}>
          <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', marginBottom: '20px' }}>
            <button
              onClick={() => setToggleView('traits')}
              style={toggleView === 'traits' ? activeButtonStyle : inactiveButtonStyle}
            >
              Traits
            </button>
            <button
              onClick={() => setToggleView('ratios')}
              style={toggleView === 'ratios' ? activeButtonStyle : inactiveButtonStyle}
            >
              Ratios
            </button>
          </div>
          <div
            style={{
              border: '2px solid gray',
              borderRadius: '10px',
              padding: '10px',
              height: isMobile ? 'auto' : '500px',
              overflowY: 'auto',
            }}
          >
            {toggleView === 'traits' ? (
              <FrontTraitsToggle visibleFeatures={visibleFeatures} toggleFeature={toggleFeature} />
            ) : (
              <FrontRatiosToggle visibleFeatures={visibleFeatures} toggleFeature={toggleFeature} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FrontResultsComponent;
