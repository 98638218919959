import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cropper from 'react-easy-crop';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import frontProfilePlaceholder from '../../../assets/images/front_profile_example1.png';
import sideProfilePlaceholder from '../../../assets/images/side_profile_example1.png';
import homeAnalyzedWoman from '../../../assets/images/homeAnalyzedWoman.png';
import ScanResultsGroup from '../../../assets/images/ScanResultsGroup.png';
import AIChatGroup from '../../../assets/images/AIChatGroup.png';
import ScanYourFaceGroup from '../../../assets/images/ScanYourFaceGroup.png';
import LoginModal from '../components/LoginModal';
import SignUpModal from '../components/SignUpModal';
import '../../styles/Unauthorized/UnauthorizedUploadPage.css';

function UnauthorizedUploadPage() {
  // --- Scan & Image States ---
  const [scanType, setScanType] = useState('frontProfile');
  const [frontImage, setFrontImage] = useState(null);
  const [sideImage, setSideImage] = useState(null);
  const [frontDisplayImage, setFrontDisplayImage] = useState(frontProfilePlaceholder);
  const [sideDisplayImage, setSideDisplayImage] = useState(sideProfilePlaceholder);
  const [gender, setGender] = useState('male');

  // --- Loading & Progress ---
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();

  // --- Modal States ---
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false);

  // --- Crop Modal States ---
  const [isCropModalOpen, setCropModalOpen] = useState(false);
  const [imageToCrop, setImageToCrop] = useState(null);
  const [cropType, setCropType] = useState(''); // 'front' or 'side'
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  // --- Button Handlers ---
  const handleScanTypeButton = (selectedScanType) => {
    // For unauthorized users, sideProfile/fullScan trigger login modal
    if (selectedScanType === 'sideProfile' || selectedScanType === 'fullScan') {
      setIsLoginModalOpen(true);
      setScanType('frontProfile');
    } else {
      setScanType(selectedScanType);
    }
  };

  const handleGenderButton = (selectedGender) => {
    setGender(selectedGender);
  };

  // --- Open Crop Modal ---
  const openCropModal = (file, type) => {
    setCropType(type);
    setImageToCrop(file);
    setCropModalOpen(true);
    // Reset crop and zoom values
    setCrop({ x: 0, y: 0 });
    setZoom(1);
  };

  // --- Crop Handler ---
  const handleCrop = async () => {
    try {
      const imageUrl = URL.createObjectURL(imageToCrop);
      const { blob, croppedImageUrl } = await getCroppedImg(imageUrl, croppedAreaPixels);
      if (cropType === 'front') {
        setFrontImage(blob);
        setFrontDisplayImage(croppedImageUrl);
      } else if (cropType === 'side') {
        setSideImage(blob);
        setSideDisplayImage(croppedImageUrl);
      }
      setCropModalOpen(false);
    } catch (error) {
      console.error('Error cropping image:', error);
    }
  };

  // --- Helper: create image from URL ---
  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener('load', () => resolve(image));
      image.addEventListener('error', (error) => reject(error));
      image.setAttribute('crossOrigin', 'anonymous');
      image.src = url;
    });

  // --- Helper: get cropped image using canvas ---
  async function getCroppedImg(imageSrc, pixelCrop) {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error('Canvas is empty'));
          return;
        }
        blob.name = 'cropped.jpeg';
        const croppedImageUrl = URL.createObjectURL(blob);
        resolve({ blob, croppedImageUrl });
      }, 'image/jpeg');
    });
  }

  // --- File Input Handlers ---
  const handleFrontImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      openCropModal(e.target.files[0], 'front');
    }
  };

  const handleSideImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      openCropModal(e.target.files[0], 'side');
    }
  };

  // --- Submit Handler ---
  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    setProgress(0);
    const frontImageBase64 = frontImage ? await toBase64(frontImage) : null;
    const sideImageBase64 = sideImage ? await toBase64(sideImage) : null;

    const incrementProgress = () => {
      let percent = 0;
      const interval = setInterval(() => {
        percent += 1;
        setProgress(percent);
        if (percent >= 100) clearInterval(interval);
      }, 150);
    };
    incrementProgress();

    try {
      let endpoint = '';
      let bodyData = {};

      if (scanType === 'frontProfile') {
        endpoint =
          'https://us-central1-golden-ratio-23459.cloudfunctions.net/api/processUnauthorizedFrontScan';
        bodyData = { frontImage: frontImageBase64, gender };
      } else {
        throw new Error('Invalid scan type');
      }

      const response = await fetch(endpoint, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(bodyData),
      });

      if (!response.ok) throw new Error('Network response was not ok');

      const data = await response.json();
      const { scanData } = data;
      console.log(scanData);

      setTimeout(() => {
        setLoading(false);
        navigate('/results', { state: { data: scanData, frontImage: frontImageBase64 } });
      }, 15000);
    } catch (error) {
      console.error('Error processing scan:', error);
      setLoading(false);
    }
  };

  // --- Helper: convert file to base64 ---
  const toBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(',')[1]);
      reader.onerror = (error) => reject(error);
    });
  };

  // --- Modal control functions ---
  const openLoginModal = () => {
    setIsLoginModalOpen(true);
    document.body.classList.add('modal-open');
  };

  const closeLoginModal = () => {
    setIsLoginModalOpen(false);
    document.body.classList.remove('modal-open');
  };

  const openSignUpModal = () => {
    setIsSignUpModalOpen(true);
    document.body.classList.add('modal-open');
  };

  const closeSignUpModal = () => {
    setIsSignUpModalOpen(false);
    document.body.classList.remove('modal-open');
  };

  // --- Determine if placeholder should be active ---
  const isFrontPlaceholderActive = frontImage === null;

  return (
    <>
      {/* Crop Modal */}
      {isCropModalOpen && (
        <div className="crop-modal-overlay">
          <div className="crop-modal">
            <h2>Crop Your Image</h2>
            <div className="crop-container">
              <Cropper
                image={URL.createObjectURL(imageToCrop)}
                crop={crop}
                zoom={zoom}
                aspect={1} // square crop
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={(croppedArea, croppedAreaPixels) => setCroppedAreaPixels(croppedAreaPixels)}
              />
            </div>
            <div className="crop-modal-buttons">
              <button onClick={handleCrop}>Crop</button>
              <button onClick={() => setCropModalOpen(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}

      {/* 1) Personalized Guide and Upload Section */}
      <section className="unauthorized-upload-page__personalized-guide-upload-section">
        <section className="unauthorized-upload-page__purple-gradient-section">
          <h1 className="unauthorized-upload-page__gradient-title">
            <span className="title-black">Your Personalized </span>
            <span className="title-purple">Beauty Guide</span> with AI
          </h1>
          <p className="unauthorized-upload-page__gradient-subtitle">
            Analyze. Chat. Discover your perfect products.
          </p>
        </section>

        <div className="unauthorized-upload-page__upload-page">
          <h1 className="unauthorized-upload-page__upload-title">
            {loading ? 'Analyzing Your Face' : 'Upload Photos To See Your Facial Attractiveness'}
          </h1>

          {!loading && (
            <form onSubmit={handleSubmit} className="unauthorized-upload-page__upload-form">
              <div className="unauthorized-upload-page__form-content-row">
                {/* Left Panel: Scan Type, Gender & Start Button */}
                <div className="unauthorized-upload-page__settings-left">
                  <div className="unauthorized-upload-page__scan-type-selector">
                    <label>Select Scan Type:</label>
                    <div className="unauthorized-upload-page__button-group">
                      <button
                        type="button"
                        className={scanType === 'frontProfile' ? 'active' : ''}
                        onClick={() => handleScanTypeButton('frontProfile')}
                      >
                        Front Profile
                      </button>
                      <button
                        type="button"
                        className={scanType === 'sideProfile' ? 'active' : ''}
                        onClick={() => handleScanTypeButton('sideProfile')}
                      >
                        Side Profile
                      </button>
                      <button
                        type="button"
                        className={scanType === 'fullScan' ? 'active' : ''}
                        onClick={() => handleScanTypeButton('fullScan')}
                      >
                        Full Scan
                      </button>
                    </div>
                  </div>

                  <div className="unauthorized-upload-page__gender-section">
                    <label>Gender:</label>
                    <div className="unauthorized-upload-page__button-group">
                      <button
                        type="button"
                        className={gender === 'male' ? 'active' : ''}
                        onClick={() => handleGenderButton('male')}
                      >
                        Male
                      </button>
                      <button
                        type="button"
                        className={gender === 'female' ? 'active' : ''}
                        onClick={() => handleGenderButton('female')}
                      >
                        Female
                      </button>
                      <button
                        type="button"
                        className={gender === 'other' ? 'active' : ''}
                        onClick={() => handleGenderButton('other')}
                      >
                        Other
                      </button>
                    </div>
                  </div>

                  <button
                    type="submit"
                    className="unauthorized-upload-page__start-journey-button"
                    disabled={!frontImage}
                  >
                    Start Beauty Journey
                  </button>
                </div>

                {/* Right Panel: Upload Section */}
                {scanType === 'frontProfile' && (
                  <div
                    className={`unauthorized-upload-page__upload-section unauthorized-upload-page__upload-section--single-upload ${
                      isFrontPlaceholderActive ? 'unauthorized-upload-page__front-placeholder-active' : ''
                    }`}
                  >
                    <h2 className="unauthorized-upload-page__section-title">Front Profile</h2>
                    <div
                      className="unauthorized-upload-page__upload-box unauthorized-upload-page__upload-box--single-upload"
                      onClick={() => document.getElementById('front-input').click()}
                      aria-label="Upload Front Profile Image"
                      role="button"
                      tabIndex={0}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                          document.getElementById('front-input').click();
                        }
                      }}
                    >
                      {frontImage ? (
                        <img
                          src={frontDisplayImage}
                          alt="Front Profile"
                          className="unauthorized-upload-page__uploaded-image"
                        />
                      ) : (
                        <div className="unauthorized-upload-page__placeholder">
                          <div className="unauthorized-upload-page__placeholder-icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="64"
                              height="64"
                              fill="#ffffff"
                              viewBox="0 0 24 24"
                            >
                              <path d="M21 19V5c0-1.1-.9-2-2-2H5C3.9 3 3 3.9 3 5v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zm-3-9l-5 5-5-5V5h10v5z" />
                            </svg>
                          </div>
                        </div>
                      )}
                      <input
                        id="front-input"
                        type="file"
                        accept="image/*"
                        onChange={handleFrontImageChange}
                        className="unauthorized-upload-page__file-input"
                      />
                    </div>
                  </div>
                )}
              </div>
            </form>
          )}

          {loading && (
            <div className="unauthorized-upload-page__loading-spinner">
              <CircularProgressbar
                value={progress}
                text={`${progress}%`}
                styles={buildStyles({
                  pathColor: '#7e3ba1',
                  textColor: '#7e3ba1',
                  trailColor: '#d6d6d6',
                  backgroundColor: '#3e98c7',
                })}
              />
            </div>
          )}
        </div>
      </section>

      {/* 2) About Us Section */}
      <section className="unauthorized-upload-page__white-about-section">
        <h2 className="unauthorized-upload-page__section-heading">
          About Us <span className="unauthorized-upload-page__highlight">Redefining Beauty</span> with AI
        </h2>
        <p className="unauthorized-upload-page__section-subtitle">
          Revolutionizing Your Beauty Routine with AI Precision
        </p>

        <div className="unauthorized-upload-page__about-content">
          <div className="unauthorized-upload-page__about-text">
            <p className="unauthorized-upload-page__about-intro">
              Discover how our innovative AI technology transforms your beauty experience by providing personalized insights and recommendations.
            </p>
            <ul className="unauthorized-upload-page__about-bullets">
              <li>Comprehensive Facial Analysis: Understand your unique facial features and skin needs.</li>
              <li>Tailored Product Recommendations: Receive suggestions that perfectly match your profile.</li>
              <li>Interactive AI Assistance: Engage with our intelligent assistant for expert advice and guidance.</li>
            </ul>
          </div>
          <div className="unauthorized-upload-page__about-image">
            <img src={homeAnalyzedWoman} alt="About us placeholder" />
          </div>
        </div>
      </section>

      {/* 3) How It Works Section */}
      <section className="unauthorized-upload-page__light-purple-howitworks-section">
        <h2 className="unauthorized-upload-page__section-heading">
          How It <span className="unauthorized-upload-page__highlight">Works</span>
        </h2>
        <p className="unauthorized-upload-page__section-subtitle">
          Revolutionizing Your Beauty Routine with AI Precision
        </p>

        <div className="how-it-works-step how-it-works-step--1">
          <div className="step-text">
            <h3>Scan Your Face</h3>
            <p>
              Start your journey with a quick facial scan. Our advanced AI technology analyzes your unique features—identifying skin type, texture, and concerns—to build your personalized beauty profile.
            </p>
          </div>
          <div className="step-image">
            <img src={ScanYourFaceGroup} alt="Scan Your Face" />
          </div>
        </div>

        <div className="how-it-works-step how-it-works-step--2">
          <div className="step-image">
            <img src={ScanResultsGroup} alt="Receive Personalized Recommendations" />
          </div>
          <div className="step-text">
            <h3>Receive Personalized Recommendations</h3>
            <p>
              Our AI technology suggests products tailored to your skin’s needs. Discover the perfect routine for a healthier, radiant look.
            </p>
          </div>
        </div>

        <div className="how-it-works-step how-it-works-step--3">
          <div className="step-text">
            <h3>Chat with AI and Consult Your Product</h3>
            <p>
              Engage in an interactive conversation with our intelligent beauty assistant. Ask questions and receive expert advice tailored to your profile.
            </p>
          </div>
          <div className="step-image">
            <img src={AIChatGroup} alt="Chat with AI" />
          </div>
        </div>
      </section>

      {/* Modal Backdrop */}
      {(isLoginModalOpen || isSignUpModalOpen) && <div style={backdropStyles}></div>}

      {/* Login Modal */}
      <LoginModal
        isOpen={isLoginModalOpen}
        onClose={closeLoginModal}
        onSignUpClick={() => {
          closeLoginModal();
          openSignUpModal();
        }}
      />

      {/* Sign Up Modal */}
      <SignUpModal
        isOpen={isSignUpModalOpen}
        onClose={closeSignUpModal}
        onLoginClick={() => {
          closeSignUpModal();
          openLoginModal();
        }}
      />
    </>
  );
}

const backdropStyles = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: 998,
};

export default UnauthorizedUploadPage;
